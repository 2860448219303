export const valueAsExpense = (value) => {
	let regex;
	const hasDecimal = value.indexOf('.') > -1;
	if (hasDecimal) {
		regex = /^-?\d{0,6}(?:[.]\d{0,2})/;
	} else {
		regex = /^-?\d{0,6}/;
	}
	const match = value.match(regex);
	return match ? match[0] : null;
};

export const valueAsMileage = (value) => {
	let regex;
	const hasDecimal = value.indexOf('.') > -1;
	if (hasDecimal) {
		regex = /^\d{0,4}(?:[.]\d{0,2})/;
	} else {
		regex = /^\d{0,4}/;
	}
	const match = value.match(regex);
	return match ? match[0] : null;
};

export const valueAsNumber = (value) => value.replace(/[^0-9]/g, '');

export const valueAsText = (value) => value.replace(/[0-9]/g, '');
