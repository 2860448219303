import { useTheme } from "@material-ui/styles";
import { COLORS } from "../../../utils/Application_Constants";

function MileageEntryStylesV1() {
    const theme = useTheme();

    return {
        entryCell: {
            '&:first-child': {
                '& .MuiSelect-outlined': {
                    borderTopLeftRadius: '8px',
                    [theme.breakpoints.up('md')]: {
                        borderBottomLeftRadius: '8px',
                    },
                    [theme.breakpoints.down('md')]: {
                        borderRight: 'none',
                    },
                },
            },
            '&:nth-child(2)': {
                '& .MuiSelect-outlined': {
                    [theme.breakpoints.down('sm')]: {
                        borderTopRightRadius: '8px',
                    },
                },
            },
        },
        menuButtonCell: {
            border: `1px solid ${COLORS.LT_MIDNIGHT5}`,
            padding: '2px',
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
            overflow: 'hidden',
        },
        disabledBackgroundColor: {
            background: COLORS.LT_MIDNIGHTBG2,
        },
        formControl: {
            marginBottom: '0',
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent',
                borderRadius: '8px',
            },
        },
        formControlOrange: {
			marginBottom: '0',
			'& .MuiOutlinedInput-root': {
				'& fieldset': {
					borderColor: `${COLORS.LT_WARNING} !important`,
				},
			},
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: COLORS.LT_WARNING,
				borderRadius: '8px',
			},
		},
        menuButton: {
            color: COLORS.LT_MIDNIGHT25,
        },
        menuIcon: {
            color: COLORS.LT_MIDNIGHT25,
            display: 'flex',
            alignItems: 'center',
        },
        menuIconRemove: {
            color: COLORS.LT_PEPPER,
        },
        infoIcon: {
            color: COLORS.LT_MIDNIGHT25,
            height: '12px',
        },
        expandMore: {
            color: COLORS.LT_MIDNIGHT25,
            height: '20px',
            right: '8px',
            top: 'calc(50% - 10px)',
        },
        input: {
            padding: '16px !important',
            height: 'auto',
            fontSize: '14px',
            lineHeight: '20px',
            border: `1px solid ${COLORS.LT_MIDNIGHT5}`,
            [theme.breakpoints.up('md')]: {
                borderRight: 'none',
                '&::placeholder': {
                    opacity: 0,
                },
            },
            [theme.breakpoints.down('sm')]: {
                borderBottom: 'none',
            },
            '&$disabled': {
                background: COLORS.LT_MIDNIGHTBG2,
            },
        },
        inputRoot: {
            '&$disabled $notchedOutline': {
                borderColor: 'transparent',
            },

            '&$disabled $input': {
                background: COLORS.LT_MIDNIGHTBG2,
                color: COLORS.LT_MIDNIGHT33,
            },
        },
        selectOutlined: {
            padding: '16px 24px 16px 16px !important',
            fontSize: '14px',
            lineHeight: '20px',
            borderRadius: '0px',
            borderColor: COLORS.LT_MIDNIGHT5,
            border: `1px solid ${COLORS.LT_MIDNIGHT5}`,
            [theme.breakpoints.up('md')]: {
                borderRight: 'none',
                '& .select-placeholder': {
                    opacity: 0,
                },
            },
            [theme.breakpoints.down('sm')]: {
                borderBottom: 'none',
            },
            '& .select-placeholder': {
                color: COLORS.LT_MIDNIGHT33,
            },
            '&$disabled': {
                background: COLORS.LT_MIDNIGHTBG2,
                color: COLORS.LT_MIDNIGHT33,
            },
        },
        disabled: {},
        notchedOutline: {},
        commentsInput: {
            flexGrow: '1',
            '& .MuiOutlinedInput-input': {
                borderBottom: `1px solid ${COLORS.LT_MIDNIGHT5}`,
                [theme.breakpoints.down('sm')]: {
                    borderBottomRightRadius: '8px',
                    borderBottomLeftRadius: '8px',
                    overflow: 'hidden',
                },
            },
        },
        mobileMenuButton: {
            marginBottom: '4px',
            marginLeft: 'auto',
            display: 'flex',
            justifyContent: 'flex-end',

            '& .MuiIconButton-root': {
                padding: '6px',
            },
        },
        mileageMobileHeader: {
            display: 'none',
        },
        mileageActionButton: {
            display: 'none',
        },
        mileageDeleteButton: {
            display: 'none',
        },
        inputLabel: {
            display: 'none',
        },
    };
}

export default MileageEntryStylesV1;