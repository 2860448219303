import { createExpenseImage } from '../Services/ExpenseService';

export const uploadImages = async (fileUploads, expenseResponse, mode) => {

			let createExpenseImageData = [];
            //DATA FILEUPLOADS WAS NOT HAVING TABS REMOVED
            fileUploads.forEach(async (fileUpload, index) => {
                    const expenseId = index==0 ? expenseResponse.id : (mode === 'pending-cc' ? expenseResponse.splitPreCodedExpenses[index-1].id : expenseResponse.splitExpenses[index-1].id);
                    let imgData = new FormData();
                    imgData.append('Id', expenseId);
                    imgData.append('IsPrecoded', mode === 'pending-cc' ? true : false);

                    if (fileUpload.newFiles && fileUpload.newFiles.length > 0) {
                        const files = fileUpload.newFiles;
                        if (Array.isArray(files)) {
                            files.forEach((file) => {
                                imgData.append('Images', file);
                            });
                        } else {
                            imgData.append('Images', files);
                        } 
                    }

                    if (fileUpload.existingFiles && fileUpload.existingFiles.length > 0) {
                        const existingFiles = fileUpload.existingFiles;
                        if (Array.isArray(existingFiles)) {
                            existingFiles.forEach((existingFile,index) => {
                                //imgData.append('OriginalImages', existingFile);
                                imgData.append(`OriginalImages[${index}].Id`, existingFile.id);
                                imgData.append(`OriginalImages[${index}].Filename`, existingFile.filename);
                            });
                        } 
                    }

                    createExpenseImageData.push(imgData);
            });

            //Run the Images APIs in reverse order
            //This is done in case Existing Images are clones to new tab and then removed from parent tab
            //after the fact.  It could be possible that the Parent Image is deleted and now not available to
            //be copied to the child
            for (var i = createExpenseImageData.length - 1; i >= 0; i--) {
                await createExpenseImage(createExpenseImageData[i]);
            }
};
