import { Button, Collapse, Divider, Paper, Typography, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: theme.spacing(3),
		marginTop: theme.spacing(4),
	},
	lineItem: {
		display: 'flex',
		justifyContent: 'space-between',

		'&:not(:last-child)': {
			marginBottom: theme.spacing(1),
		},
	},
	divider: {
		margin: theme.spacing(1, 0, 3, 0),
	},
	viewMore: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(1, 0),

		'&:hover': {
			cursor: 'pointer',
		},
	},
	total: {
		marginTop: theme.spacing(2),
	},
	balanceZero: {
		color: 'green',
	},
	balanceOff: {
		color: 'red',
	},
}));

const ExpenseSummaryItem = ({ expense }) => (
	<>
		<Typography variant='body2'>{expense.status !== 'Abandoned' ? expense.label : null}</Typography>
		<Typography variant='body2'>
			{expense.status !== 'Abandoned' ? (expense.amount && expense.amount !== '-') ? '$' + parseFloat(expense.amount).toFixed(2) : '$' + 0 : null}
		</Typography>
	</>
);

const ExpenseSummary = ({mode, setError, clearErrors, setShowBalanceError}) => {
	const classes = useStyles();
	const { watch } = useFormContext();
	const expenseEntries = watch('expenseEntries');
	const limit = 2;
	const [viewMore, setViewMore] = useState(false);
	const entriesTotal = expenseEntries
		.filter(expense =>
			expense.status !== 'Abandoned' &&
			expense.amount !== '-' &&
			expense.amount !== ''
		)
		.reduce((total, expense) => total + Number(expense.amount), 0);
	const balance = Math.round((expenseEntries[0].totalAmount - entriesTotal) * 100) / 100;
	const total = mode == 'cc' ? expenseEntries[0].totalAmount !== 0 && typeof(expenseEntries[0].totalAmount) === 'number' ? parseFloat(expenseEntries[0].totalAmount) : 0 : entriesTotal;
	const { id } = useParams();

	useEffect(() => {
		if (mode == 'cc') {
			if (balance !== 0) {
				setError("balance", {
					type: "manual",
					message: "The sum of the expenses does not match the current transaction amount.",
				});
			} else {
				clearErrors("balance");
				setShowBalanceError(false);
			}
		}
	}, [entriesTotal]);

	return (
		<Paper elevation={1} className={classes.paper}>
			<Typography variant='h5'>Expense Summary</Typography>
			<Divider className={classes.divider} />
				{expenseEntries.slice(0, limit).map((expense, index) => (
					<div className={classes.lineItem} key={`${index}-line-item`}>
						<ExpenseSummaryItem expense={expense} />
					</div>
				))}
			<Collapse in={viewMore}>
				{expenseEntries.slice(limit).map((expense, index) => (
					<div className={classes.lineItem} key={`${index}-line-item`}>
						<ExpenseSummaryItem expense={expense} />
					</div>
				))}
			</Collapse>
			{expenseEntries.length > limit && (
				<Typography
					variant='body2'
					onClick={() => setViewMore((prev) => !prev)}
					className={classes.viewMore}
				>
					<b>{viewMore ? 'Show less' : 'View more'}</b>{' '}
					<ExpandMoreIcon
						style={{
							transition: 'transform .25s',
							transform: `${viewMore ? 'rotate(180deg)' : 'rotate(0)'}`,
						}}
						fontSize='small'
					/>
				</Typography>
			)}
			<div className={`${classes.lineItem} ${classes.total}`}>
				<Typography variant='h5'>Total</Typography>
				<Typography variant='h5'>${total !== 0 ? total.toFixed(2) : 0}</Typography>
			</div>
			{mode == 'cc' && 
				<div className={`${classes.lineItem} ${classes.total}`}>
					<Typography variant='h5'
						className={balance !== 0 ? classes.balanceOff : classes.balanceZero}>
						Balance
					</Typography>
					<Typography variant='h5'
						className={balance !== 0 ? classes.balanceOff : classes.balanceZero}>
						${balance !== 0 ? balance.toFixed(2) : 0}
					</Typography>
				</div>
			}
		</Paper>
	);
};

export default ExpenseSummary;
