import React, { useContext, useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
	IconButton,
	makeStyles,
	Grid,
	Hidden,
	TextField,
	MenuItem,
	Menu,
	Typography,
	Button,
	InputLabel,
} from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import WithContexts from '../../../contexts/withContexts';
import { MoreVert, ExpandMore, DeleteOutline, MoreHoriz } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { AdminAuthContext } from '../../../Admin/Contexts/AdminAuthContext';
import CopyADayMenuItem from './CopyADayMenuItem';
import CopyADayMenu from './CopyADayMenu';
import MileageEntryStylesV1 from "./MileageEntryStylesV1";
import MileageEntryStylesV2 from "./MileageEntryStylesV2";
import {WarningController} from './WarningController';

const Placeholder = ({ label }) => <span className='select-placeholder'>{label}</span>;

function MileageEntry(props) {
	const { index, dateLookup, timesheet, mileageEntry, onRemove, isPaidApproved, isMobile, isMobileUiV2Enabled, isShowWarning } = props;

	const getStylesheet = () => {
		const styles = isMobileUiV2Enabled
			? MileageEntryStylesV2
			: MileageEntryStylesV1;

		const useStyles = makeStyles(styles);
		return useStyles();
	};

	const classes = getStylesheet();

	const { errors, watch, getValues, clearErrors } = useFormContext();
	const watchAssignment = watch(`mileageEntries[${index}].locationId`);
	const watchMiles = watch(`mileageEntries[${index}].miles`);
	const watchComment = watch(`mileageEntries[${index}].comment`);
	const [showCopyADayMenu, setShowCopyADayMenu] = useState(false);
	const readOnlyLocations =
		timesheet.timesheetReviews?.map((review) =>
			review.status !== 'NotStarted' &&
			review.status !== 'PendingSubmission' &&
			review.status !== 'Rejected' &&
			review.status !== 'DidNotWork'
				? review.locationId
				: null
		) || [];
	const adminAuth = useContext(AdminAuthContext);
	const isReadOnly =
		readOnlyLocations.indexOf(mileageEntry.locationId) > -1 || adminAuth?.isLTAssociateUser;
	const [anchorEl, setAnchorEl] = useState(null);
	const getAvailableLocation = () => {
		return (
			timesheet.timesheetReviews?.find(
				(review) => !readOnlyLocations.includes(review.locationId)
			)?.locationId || null
		);
	};
	const avaliableLocationId = getAvailableLocation();
	const defaultLocationId =
		timesheet.primaryWorkLocationId &&
		!readOnlyLocations.includes(timesheet.primaryWorkLocationId)
			? timesheet.primaryWorkLocationId
			: avaliableLocationId;

	const getTransactionDateLabel = (transactionDate) => {
		return dateLookup.filter(date => date.value === transactionDate)[0]?.label;
	};
	const [transactionDateLabel, setTransactionDateLabel] = useState(getTransactionDateLabel(mileageEntry.transactionDate));

	const getLocationDisplayName = (locationId) => {
		const existingOrDefaultLocationId = locationId || defaultLocationId;
		return timesheet.booking.locations.filter(location => location.id === existingOrDefaultLocationId)[0]?.displayName;
	};
	const [locationDisplayName, setLocationDisplayName] = useState(getLocationDisplayName(mileageEntry.locationId));

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const validateMileage = (value) => {
		let regex;
		const hasDecimal = value.indexOf('.') > -1;
		if (hasDecimal) {
			regex = /^\d{0,4}(?:[.]\d{0,2})/;
		} else {
			regex = /^\d{0,4}/;
		}
		const match = value.match(regex);
		return match ? match[0] : null;
	};

	const renderCopyADayMenuItem = () => {
		return (
			<CopyADayMenuItem
				index={index}
				entryType={'mileage-entry'}
				setAnchorEl={setAnchorEl}
				setShowCopyADayMenu={setShowCopyADayMenu}
				isDisabled={!watchMiles}
				isMobile={isMobile}
				isMobileUiV2Enabled={isMobileUiV2Enabled}
			/>
		);
	};

	const renderCopyADayMenu = () => {
		return (
			<CopyADayMenu
				index={index}
				timesheet={timesheet}
				dateLookup={dateLookup}
				entryType={'mileage-entry'}
				anchorEl={anchorEl}
				setAnchorEl={setAnchorEl}
				showCopyADayMenu={showCopyADayMenu}
				setShowCopyADayMenu={setShowCopyADayMenu}
				isMobile={isMobile}
				isMobileUiV2Enabled={isMobileUiV2Enabled}
			/>
		);
	};

	const getEntryMenuHiddenSizes = () => {
		if (isPaidApproved || adminAuth?.isLTAssociateUser) {
			return ['xs', 'sm', 'md', 'lg', 'xl'];
		}

		return isMobileUiV2Enabled ? [] : ['xs', 'sm'];
	};

	return (
		<>
			<Hidden
				only={
					isPaidApproved || adminAuth?.isLTAssociateUser || isMobileUiV2Enabled
						? ['xs', 'sm', 'md', 'lg', 'xl']
						: ['md', 'lg', 'xl']
				}
			>
				<div className={classes.mobileMenuButton}>
					<IconButton
						id={`mileage-entry--${index}--menu`}
						color='default'
						aria-label='More options'
						onClick={handleClick}
						disabled={isReadOnly ? true : false}
					>
						<MoreHoriz />
					</IconButton>
					<Menu
						id='mileageEntry-menu'
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={handleClose}
					>
						<MenuItem
							onClick={() => {
								onRemove(getValues().mileageEntries[index], index);
								handleClose();
							}}
							id={`mileage-entry--${index}--menu--list-item--remove`}
						>
							<Grid container alignItems='center' spacing={2}>
								<Grid item className={classes.menuIcon}>
									<DeleteOutline
										fontSize='small'
										className={classes.menuIconRemove}
									/>
								</Grid>
								<Grid item>
									<Typography variant='body2'>Remove</Typography>
								</Grid>
							</Grid>
						</MenuItem>
					</Menu>
				</div>
			</Hidden>
			<Grid container className={classes.entryRow} id={`mileage-entry--${index}`}>
				<h4 className={classes.mileageMobileHeader}>{transactionDateLabel} - {locationDisplayName}</h4>
				<Controller name={`mileageEntries[${index}].id`} defaultValue={mileageEntry.id} />
				<Grid className={classes.entryCell} item xs={isMobileUiV2Enabled ? 12 : 6} md={2} lg={2}>
					<InputLabel shrink classes={{ root: classes.inputLabel }}>
						Date
					</InputLabel>
					<Controller
						name={`mileageEntries[${index}].transactionDate`}
						defaultValue={mileageEntry.transactionDate}
						rules={{
							required: watchMiles || watchComment ? 'Date is required' : false,
						}}
						render={({ onChange, value, ref }) => (
							<TextField
								id={`mileage-entry--${index}--date`}
								onChange={(e) => {
									setTransactionDateLabel(getTransactionDateLabel(e.target.value));
									clearErrors(`mileageEntries[${index}].transactionDate`);
									onChange(e);
								}}
								select
								value={value}
								className={`${classes.formControl} mileage-entry--cell`}
								ref={ref}
								InputProps={{
									classes: {
										root: classes.inputRoot,
										disabled: classes.disabled,
										notchedOutline: classes.notchedOutline,
									},
								}}
								SelectProps={{
									classes: {
										icon: classes.expandMore,
										outlined: classes.selectOutlined,
										disabled: classes.disabled,
									},
									IconComponent: ExpandMore,
									displayEmpty: true,
									renderValue:
										value !== '' ? null : () => <Placeholder label='Date' />,
								}}
								variant='outlined'
								disabled={isReadOnly}
								error={
									errors['mileageEntries']?.[index]?.transactionDate
										? true
										: false
								}
								helperText={
									errors['mileageEntries']?.[index]?.transactionDate?.message
								}
							>
								{dateLookup.map((date, dateIndex) => (
									<MenuItem
										key={date.value}
										value={date.value}
										id={`mileage-entry--${index}--date--list-item--${dateIndex}`}
									>
										{date.label}
									</MenuItem>
								))}
							</TextField>
						)}
					/>
				</Grid>
				<Grid className={classes.entryCell} item xs={isMobileUiV2Enabled ? 12 : 6} md={3}>
					<InputLabel shrink classes={{ root: classes.inputLabel }}>
						Location
					</InputLabel>
					<Controller
						name={`mileageEntries[${index}].locationId`}
						defaultValue={
							mileageEntry.locationId ? mileageEntry.locationId : defaultLocationId
						}
						rules={{
							required:
								watchMiles || watchComment ? 'Work Location is required' : false,
						}}
						render={({ onChange, value, ref }) => (
							<TextField
								id={`mileage-entry--${index}--work-location`}
								onChange={(e) => {
									setLocationDisplayName(getLocationDisplayName(e.target.value));
									clearErrors(`mileageEntries[${index}].locationId`);
									onChange(e);
								}}
								select
								value={value}
								className={`${classes.formControl} mileage-entry--cell`}
								ref={ref}
								InputProps={{
									classes: {
										root: classes.inputRoot,
										disabled: classes.disabled,
										notchedOutline: classes.notchedOutline,
									},
								}}
								SelectProps={{
									classes: {
										icon: classes.expandMore,
										outlined: classes.selectOutlined,
										disabled: classes.disabled,
									},
									IconComponent: ExpandMore,
									displayEmpty: true,
									renderValue:
										value !== ''
											? null
											: () => <Placeholder label='Location' />,
								}}
								variant='outlined'
								disabled={isReadOnly}
								error={errors['mileageEntries']?.[index]?.locationId ? true : false}
								helperText={errors['mileageEntries']?.[index]?.locationId?.message}
							>
								{timesheet.booking.locations.map((location, locationIndex) => (
									<MenuItem
										key={location.id}
										value={location.id}
										disabled={readOnlyLocations.indexOf(location.id) > -1}
										id={`mileage-entry--${index}--work-location--list-item--${locationIndex}`}
									>
										{location.displayName}
									</MenuItem>
								))}
							</TextField>
						)}
					/>
				</Grid>
				<Grid className={classes.entryCell} item xs={12} md={1}>
					<InputLabel shrink classes={{ root: classes.inputLabel }}>
						Miles
					</InputLabel>
					<Controller
						name={`mileageEntries[${index}].miles`}
						defaultValue={mileageEntry.miles}
						rules={{
							required: watchComment ? 'Miles are required' : false,
						}}
						render={({ onChange, value, ref }) => (
							<TextField
								id={`mileage-entry--${index}--mileage`}
								className={`${classes.formControl} mileage-entry--cell`}
								inputRef={ref}
								onChange={(e) => {
									clearErrors(`mileageEntries[${index}].miles`);
									const newValue = validateMileage(e.target.value);
									if (newValue || newValue === '') {
										onChange(newValue);
									}
								}}
								InputProps={{
									classes: {
										input: classes.input,
										root: classes.inputRoot,
										disabled: classes.disabled,
										notchedOutline: classes.notchedOutline,
									},
								}}
								value={value}
								variant='outlined'
								disabled={isReadOnly}
								error={errors['mileageEntries']?.[index]?.miles ? true : false}
								helperText={errors['mileageEntries']?.[index]?.miles?.message}
								placeholder='Miles'
							/>
						)}
					/>
				</Grid>
				<Grid item className={classes.entryCell} classes={{ root: classes.commentsInput }}>
					<InputLabel shrink classes={{ root: classes.inputLabel }}>
						Comments
					</InputLabel>
					<WarningController
						name={`mileageEntries[${index}].comment`}
						defaultValue={mileageEntry.comment}
						render={({ onChange, value, ref, warning }) => (
							<TextField
								inputProps={{ maxLength: 255 }}
								id={`mileage-entry--${index}--comments`}
								className={ (warning && isShowWarning) ? `${classes.formControlOrange} mileage-entry--cell` : `${classes.formControl} mileage-entry--cell`}
								value={value}
								ref={ref}
								onChange={(e) => {
									clearErrors(`mileageEntries[${index}].comment`);
									onChange(e);
								}}
								InputProps={{
									classes: {
										input: classes.input,
										root: classes.inputRoot,
										disabled: classes.disabled,
										notchedOutline: classes.notchedOutline,
									},
								}}
								variant='outlined'
								disabled={isReadOnly}
								placeholder='Comments'
								minRows={isMobile && isMobileUiV2Enabled ? 2 : 1}
								multiline={isMobile && isMobileUiV2Enabled}
							/>
						)}
					/>
				</Grid>
				<Grid item className={classes.entryMenu}>
					<Hidden
						only={getEntryMenuHiddenSizes()}
					>
						<div
							className={`${classes.menuButtonCell} ${
								isReadOnly ? classes.disabledBackgroundColor : ''
							}`}
						>
							<IconButton
								id={`mileage-entry--${index}--menu`}
								className={classes.menuButton}
								color='default'
								aria-label='More options'
								onClick={handleClick}
								disabled={isReadOnly ? true : false}
							>
								<MoreVert />
							</IconButton>
							<Grid item xs={12} hidden={!isMobile || !isMobileUiV2Enabled || isPaidApproved || isReadOnly}>
								<Button
									variant='contained'
									classes={{ root: classes.mileageActionButton }}
									onClick={handleClick} >
									Mileage Actions
								</Button>
							</Grid>
							<Menu
								id='mileageEntry-menu'
								anchorEl={anchorEl}
								anchorOrigin={isMobile && isMobileUiV2Enabled ? { vertical: 'center', horizontal: 'center', } : undefined}
								transformOrigin={isMobile && isMobileUiV2Enabled ? { vertical: 'top', horizontal: 'center', } : undefined}
								keepMounted
								open={Boolean(anchorEl)}
								onClose={handleClose}
							>
								{renderCopyADayMenuItem()}
								<MenuItem
									onClick={() => {
										onRemove(getValues().mileageEntries[index], index);
										handleClose();
									}}
									id={`mileage-entry--${index}--menu--list-item--remove`}
									className={classes.actionRemoveButton}
								>
									<Grid container alignItems='center' spacing={2}>
										<Grid item className={classes.menuIcon}>
											<DeleteOutline
												fontSize='small'
												className={classes.menuIconRemove}
											/>
										</Grid>
										<Grid item>
											<Typography variant='body2'>Remove</Typography>
										</Grid>
									</Grid>
								</MenuItem>
							</Menu>
							{renderCopyADayMenu()}
						</div>
					</Hidden>
				</Grid>
				<Grid item xs={12} hidden={!isMobile || !isMobileUiV2Enabled || isPaidApproved || isReadOnly}>
					<Button
						variant='contained'
						classes={{ root: classes.mileageDeleteButton }}
						onClick={() => {
							onRemove(getValues().mileageEntries[index], index);
							handleClose();
						}} >
						Delete Mileage
					</Button>
				</Grid>
			</Grid>
		</>
	);
}

MileageEntry.propTypes = {
	mileageEntry: PropTypes.shape({
		id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		timesheetId: PropTypes.number,
		locationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		miles: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		transactionId: PropTypes.string,
	}),
};

export default WithContexts(withRouter(withTranslation()(MileageEntry)));
