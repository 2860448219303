import { useTheme } from '@material-ui/styles';
import { COLORS } from '../../utils/Application_Constants';

function TimesheetStylesV2() {
	const theme = useTheme();

	return {
		root: {
			backgroundColor: COLORS.LT_MIDNIGHTBG1,
			minHeight: '200px',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		ContainerRoot: {
			paddingTop: '1rem',
			paddingBottom: '5rem',
		},
		PaperRoot: {
			padding: '2rem',
			display: 'flex',
			gap: '24px',
			flexDirection: 'column',
		},
		entrySection: {
			display: 'flex',
			gap: '24px',
			flexDirection: 'column',
		},
		containedSuccess: {
			backgroundColor: COLORS.LT_EMERALD,
			'&:hover': {
				backgroundColor: COLORS.LT_EMERALD75,
			},
			color: 'white',
		},
		overline1: {
			fontWeight: '600',
		},
		weekEnding: {
			fontWeight: '600',
			[theme.breakpoints.up('md')]: {
				'&.admin': {
					color: COLORS.LT_MIDNIGHT50,
				},
			},
			[theme.breakpoints.down('sm')]: {
				fontSize: '1.5rem',
				color: '#2a3953',
			}
		},
		cancelButton: {
			backgroundColor: COLORS.LT_PEPPER10,
			color: COLORS.LT_PEPPER,
			'&:hover': {
				backgroundColor: COLORS.LT_PEPPER25,
			},
		},
		buttonsWrapper: {
			paddingTop: '24px',
		},
		infoIcon: {
			color: COLORS.LT_MIDNIGHT25,
			height: '12px',
		},
		submitButton: {
			backgroundColor: '#17B169',
		},
		progressRoot: {
			margin: '0 ',
		},
		header: {
			fontWeight: '600',
		},
		headerGrey: {
			fontWeight: '600',
			color: COLORS.RAVEN,
		},
		headerInfo: {
			color: COLORS.LT_MIDNIGHT50,
			display: 'flex',
			alignItems: 'center',
			gap: '4px',
		},
		paragraph3: {
			display: 'flex',
			alignItems: 'center',
			color: COLORS.LT_MIDNIGHT50,
			fontSize: '12px',
			lineHeight: '18px',
		},
		headerContainer: {
			display: 'flex',
			backgroundColor: COLORS.LT_MIDNIGHTBG1,
			padding: '12px 0',
			borderRadius: '8px',
		},
		headerCell: {
			paddingLeft: '16px',
		},
		containedPrimaryLight: {
			backgroundColor: COLORS.LT_JOURNEY10,
			color: COLORS.LT_JOURNEY,
			'&:hover': {
				backgroundColor: COLORS.LT_JOURNEY25,
			},
		},
		entriesContainer: {
			flexWrap: 'wrap',
			[theme.breakpoints.up('md')]: {
				flexWrap: 'nowrap',
			},
		},
		dialogPaperRoot: {
			padding: '40px',
			gap: '16px',
		},
		dialogTitleRoot: {
			color: COLORS.LT_FOUNDATION,
			fontSize: '24px',
			fontWeight: '700',
			padding: '0',
		},
		dialogContentRoot: {
			padding: '0',
			'&:first-child': {
				paddingTop: '0',
			},
		},
		dialogGrid: {
			gap: '24px',
		},
		dialogActionsRoot: {
			padding: '0',
		},
		underlinedButton: {
			textDecoration: 'underline',
		},
		dialogErrorIcon: {
			color: COLORS.LT_PEPPER,
			background: COLORS.LT_PEPPER10,
			borderRadius: '50%',
			padding: '16px',
		},
		dialogWarningIcon: {
			color: COLORS.LT_SUNFLOWER,
			background: COLORS.LT_SUNFLOWER25,
			borderRadius: '50%',
			padding: '16px',
		},
		form: {
			'&:focus-visible': {
				outline: 'none',
			},
		},
		viewTimesheetLink: {
			color: COLORS.LT_PEPPER,
			cursor: 'pointer',
		},
		errorText: {
			fontSize: '14px',
		},
		viewTimesheetInError: {
			marginLeft: '6px',
		},
		errorListItem: {
			listStyleType: 'none',
			margin: '0',
			marginTop: '5px',
		},
		rejectReasonAlert: {
			marginBottom: '1rem',
		},
		lastRejectionReasonAlert: {
			marginBottom: '0rem',
		},
		bookingName: {
			fontStyle: 'italic',
			color: COLORS.LT_MIDNIGHT50,
		},
		clinicianNameHeader: {
			[theme.breakpoints.down('sm')]: {
				display: 'none'
			}
		},
		mileageAddButton: {
			[theme.breakpoints.down('xs')]: {
				width: '100%',
			}
		},
		mobileButton: {
			[theme.breakpoints.down('xs')]: {
				width: '100%',
			}
		},
	};
}

export default TimesheetStylesV2;
