import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import PublishIcon from '@material-ui/icons/Publish';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { COLORS } from '../../../utils/Application_Constants';
import { useFormContext } from 'react-hook-form';
import FilePreview from './FilePreview';
import FileRejections from './FileRejections';

const useStyles = makeStyles((theme) => ({
	dropzone: {
		borderStyle: 'dashed',
		borderWidth: '2px',
		borderColor: COLORS.LT_MIDNIGHT25,
		textAlign: 'center',
		borderRadius: '4px',
		cursor: 'pointer',
		opacity: '1',

		'&:hover': {
			background: COLORS.LT_MIDNIGHTBG2,
		},

		'&.disabled': {
			'&:hover': {
				background: 'transparent',
			},
			cursor: 'initial',
			opacity: 0.5,
		},
	},
	dot: {
		height: '50px',
		width: '50px',
		backgroundColor: COLORS.LT_JOURNEY10,
		borderRadius: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: COLORS.LT_JOURNEY,
	},
	uploadTitle: {
		'& u': {
			color: COLORS.LT_JOURNEY,
			fontWeight: 'bold',
		},
	},
	uploadDescription: {
		fontSize: '12px',
		marginTop: '8px',
		color: COLORS.LT_MIDNIGHT50,
	},
	centerDrop: {
		padding: 24,
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		gap: 8,
	},
	filePreviews: {
		marginTop: '24px',
		display: 'flex',
		flexDirection: 'column',
		gap: '8px',
	},
}));

const ExpensesUploadInput = () => {
	const classes = useStyles();
	const { register, setValue, watch, unregister, setError } = useFormContext();
	const file = watch('file');
	const [loadingState, setLoadingState] = useState({});
	const isDisabled = !!file;
	const maxFiles = 1;

	useEffect(() => {
		register('file', {
			validate: {
				required: (value) => value || 'File is required',
			},
		});
		return () => unregister('file');
	}, [register]);

	const onError = useCallback(
		(error) => {
			console.error(error);
			setError('file', {
				type: 'manual',
				message: error,
			});
		},
		[setValue, file]
	);

	const handleLoading = async (file) => {
		let newIsLoading = { ...loadingState };
		newIsLoading = { ...newIsLoading, [file.name]: { isLoading: true, duration: 1.5 } };
		setLoadingState(newIsLoading);
		await new Promise((resolve) => setTimeout(resolve, 1500));
		newIsLoading = { ...newIsLoading, [file.name]: { isLoading: false } };
		setLoadingState(newIsLoading);
		setLoadingState({});
	};

	const onDrop = useCallback(
		async (acceptedFiles, fileRejections) => {
			if (fileRejections.length > 0) return;
			const [acceptedFile] = acceptedFiles;
			setValue('file', acceptedFile, { shouldValidate: true, shouldDirty: true });
			await handleLoading(acceptedFile);
		},
		[setValue, file]
	);

	const { getRootProps, getInputProps, fileRejections } = useDropzone({
		onError,
		onDrop,
		accept: {
			'text/csv': [],
		},
		disabled: isDisabled,
		maxFiles,
	});

	const removeFile = (fileIndex) => {
		setValue('file', null, { shouldDirty: true });
		setLoadingState({});
	};

	return (
		<>
			<Grid
				{...getRootProps({
					className: `${classes.dropzone} ${isDisabled && 'disabled'}`,
				})}
			>
				<Grid className={classes.centerDrop}>
					<Grid className={classes.dot}>
						<PublishIcon className={classes.icon} />
					</Grid>
					<Typography variant='body2' className={classes.uploadTitle}>
						<u>Click to upload</u> or drag and drop
					</Typography>
					<Typography variant='body2' className={classes.uploadDescription}>
						CSV Format
					</Typography>
					<input {...getInputProps()} name='file' type='file' disabled={isDisabled} />
				</Grid>
			</Grid>

			{file && (
				<div className={classes.filePreviews}>
					<FilePreview
						fileIndex={0}
						file={file}
						loadingState={loadingState}
						removeFile={removeFile}
						mode={'credit-card-file-upload'}
					/>
				</div>
			)}

			{fileRejections?.length > 0 && <FileRejections fileRejections={fileRejections} />}
		</>
	);
};

export default ExpensesUploadInput;
