import { Grid, IconButton, Typography, withStyles } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import React from 'react';
import { COLORS } from '../../../utils/Application_Constants';
import ProgressBarAnimation from '../Expense/ProgressBarAnimation';
import { Link } from 'react-router-dom';
import { getExpenseImageById } from '../../Services/ExpenseService';

const styles = (theme) => ({
	preview: {
		border: `1px solid ${COLORS.LT_MIDNIGHT10}`,
		padding: 12,
		borderRadius: 4,
        '&:hover': {
            cursor: 'pointer',
            background: COLORS.LT_MIDNIGHTBG1,
        }
	},
	fileThumbnail: {
		width: 40,
		height: 40,
		borderRadius: '4px',
		display: 'block',
		objectFit: 'cover',
		cursor: 'pointer',

		'&.skeleton': {
			background: COLORS.LT_MIDNIGHT25,
			animation: '$pulse 2s cubic-bezier(.4,0,.6,1) infinite',
		},
	},
	pdfThumbnail: {
		width: 40,
		height: 40,
		borderRadius: '4px',
		background: COLORS.LT_JOURNEY75,
		color: 'white',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	fileName: {
		fontWeight: 'bold',
		maxWidth: '100%',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: 'block',
		whiteSpace: 'nowrap',
	},	
	fileNameLink: {
		'& u': {
			color: COLORS.LT_JOURNEY,
			fontWeight: 'bold',
		},
	},
	fileSize: {
		fontWeight: 'bold',
		color: COLORS.LT_MIDNIGHT50,
	},
	'@keyframes pulse': {
		'0%': {
			opacity: 1,
		},
		'50%': {
			opacity: 0.5,
		},
		'100%': {
			opacity: 1,
		},
	},
});

const FilePreview = (props) => {
	const { file, removeFile, loadingState, isDisabled, fileIndex, classes, isExisting, mode } = props;
	const initialLoadingState = { isLoading: false, duration: 0 };
	const { isLoading, duration } = loadingState[file.name] || initialLoadingState;
	const isPdf = file.type === 'application/pdf';
	const isCsvOrExcel =
		file.type === 'text/csv' ||
		file.type === 'application/vnd.ms-excel' ||
		file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
	const bytesToMegaBytes = (bytes) => (bytes / (1024 * 1024)).toFixed(1);
	const bytesToKiloBytes = (bytes) => (bytes / 1024).toFixed(1);

	function dataURItoBlob(dataURI) {//Required for Chrome to display data url data
		// convert base64 to raw binary data held in a string
		// doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
		var byteString = atob(dataURI.split(',')[1]);
	
		// separate out the mime component
		var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
	
		// write the bytes of the string to an ArrayBuffer
		var ab = new ArrayBuffer(byteString.length);
		var ia = new Uint8Array(ab);
		for (var i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}
	
		//New Code
		return new Blob([ab], {type: mimeString});
	}

	async function previewImage() {
		if (mode !== 'credit-card-file-upload'){
			if (isExisting){
				let anImage = await getExpenseImageById((mode==='pending-cc' ? file.preCodedExpenseId: file.expenseId),file.id,(mode==='pending-cc'? true: false));
				window.open(URL.createObjectURL(dataURItoBlob(anImage.image)), '_blank')
			}else{
				window.open(file.preview, '_blank')
			}
		}
	}

	return (
		<Grid
			container
			alignItems='center'
			justifyContent='space-between'
			key={!isExisting ? file.name : file.originalFilename}
			className={classes.preview}
		>
			<Grid item xs={7} onClick={previewImage}>
				<Grid container alignItems='center' spacing={1}>
					<Grid item xs={7}>
						<div className={classes.fileName}>
							<Link to="#"  >
								{(mode === 'credit-card-file-upload') && 								
									<Typography variant='caption' title={!isExisting ? file.name : file.originalFilename}>
										{!isExisting ? file.name : file.originalFilename}
									</Typography>}
								{(mode !== 'credit-card-file-upload') && 								
									<Typography className={classes.fileNameLink} variant='caption' title={!isExisting ? file.name : file.originalFilename}>
										<u>{!isExisting ? file.name : file.originalFilename}</u>
									</Typography>}
							</Link>
							{isLoading && <ProgressBarAnimation durationSeconds={duration} />}
						</div>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<Grid container alignItems='center' spacing={1}>
					<Grid item>
						<Typography variant='caption' className={classes.fileSize}>
							{bytesToMegaBytes(!isExisting ? file.size : file.filesize) < 0.1
								? bytesToKiloBytes(!isExisting ? file.size : file.filesize) + 'KB'
								: bytesToMegaBytes(!isExisting ? file.size : file.filesize) + 'MB'}
						</Typography>
					</Grid>
					<Grid item>
						<IconButton onClick={() => removeFile(fileIndex, isExisting)} disabled={isDisabled}>
							<ClearIcon fontSize='small' />
						</IconButton>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default withStyles(styles)(FilePreview);
