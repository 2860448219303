import React from 'react';
import msalService from '../services/msSalService';
import { getPortalUserLevel } from '../services/ProfileServices/profileService';
import { getConfigurationSetting } from '../services/AppConfigService';
import { appInsights } from '../AppInsights';

function getExternalId(account) {
	if (!account) {
		return null;
	}

	const externalId =
		account.idTokenClaims.extension_SfExternalId === undefined
			? account.idTokenClaims.extension_SfExternalId
			: account.idTokenClaims.extension_SfExternalId.trim();

	return externalId;
}

function getUserLogin(account) {
	if (!account || !account.idToken) {
		return null;
	}

	const userLogin = account.idToken.emails[0];

	return userLogin;
}

function getIsFirstLogin(account) {
	if (!account) {
		return null;
	}
	return account.idTokenClaims.extension_IsFirstLogin;
}

function getVerifiedDate(account) {
	if (!account) {
		return null;
	}
	return account.idTokenClaims.extension_VerifiedDate;
}

function getInvitationSentDate(account) {
	if (!account) {
		return null;
	}
	return account.idTokenClaims.extension_InvitationSentDate;
}

/**
 * This function allow you to modify a JS Promise by adding some properties.
 */
function MakeQuerablePromise(promise) {
	// Don't modify any promise that has been already modified.
	if (promise.isFulfilled) return promise;

	// Set initial state
	var isPending = true;
	var isRejected = false;
	var isFulfilled = false;
	var value = -1;

	// Observe the promise, saving the fulfillment in a closure scope.
	var result = promise.then(
		function (v) {
			isFulfilled = true;
			isPending = false;
			value = v;
			return v;
		},
		function (e) {
			isRejected = true;
			isPending = false;
			throw e;
		}
	);

	result.isFulfilled = function () {
		return isFulfilled;
	};
	result.isPending = function () {
		return isPending;
	};
	result.isRejected = function () {
		return isRejected;
	};
	result.value = function () {
		return value;
	};
	return result;
}

function getUserLevel(account) {
	if (!account) {
		return null;
	}
	if (account.idTokenClaims.extension_SfExternalId) {
		return MakeQuerablePromise(
			getPortalUserLevel(account.idTokenClaims.extension_SfExternalId.trim())
		);
	} else {
		return null;
	}
}

function getShowV2() {
	return MakeQuerablePromise(
		getConfigurationSetting('AxuallCredentialing', true)
	);
}

const account = msalService.getAccount || null;

appInsights.setAuthenticatedUserContext(getUserLogin(account), getExternalId(account), true);

if(account && process.env.REACT_APP_TYPE === 'Clinician'){
	window.heap.identify(account.accountIdentifier);
	window.heap.addUserProperties({ providerExternalId: getExternalId(account),email: getUserLogin(account),environment: process.env.REACT_APP_EXECUTION_ENVIRONMENT,'LT Channel': 'LTOE'});
}

const defaultUserContext = {
	account,
	externalId: getExternalId(account),
	verifiedDate: getVerifiedDate(account),
	invitationSentDate: getInvitationSentDate(account),
	isFirstLogin: getIsFirstLogin(account),
	userLevel: getUserLevel(account),
	showV2: getShowV2(),
	degree: account?.idTokenClaims?.extension_Degree?.trim()
};

const UserContext = React.createContext(defaultUserContext);

export default UserContext;
