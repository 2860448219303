/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-self-assign */
import React, { useContext, useEffect, useState, useRef, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import {
	Paper,
	Typography,
	Button,
	makeStyles,
	Grid,
	Box,
	useMediaQuery,
	Container,
	CircularProgress,
	Hidden,
	DialogContent,
	Dialog,
	DialogTitle,
	DialogActions,
	Snackbar,
} from '@material-ui/core';
import { withRouter, Link as RouterLink } from 'react-router-dom';
import WithContexts from '../../contexts/withContexts';
import { getTimesheetById, saveTimesheet } from '../../services/TimeAndPayService';
import { getHolidays } from '../../services/MetaDataService';
import { useTheme } from '@material-ui/styles';
import { ChevronLeft, DeleteOutline, InfoOutlined, PriorityHigh } from '@material-ui/icons';
import ToolTipComponent from '../../components/GlobalComponents/ToolTipComponent';
import TimesheetEntry from '../../components/ContentComponents/Timesheets/TimesheetEntry';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import MileageEntry from '../../components/ContentComponents/Timesheets/MileageEntry';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import useQuery from '../../utils/useQuery';
import { Alert } from '@material-ui/lab';
import moment from 'moment-timezone';
import { cloneDeep, has } from 'lodash';
import { TimesheetStatusEnumApi } from '../../components/ContentComponents/Timesheets/Status.enum';
import RateDisplayNameEnum from '../../components/ContentComponents/Timesheets/RateDisplayName.enum';
import StatusErrorDialog from '../../Shared/Components/StatusErrorDialog';
import { AdminAuthContext } from '../../Admin/Contexts/AdminAuthContext';
import TimesheetHistory from '../../Admin/Components/TimesheetHistory';
import { getConfigurationSetting } from '../../services/AppConfigService';
import { formatErrorMessage } from '../../Shared/Utils/ErrorMessage';
import { useDebouncedCallback } from 'use-debounce';
import TimesheetStylesV1 from './TimesheetStylesV1';
import TimesheetStylesV2 from './TimesheetStylesV2';
import RateTypeCategoryEnum from '../../components/ContentComponents/Timesheets/RateTypeCategories.enum';
import { getTimesheetCommentWarnings, addTimesheetCommentWarning } from '../../utils/helpers';

function Timesheet(props) {
	const { t, UserContext, setRefreshNotifications } = props;
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
		defaultMatches: true,
	});
	const [dateLookup, setDateLookup] = useState([]);
	const [timesheet, setTimesheet] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const [cancelModalOpen, setCancelModalOpen] = useState(false);
	const [holidayModalOpen, setHolidayModalOpen] = useState(false);
	const [missingShiftOnLoggedHolidayModelOpen, setMissingShiftOnLoggedHolidayModelOpen] = useState(false);
	const [wordList, setWordList] = useState(null); 
	const [commentWarningAlertModalOpen, setCommentWarningAlertModalOpen] = useState(false);
	const [emptyTimesheetModalOpen, setEmptyTimesheetModalOpen] = useState(false);
	const [holidayModalProperties, setHolidayModalProperties] = useState(null);
	const [missingShiftOnLoggedHolidayModelProperties, setMissingShiftOnLoggedHolidayModelProperties] = useState(null);
	const [isMileageExpected, setIsMileageExpected] = useState(false);
	const { id } = useParams();
	const query = useQuery();
	const weekEnding = query.get('weekEnding');
	const clinicianId = query.get('clinicianId');
	const externalId = UserContext && UserContext.externalId ? UserContext.externalId : clinicianId;
	const history = useHistory();
	const [recallWarningDialogOpen, setRecallWarningDialogOpen] = useState(false);
	const [recallErrorDialogOpen, setRecallErrorDialogOpen] = useState(false);
	const [recallErrorType, setRecallErrorType] = useState();
	const [clearDialogOpen, setClearDialogOpen] = useState(false);
	const [cleared, setCleared] = useState(false);
	const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
	const [isReadOnly, setIsReadyOnly] = useState(false);
	const [isPaidApproved, setIsPaidApproved] = useState(false);
	const [readOnlyLocations, setReadOnlyLocations] = useState([]);
	const [error, setError] = useState();
	const [errorLoadingTimesheet, setErrorLoadingTimesheet] = useState();
	const commentWarning = useRef(false);
	const commentWordList = useRef([]);
	const [isSave, setIsSave] = useState(false);
	const formRef = useRef(null);
	const adminAuth = useContext(AdminAuthContext);
	const [historyEnabled, setHistoryEnabled] = useState(false);
	const [totalTimesheetHours, setTotalTimesheetHours] = useState(0);
	const [triggerCallRowLockCheck, setTriggerCallRowLockCheck] = useState(false);
	const [isMobileUiV2Enabled, setIsMobileUiV2Enabled] = useState(false);
	const [isShowWarning, setIsShowWarning] = useState(false); 

	const getStylesheet = () => {
		const styles = isMobileUiV2Enabled
			? TimesheetStylesV2
			: TimesheetStylesV1;

		const useStyles = makeStyles(styles);
		return useStyles();
	};

	const classes = getStylesheet();

	const settingDidNotWork = useRef(false);

	const historyEnabledStatuses = [
		TimesheetStatusEnumApi.DidNotWork,
		TimesheetStatusEnumApi.NotStarted,
		TimesheetStatusEnumApi.PendingSubmission,
		TimesheetStatusEnumApi.Rejected,
	];
	const readOnlyStatuses = [
		TimesheetStatusEnumApi.Submitted,
		TimesheetStatusEnumApi.Approved,
		TimesheetStatusEnumApi.Paid,
	];
	const formHasRejectedTimesheet = timesheet?.timesheetReviews?.filter(
		(timesheetReview) => timesheetReview?.status === TimesheetStatusEnumApi.Rejected
	)?.length
		? true
		: false;
	const isAdminApp = process.env.REACT_APP_TYPE === 'Admin';

	const methods = useForm({ defaultValues: {} });

	const {
		formState: { isDirty },
		watch
	} = methods;

	const {
		fields: timesheetEntries,
		append: timesheetAppend,
		insert: timesheetInsert,
		remove: timesheetRemove,
	} = useFieldArray({
		control: methods.control,
		name: 'timesheetEntries',
		keyName: 'timesheetEntryId',
	});
	const {
		fields: mileageEntries,
		append: mileageAppend,
		remove: mileageRemove,
	} = useFieldArray({
		control: methods.control,
		name: 'mileageEntries',
		keyName: 'mileageEntryId',
	});

	const useDynamicWatch = (dynamicFieldNames: string[]): any[] => {
		const watchedValues: any[] = useMemo(() => watch(dynamicFieldNames), [dynamicFieldNames]);
		return watchedValues;
	  };

	const dynamicTimesheetCommentFieldNames = useMemo(() => timesheetEntries.reduce((fieldNames, field, currentIndex) => {
		const fieldName = `timesheetEntries[${currentIndex}].comment`;
		return [...fieldNames, fieldName];
	}, []), [timesheetEntries]);

	const dynamicMileageCommentFieldNames = useMemo(() => mileageEntries.reduce((fieldNames, field, currentIndex) => {
		const fieldName = `mileageEntries[${currentIndex}].comment`;
		return [...fieldNames, fieldName];
	}, []), [mileageEntries]);
	
	const watchedValues = useDynamicWatch(dynamicTimesheetCommentFieldNames.concat(dynamicMileageCommentFieldNames));

	function containsAny(str, substrings) {
		var rtnStrings = [];
		for (var i = 0; i != substrings.length; i++) {
		   var substring = substrings[i];
		   let re = new RegExp(String.raw`\b${substring}\b`, "gi");
		   if (str.search(re) >= 0) {
			 rtnStrings.push(substring);
		   }
		}
	  return rtnStrings;
	}

	useEffect(() => {
		commentWarning.current = false;
		let contextwarningtimesheet = getTimesheetCommentWarnings();
		let calcCommentWordList = [];
		if (!contextwarningtimesheet.includes(id)){
			for (var key in watchedValues) {
				var aValue = watchedValues[key];
				if (aValue){
					let containsWords = containsAny(aValue, wordList.words);
					if (containsWords.length > 0){
						commentWarning.current = true;
						calcCommentWordList.push(...containsWords);
					}
				}
			}

			for(var i = 0 ; i < calcCommentWordList.length ; i++){
				calcCommentWordList[i] = calcCommentWordList[i].charAt(0).toUpperCase() + calcCommentWordList[i].substr(1);
			}

			commentWordList.current=[...new Set(calcCommentWordList)];
		}
	}, [watchedValues]);

	useEffect(() => {
		formRef.current && formRef.current.focus();
		window.scrollTo(0, 0);
	}, [isLoaded]);

	useEffect(() => {
		fetchTimesheetById();
	}, [id, weekEnding]);

	function generateNextId() {
		const entries = methods.getValues().timesheetEntries;
		const lowIdItem = entries.reduce(function(prev, curr) {
			return prev.id < curr.id ? prev : curr;
		});
		return !lowIdItem | lowIdItem.id > -1 ? -1 : lowIdItem.id - 1;
	}

	async function fetchTimesheetById() {
		setIsLoaded(false);
		try {
			const timesheetDetails = await getTimesheetById(externalId, id);
			const enableHistory = historyEnabledStatuses.includes(timesheetDetails.status);
			setHistoryEnabled(enableHistory && isAdminApp);
			mapTimeToLocationTimezone(timesheetDetails);
			generateDateLookup(
				weekEnding,
				timesheetDetails.booking.startDate,
				timesheetDetails.booking.endDate
			);
			const readOnlyLocations =
				timesheetDetails.timesheetReviews
					?.map((review) =>
						readOnlyStatuses.indexOf(review.status) > -1 ? review.locationId : null
					)
					.filter(Boolean) || [];
			setReadOnlyLocations(readOnlyLocations);
			const readOnlyReviewCheck =
				timesheetDetails.timesheetReviews.length > 0
					? timesheetDetails.timesheetReviews.every(checkIfReadOnly)
					: false;
			const readOnlyCheck = readOnlyStatuses.indexOf(timesheetDetails.status) > -1;
			setIsReadyOnly(readOnlyReviewCheck || readOnlyCheck);
			setIsPaidApproved(
				timesheetDetails.status === 'Approved' || timesheetDetails.status === 'Paid'
					? true
					: false
			);
			setTimesheet(timesheetDetails);
			setIsMileageExpected(timesheetDetails.booking.mileageExpected);
			setIsLoaded(true);
		} catch (error) {
			setErrorLoadingTimesheet('Could not load timesheet');
			setIsLoaded(true);
			console.error(error);
		}
	}

	useEffect(() => {
		if (timesheet && dateLookup) {
			methods.reset(setDefaultValues());
		}
	}, [methods.reset, timesheet, dateLookup]);

	useEffect(() => {
		async function getConfigurationSettings() {
			const showMobileUiV2 = await getConfigurationSetting('TimesheetMobileUiV2', true);
			setIsMobileUiV2Enabled(showMobileUiV2);

			const aWordlist = await getConfigurationSetting('Timesheets:Comments:WordList');
			setWordList(aWordlist);
		}

		getConfigurationSettings();
	}, []);

	const setDefaultValues = () => {
		let timesheetEntries = [];
		let mileageEntries = [];

		timesheetEntries = timesheet.timesheetEntries.sort((a, b) => new Date(a.transactionDate) - new Date(b.transactionDate));

		// Group timesheetEntries by relatedEntryId or form individual groups for entries without relatedEntryId
		const groups = {};
		timesheetEntries.forEach((entry, index) => {
			if (entry.id === 0) {
				entry.id = -(index + 1);
            }
			const relatedEntryId = entry.relatedEntryId;
			if (relatedEntryId) {
				if (!groups[relatedEntryId]) {
					groups[relatedEntryId] = [];
				}
				groups[relatedEntryId].push(entry);
			} else {
				// Form individual groups for entries without relatedEntryId
				groups[entry.id] = [entry];
			}
		});

		const sortEntriesWithinGroup = (group) => {
			return group.sort((a, b) => {
				const dateA = new Date(a.transactionDate);
				const dateB = new Date(b.transactionDate);
				if (dateA.getTime() !== dateB.getTime()) {
					return dateA - dateB;
				}
				// Sort by start time if it exists
				if (a.startTime && b.startTime) {
					return a.startTime.localeCompare(b.startTime);
				}
				// If start time doesn't exist, maintain the existing order
				return 0;
			});
		};

		//sort groups by the transactionDate of their first entry
		const sortGroupsByTransactionDate = (groups) => {
			return Object.values(groups).sort((a, b) => {
				const dateA = new Date(a[0].transactionDate);
				const dateB = new Date(b[0].transactionDate);
				return dateA - dateB;
			});
		};

		// Sort groups by transactionDate
		const sortedGroups = sortGroupsByTransactionDate(groups);

		// Sort entries within each group
		sortedGroups.forEach(group => {
			sortEntriesWithinGroup(group);
		});

		// Flatten sortedGroups into an array while keeping the order of groups
		const sortedEntries = sortedGroups.flatMap(group => group);

		timesheetEntries = sortedEntries
			.map((entry, index) => ({
				id: entry.id === 0 ? -(index + 1) : entry.id,
				transactionDate: entry.transactionDate,
				locationId: entry.locationId ? entry.locationId : '',
				rateTypeId: entry.rateTypeId ? entry.rateTypeId : '',
				relatedEntryId: entry.relatedEntryId ? entry.relatedEntryId : '',
				startTime: entry.startTime ? entry.startTime : '',
				endTime: entry.endTime ? entry.endTime : '',
				units: entry.units ? entry.units.toString() : '',
				comment: entry.comment ? entry.comment : '',
				timesheetBreaks: entry.timesheetBreaks
			}));

		mileageEntries = timesheet.mileageEntries
			.sort((a, b) => new Date(a.transactionDate) - new Date(b.transactionDate))
			.map((entry) => ({
				id: entry.id,
				transactionDate: entry.transactionDate,
				locationId: entry.locationId ? entry.locationId : '',
				miles: entry.miles ? entry.miles : '',
				comment: entry.comment ? entry.comment : '',
			}));
		return { timesheetEntries, mileageEntries };
	};

	const convertUTCtoTime = (utcString, timeZoneBooking) => {
		let utcDateTime = utcString;
		if (utcString !== null) {
			utcDateTime = moment(utcString).tz(timeZoneBooking).format('HH:mm');
		}
		return utcDateTime;
	};

	const mapTimeToLocationTimezone = (timesheet) => {
		timesheet.timesheetEntries.forEach((timesheetEntry) => {
			const timeZoneBooking =
				timesheet?.booking?.locations?.find(
					(location) => location.id === timesheetEntry?.locationId
				)?.timeZone || moment.tz.guess();
			let startTime = timesheetEntry.startTime;
			let endTime = timesheetEntry.endTime;
			timesheetEntry.startTime = convertUTCtoTime(startTime, timeZoneBooking);
			timesheetEntry.endTime = convertUTCtoTime(endTime, timeZoneBooking);
			timesheetEntry.transactionDate = timesheetEntry.transactionDate = moment(
				timesheetEntry.transactionDate
			)
				.utc()
				.format('YYYY-MM-DDTHH:mm:ss');

			timesheetEntry.timesheetBreaks = timesheetEntry.timesheetBreaks.sort((a, b) => a.startTime.localeCompare(b.startTime));
			timesheetEntry.timesheetBreaks.forEach((timesheetBreak) => {
				timesheetBreak.startTime = convertUTCtoTime(timesheetBreak.startTime, timeZoneBooking);
				timesheetBreak.endTime = convertUTCtoTime(timesheetBreak.endTime, timeZoneBooking);
			});
		});
		timesheet.mileageEntries.forEach((mileageEntry) => {
			mileageEntry.transactionDate = moment(mileageEntry.transactionDate)
				.utc()
				.format('YYYY-MM-DDTHH:mm:ss');
		});
		return timesheet;
	};

	const debouncedOnSaveOrSubmit = useDebouncedCallback((action, data) => {
		if (action=='save'){
			onSave(data);
		}else if (action=='submit'){
			onSubmit(data);
		}
		
	}, 5000, { leading: true });

	const onSave = async (data) => {
		setIsLoaded(false);
		let newTimesheet = formatTimesheet(data);
		if (newTimesheet.status === TimesheetStatusEnumApi.NotStarted) {
			setIsLoaded(true);
			history.push('/timesheets');
		} else {
			try {
				if (newTimesheet.id) {
					// call update
					await saveTimesheet(newTimesheet, newTimesheet.id);
				} else {
					await saveTimesheet(newTimesheet);
				}
				if (setRefreshNotifications) {
					setRefreshNotifications();
				}
				setIsLoaded(true);
				history.push('/timesheet/save');
			} catch (error) {
				setIsLoaded(true);
				var message = formatErrorMessage(error);
				if (message === 'An error has occurred.') message = 'Timesheet has failed to be saved. Please try again.';
				setError(message);
				console.error(message);
			}
		}
	};

	const validateHoursOverlap = ({ timesheetEntries }) => {
		let hasOverlap = false;

		const checkOverlap = (entryA, entryB, indexA, indexB) => {
			const {
				startTime: startTimeA,
				endTime: endTimeA,
				transactionDate: transactionDateA,
				rateTypeId: rateTypeIdA,
			} = entryA;

			const {
				startTime: startTimeB,
				endTime: endTimeB,
				transactionDate: transactionDateB,
				rateTypeId: rateTypeIdB,
			} = entryB;

			if (startTimeA === '' || endTimeA === '' || startTimeB === '' || endTimeB === '')
				return;

			const rateTypeA = timesheet.booking.availableRates.find((r) => r.id === rateTypeIdA);
			const rateTypeB = timesheet.booking.availableRates.find((r) => r.id === rateTypeIdB);

			const startDateA = moment.utc(transactionDateA).add(moment.duration(startTimeA));
			const endDateA = moment.utc(transactionDateA).add(moment.duration(endTimeA));
			const startDateB = moment.utc(transactionDateB).add(moment.duration(startTimeB));
			const endDateB = moment.utc(transactionDateB).add(moment.duration(endTimeB));

			const isMultiDayA = endDateA.isSameOrBefore(startDateA);
			const isMultiDayB = endDateB.isSameOrBefore(startDateB);

			if (isMultiDayA) endDateA.add(1, 'day');
			if (isMultiDayB) endDateB.add(1, 'day');

			const overlaps =
				startDateA.isBetween(startDateB, endDateB) ||
				endDateA.isBetween(startDateB, endDateB) ||
				startDateB.isBetween(startDateA, endDateA) ||
				endDateB.isBetween(startDateA, endDateA) ||
				startDateA.isSame(startDateB) ||
				endDateA.isSame(endDateB);

			if (overlaps) {
				if (rateTypeA.category === 'Hours' && rateTypeB.category === 'Hours') {
					hasOverlap = true;
					['startTime', 'endTime'].forEach((timeField) => {
						['A', 'B'].forEach((suffix) => {
							const entryIndex = suffix === 'A' ? indexA : indexB;
							const fieldName = timeField === 'startTime' ? 'Start time' : 'End time';
							methods.setError(`timesheetEntries[${entryIndex}].${timeField}`, {
								type: 'manual',
								message: `${fieldName} can't overlap`,
							});
						});
					});
				}
			}
		};

		for (let indexA = 0; indexA < timesheetEntries.length; indexA++) {
			for (let indexB = indexA + 1; indexB < timesheetEntries.length; indexB++) {
				checkOverlap(timesheetEntries[indexA], timesheetEntries[indexB], indexA, indexB);
			}
		}

		return hasOverlap;
	};

	const validateBreaks = ({ timesheetEntries }) => {
		const formattedEntries = formatTimesheetEntries(timesheetEntries);
		const hasZeroHourBreaks = validateZeroHourBreaks(formattedEntries);
		const hasBreakOutOfBounds = validateBreakWithinEntry(formattedEntries);

		if (hasZeroHourBreaks || hasBreakOutOfBounds)
			return false;

		const hasBreakOverlap = validateBreakHoursOverlap(formattedEntries);
		return !hasBreakOverlap;
	};

	const validateBreakHoursOverlap = (timesheetEntries) => {
		let hasBreakHoursOverlap = false;
		const validBreakRateTypeIds = getValidBreakRateTypeIds();

		for (let i = 0; i < timesheetEntries.length; i++) {
			const entry = timesheetEntries[i];

			if (!validBreakRateTypeIds.includes(entry.rateTypeId)
				|| !entry.timesheetBreaks)
				continue;

			var sortedBreaks = entry.timesheetBreaks.sort((a, b) => a.startTime.localeCompare(b.startTime));

			for (let j = 0; j < sortedBreaks.length - 1; j++) {
				const timesheetBreak = sortedBreaks[j];
				const breakEndTime = moment(timesheetBreak.endTime);

				const nextBreakIndex = j + 1;
				const nextBreak = sortedBreaks[nextBreakIndex];
				const nextBreakStartTime = moment(nextBreak.startTime);

				if (breakEndTime.isSameOrBefore(nextBreakStartTime))
					continue;

				setBreakError(i, j, 'startTime', `Start time can't overlap`);
				setBreakError(i, j, 'endTime', `End time can't overlap`);
				setBreakError(i, nextBreakIndex, 'startTime', `Start time can't overlap`);
				setBreakError(i, nextBreakIndex, 'endTime', `End time can't overlap`);

				hasBreakHoursOverlap = true;
			}
		}

		return hasBreakHoursOverlap;
	}
	
	const getValidBreakRateTypeIds = () => {
		let validBreakRateTypeIds = [];
		
		const validBreakRateTypeNames = [
			RateDisplayNameEnum.Regular,
			RateDisplayNameEnum.RegularOnsite,
			RateDisplayNameEnum.RegularTele,
			RateDisplayNameEnum.DayShift,
			RateDisplayNameEnum.DayShiftOnsite,
			RateDisplayNameEnum.DayShiftTele,
			RateDisplayNameEnum.NightShift,
			RateDisplayNameEnum.NightShiftOnsite,
			RateDisplayNameEnum.NightShiftTele,
			RateDisplayNameEnum.Orientation,
			RateDisplayNameEnum.OrientationOnsite,
			RateDisplayNameEnum.OrientationTele,
		];
		
		validBreakRateTypeNames.forEach((rateTypeName) => {
			const rate = timesheet.booking.availableRates.find(
				(rate) => rate.displayName === rateTypeName
			);
			
			if (rate?.id) {
				validBreakRateTypeIds.push(rate.id)
			}
		});

		return validBreakRateTypeIds;
	};

	const validateBreakWithinEntry = (timesheetEntries) => {
		let hasOutOfBoundsBreaks = false;
		const validBreakRateTypeIds = getValidBreakRateTypeIds();

		for (let i = 0; i < timesheetEntries.length; i++) {
			const entry = timesheetEntries[i];

			if (!validBreakRateTypeIds.includes(entry.rateTypeId)
				|| !entry.timesheetBreaks
				|| !entry.startTime
				|| !entry.endTime)
				continue;

			const entryStart = moment(entry.startTime);
			const entryEnd = moment(entry.endTime);

			for (let j = 0; j < entry.timesheetBreaks.length; j++) {
				const timesheetBreak = entry.timesheetBreaks[j];

				if (!timesheetBreak.startTime || !timesheetBreak.endTime)
					continue;

				const breakStart = moment(timesheetBreak.startTime);
				const breakEnd = moment(timesheetBreak.endTime);

				let isBreakOutOfBounds = false;

				if (!breakStart.isBetween(entryStart, entryEnd, null, '[]')) {
					setBreakError(i, j, 'startTime', 'Break time must be within shift time');
					isBreakOutOfBounds = true;
				}

				if (!breakEnd.isBetween(entryStart, entryEnd, null, '[]')) {
					setBreakError(i, j, 'endTime', 'Break time must be within shift time');
					isBreakOutOfBounds = true;
				}

				if (isBreakOutOfBounds)
					hasOutOfBoundsBreaks = true;
			}
		}

		return hasOutOfBoundsBreaks;
	};

	const setBreakError = (entryIndex, index, fieldName, errorMessage) => {
		const fullFieldName = `timesheetEntries[${entryIndex}].timesheetBreaks[${index}].${fieldName}`;

		methods.setError(fullFieldName, {
			type: 'manual',
			message: errorMessage,
		});
	};

	const validateZeroHourBreaks = (timesheetEntries) => {
		let hasZeroHourBreaks = false;
		const validBreakRateTypeIds = getValidBreakRateTypeIds();

		for (let i = 0; i < timesheetEntries.length; i++) {
			const entry = timesheetEntries[i];

			if (!validBreakRateTypeIds.includes(entry.rateTypeId)
				|| !entry.timesheetBreaks
				|| !entry.startTime
				|| !entry.endTime)
				continue;

			for (let j = 0; j < entry.timesheetBreaks.length; j++) {
				const timesheetBreak = entry.timesheetBreaks[j];

				if (!timesheetBreak.startTime || !timesheetBreak.endTime)
					continue;

				if (timesheetBreak.startTime === timesheetBreak.endTime) {
					setBreakError(i, j, 'startTime', 'Start and end time cannot be the same');
					setBreakError(i, j, 'endTime', 'Start and end time cannot be the same');
					hasZeroHourBreaks = true
				}
			}
		}

		return hasZeroHourBreaks;
	};

	const validateNoDates = (data) => {
		let triggers = [];
		['timesheetEntries', 'mileageEntries'].forEach((entries) => {
			if (!data[entries]) return null;
			data[entries].forEach((entry, index) => {
				let trigger = !entry.transactionDate
					? `${entries}[${index}].transactionDate`
					: null;
				if (trigger) triggers.push(trigger);
			});
		});
		return triggers;
	};

	const validateAssociatedRateType = ({ timesheetEntries }) => {
		let missingAssociatedRate = false;

		const rates = timesheetEntries.filter((entry) => entry.rateTypeId !== '');

		timesheetEntries.forEach((entry, index) => {
			let missingRate = false;
			let rateType = timesheet.booking.availableRates.find((r) => r.id === entry.rateTypeId);
			if (
				!!rateType &&
				(rateType.category === 'Hours' || rateType.category === 'TeleHours') &&
				rateType.associatedRateType !== null
			) {
				missingRate = !rates.find(
					(r) =>
						r.rateTypeId === rateType.associatedRateType &&
						moment(r.transactionDate).diff(entry.transactionDate, 'hours') <= 48  &&
						r.locationId === entry.locationId
				);
			}
			if (missingRate) {
				missingAssociatedRate = true;
				methods.setError(`timesheetEntries[${index}].rateTypeId`, {
					type: 'manual',
					message: `On-call shift is required`,
				});
			}
		});
		return missingAssociatedRate;
	};

	const validateNoFutureDates = (data) => {
		let noFutureDates = true;
		const today = new Date();

		['timesheetEntries', 'mileageEntries'].forEach((entries) => {
			if (!data[entries]) return null;

			data[entries].forEach((entry, index) => {
				let transactionDate;

				// For timesheetEntries, check rateType and use startTime/endTime if necessary
				if (entries === 'timesheetEntries') {
					let rateType = entry.rateTypeId
						? timesheet.booking.availableRates.find((r) => r.id === entry.rateTypeId)
						: null;

					if (!!rateType && (rateType.category === 'Hours' || rateType.category === 'TeleHours')) {
						const startTime = new Date(entry.startTime);
						const endTime = new Date(entry.endTime);

						if (startTime > today) {
							noFutureDates = false;
							methods.setError(`${entries}[${index}].startTime`, {
								type: 'manual',
								message: 'Timesheets cannot be submitted with shift or mileage dates in the future.',
							});
						}

						if (endTime > today) {
							noFutureDates = false;
							methods.setError(`${entries}[${index}].endTime`, {
								type: 'manual',
								message: 'Timesheets cannot be submitted with shift or mileage dates in the future.',
							});
						}
					} else if (!!rateType) {
						// For other timesheet categories, check transactionDate
						transactionDate = new Date(entry.transactionDate);
						if (transactionDate > today) {
							noFutureDates = false;
							methods.setError(`${entries}[${index}].transactionDate`, {
								type: 'manual',
								message: 'Timesheets cannot be submitted with shift or mileage dates in the future.',
							});
						}
					}
				} else if (entries === 'mileageEntries') {
					// For mileageEntries, always check transactionDate
					if (!!entry.miles) {
						transactionDate = new Date(entry.transactionDate);
						if (transactionDate > today) {
							noFutureDates = false;
							methods.setError(`${entries}[${index}].transactionDate`, {
								type: 'manual',
								message: 'Timesheets cannot be submitted with shift or mileage dates in the future.',
							});
						}
                    }
				}
			});
		});

		return noFutureDates;
	};



	const validateSave = async () => {
		setIsSave(true);
		methods.clearErrors();
		const data = methods.getValues();
		const noDates = validateNoDates(data);
		const valid = await methods.trigger(noDates);
		if (valid) {
			debouncedOnSaveOrSubmit('save',data);
		}
	};

	const isEmptyTimesheet = ({ timesheetEntries, mileageEntries }) => {
		const hasNoRateTypesOrComments = !timesheetEntries.some(e => !!e.rateTypeId || !!e.comment);
		const hasNoMileage = !mileageEntries ? true : !mileageEntries.some(e => !!e.miles); 

		return hasNoRateTypesOrComments && hasNoMileage;
	};

	const validateSubmit = async () => {
		const data = methods.getValues();

		if (isEmptyTimesheet(data)) {
			setEmptyTimesheetModalOpen(true);
			return;
		}

		return await validateSubmitAfterEmptyCheck(false, false);
	}
	const revalidateErrors = async (data) => {
		methods.clearErrors();

		await detectCommentWarnings(data);
		await methods.trigger();

		validateHoursOverlap(data);
		validateBreaks(data);
		validateAssociatedRateType(data);
		validateNoFutureDates(data);
		validateHolidayShifts(data);
	}

	const validateSubmitAfterEmptyCheck = async (skipMissingHolidayShiftValidation, skipMissingShiftOnLoggedHolidayValidation) => {

		if (isSave) {
			setIsSave(false);
		}

		methods.clearErrors();
		const data = methods.getValues();
		const isCommentWarningsDetected = await detectCommentWarnings(data);
		const isMissingHolidayShift = skipMissingHolidayShiftValidation ? false : await detectMissingHolidayShift(data);
		const isMissingShiftOnLoggedHoliday = skipMissingShiftOnLoggedHolidayValidation ? false : await detectMissingShiftOnLoggedHoliday(data);
		const valid = await methods.trigger();

		const hasOverlap = validateHoursOverlap(data);
		const hasValidBreaks = validateBreaks(data);
		const missingAssociatedRate = validateAssociatedRateType(data);
		const noFutureDates = validateNoFutureDates(data);
		const hasValidHolidayShifts = validateHolidayShifts(data);

		if (!valid) {
			setEmptyTimesheetModalOpen(false);
			return;
		}

		if (
			!hasOverlap &&
			hasValidBreaks &&
			valid &&
			!missingAssociatedRate &&
			!isMissingHolidayShift &&
			!isMissingShiftOnLoggedHoliday &&
			!isCommentWarningsDetected &&
			hasValidHolidayShifts &&
			noFutureDates
		) {
			methods.handleSubmit((data) => debouncedOnSaveOrSubmit('submit',data))();
		}
		window.Appcues.track('Review Timesheet Before Submitting (Review)', {
			key: 'Click the Review/Submit button',
			description: 'A user is moving to the Review screen prior to submitting',
		});
	};

	const validateHolidayShifts = ({ timesheetEntries }) => {
		const holidayRate = timesheet.booking.availableRates.find(
			(rate) => rate.displayName === RateDisplayNameEnum.HolidayShift
		);
		if (!holidayRate) return true;

		const holidayEntryIndexesPerDay = getHolidayEntryIndexesPerDay(
			timesheetEntries,
			holidayRate
		);
		if (!holidayEntryIndexesPerDay) return true;

		return !hasMultipleHolidayShiftsPerDay(holidayEntryIndexesPerDay);
	};

	const hasMultipleHolidayShiftsPerDay = (holidayEntryIndexesPerDay) => {
		let hasMultipleHolidayShifts = false;

		holidayEntryIndexesPerDay.forEach((dayHolidayEntryIndexes) => {
			if (dayHolidayEntryIndexes.length < 2) return;

			dayHolidayEntryIndexes.forEach((index) => {
				methods.setError(`timesheetEntries[${index}].units`, {
					type: 'manual',
					message: `Max of 1 Holiday allowed per day.`,
				});
			});

			hasMultipleHolidayShifts = true;
		});

		return hasMultipleHolidayShifts;
	};

	const getHolidayEntryIndexesPerDay = (timesheetEntries, holidayRate) => {
		let holidayEntryIndexesPerDay = new Map();

		for (let i = 0; i < timesheetEntries.length; i++) {
			if (timesheetEntries[i].rateTypeId !== holidayRate.id) continue;

			const entryDate = timesheetEntries[i].transactionDate;
			let dayEntryIndexes = holidayEntryIndexesPerDay.get(entryDate);

			if (dayEntryIndexes) dayEntryIndexes.push(i);
			else dayEntryIndexes = [i];

			holidayEntryIndexesPerDay.set(entryDate, dayEntryIndexes);
		}

		return holidayEntryIndexesPerDay;
	};

	const detectCommentWarnings = async ({ timesheetEntries }) => {
		if (commentWarning.current) {
			let contextwarningtimesheet = getTimesheetCommentWarnings();

			if (!contextwarningtimesheet.includes(id)){
				addTimesheetCommentWarning(id);
			}else{
				return false;
			}
			setIsShowWarning(true);
			setCommentWarningAlertModalOpen(true);
			return true;
		}
		return false;
	};

	const detectMissingHolidayShift = async ({ timesheetEntries }) => {
		const holidayRate = timesheet.booking.availableRates.find(
			(rate) => rate.displayName === RateDisplayNameEnum.HolidayShift
		);

		if (!holidayRate) return false;

		const holidays = await getHolidays();

		let holiday;
		let holidayOnEntry;
		let entryOnHoliday;

		for (const entry of timesheetEntries) {
			const formattedEntryDate = moment(entry.transactionDate).format('YYYY-MM-DD');
			holidayOnEntry = holidays.find((holiday) => holiday.date === formattedEntryDate);

			if (holidayOnEntry) {
				if (entry.rateTypeId === holidayRate.id) {
					return false;
				}

				holiday = holidayOnEntry;
				entryOnHoliday = entry;
			}
		}

		if (holiday && (entryOnHoliday?.startTime || entryOnHoliday?.units)) {
			const displayedHolidayDate = holiday.date ? moment(holiday.date).format('MM-DD-YYYY') : null;

			const holidayModalProperties = {
				name: holiday.name,
				date: displayedHolidayDate,
				rateTypeId: holidayRate.id,
				transactionDate: entryOnHoliday.transactionDate,
				locationId: entryOnHoliday.locationId
			};
			setHolidayModalProperties(holidayModalProperties);
			setHolidayModalOpen(true);

			return true;
		};

		return false;
	};

	const detectMissingShiftOnLoggedHoliday = async ({ timesheetEntries }) => {
		const holidayRate = timesheet.booking.availableRates.find(
			(rate) => rate.displayName === RateDisplayNameEnum.HolidayShift
		);

		if (!holidayRate) return false;

		const holidays = await getHolidays();

		let holiday;
		let holidayShiftEntryOnHoliday;
		let hasNonHolidayShiftEntriesOnHoliday = false;

		for (const entry of timesheetEntries) {
			const formattedEntryDate = moment(entry.transactionDate).format('YYYY-MM-DD');
			let holidayOnEntry = holidays.find((holiday) => holiday.date === formattedEntryDate);

			if (holidayOnEntry) {
				if (entry.rateTypeId === holidayRate.id) {
					holiday = holidayOnEntry;
					holidayShiftEntryOnHoliday = entry;
				}

				else if (entry.rateTypeId !== '') {
					hasNonHolidayShiftEntriesOnHoliday = true;
				}
			}
		}

		if (holidayShiftEntryOnHoliday && !hasNonHolidayShiftEntriesOnHoliday) {
			const displayedHolidayDate = holiday.date ? moment(holiday.date).format('MM-DD-YYYY') : null;

			const missingShiftOnLoggedHolidayModelProperties = {
				name: holiday.name,
				date: displayedHolidayDate,
				rateTypeId: null,
				transactionDate: holidayShiftEntryOnHoliday.transactionDate,
				locationId: holidayShiftEntryOnHoliday.locationId
			};

			setMissingShiftOnLoggedHolidayModelProperties(missingShiftOnLoggedHolidayModelProperties);
			setMissingShiftOnLoggedHolidayModelOpen(true);

			return true;
		}

		return false;
	};

	const hasHolidayRate = (timesheetEntries, holidayRateId) => {
		return timesheetEntries.some((entry) => entry.rateTypeId === holidayRateId);
	};

	const onSubmit = async (data) => {
		setIsLoaded(false);
		let newTimesheet = formatTimesheet(data);
		newTimesheet.timesheetEntries = newTimesheet.timesheetEntries.filter(
			(entry) => entry.locationId && entry.rateTypeId
		);
		newTimesheet.mileageEntries = newTimesheet.mileageEntries.filter(
			(entry) => entry.locationId
		);
		if (!settingDidNotWork.current && newTimesheet?.status === TimesheetStatusEnumApi.DidNotWork) {
			newTimesheet.status = TimesheetStatusEnumApi.PendingSubmission;
		}
		try {
			if (newTimesheet.id) {
				// call update
				await saveTimesheet(newTimesheet, newTimesheet.id);
			} else if (!newTimesheet.id) {
				// call save
				const response = await saveTimesheet(newTimesheet);
				newTimesheet.id = response.id;
			}
			setIsLoaded(true);
			var goTo = '/timesheets';
			if (isAdminApp) {
				if (!settingDidNotWork.current) goTo = `/clinician/${newTimesheet?.clinicianId}/timesheet/${newTimesheet?.id}/review?editable=true`;
			} else {
				if (!settingDidNotWork.current) goTo = `/timesheet/${newTimesheet.id}/review`;
			}
			settingDidNotWork.current = false;
			history.push(goTo);
		} catch (error) {
			setIsLoaded(true);
			setEmptyTimesheetModalOpen(false);
			var message = formatErrorMessage(error);
			if (message === 'An error has occurred.') message = 'Timesheet has failed to be submitted. Please try again.';
			setError(message);
			console.error(error);
		}
	};

	const convertTimeToUTC = (timeInput, dateInput, timeZone) => {
		const date = moment(dateInput);
		const time = moment(timeInput, 'HH:mm a');

		const dateAndTime = moment
			.tz(timeZone)
			.month(date.month())
			.date(date.date())
			.year(date.year())
			.hour(time.hour())
			.second(time.second())
			.minute(time.minutes());
		return moment.utc(dateAndTime).format();
	};

	const formatTimesheetEntries = (entries) => {
		if (entries) {
			return entries
				.filter((entry) => entry.transactionDate)
				.map((entry) => {
					entry.id = entry.id;
					const timeZoneTransaction =
						timesheet?.booking?.locations?.find(
							(location) => location.id === entry.locationId
						)?.timeZone || moment.tz.guess();
					const transactionDate = moment.tz(entry.transactionDate, 'Greenwich');
					entry.transactionDate = transactionDate.format();
					entry.startTime =
						!entry.startTime || entry.startTime === ''
							? null
							: convertTimeToUTC(
									entry.startTime,
									transactionDate,
									timeZoneTransaction
							  );
					entry.endTime =
						!entry.endTime || entry.endTime === ''
							? null
							: convertTimeToUTC(entry.endTime, transactionDate, timeZoneTransaction);
					entry.locationId = entry.locationId === '' ? null : entry.locationId;
					entry.rateTypeId = entry.rateTypeId === '' ? null : entry.rateTypeId;
					entry.relatedEntryId = entry.relatedEntryId === '' ? null : entry.relatedEntryId;
					entry.units = entry.units === '' ? null : entry.units.toString();
					entry.comment = entry.comment === '' ? null : entry.comment;

					if (entry.startTime && entry.endTime) {
						const startTime = moment(entry.startTime).tz(timeZoneTransaction);
						const endTime = moment(entry.endTime).tz(timeZoneTransaction);
						const isMultiDay = endTime.isSameOrBefore(startTime);
						if (isMultiDay) {
							endTime.add(1, 'day');
							entry.endTime = endTime.utc().format();
						}
					}

					entry.timesheetBreaks = formatTimesheetEntryBreaks(entry, transactionDate, entry.locationId);

					return entry;
				});
		} else {
			return [];
		}
	};

	const formatTimesheetEntryBreaks = (entry, transactionDate, locationId) => {
		if (!entry.timesheetBreaks)
			return [];

		const timeZoneTransaction =
			timesheet?.booking?.locations?.find(
				(location) => location.id === locationId
			)?.timeZone || moment.tz.guess();

		const timesheetBreaks = cloneDeep(entry.timesheetBreaks);

		return timesheetBreaks
			.filter((timesheetBreak) => timesheetBreak.startTime && timesheetBreak.endTime)
			.map((timesheetBreak) => {
				timesheetBreak.startTime = convertTimeToUTC(timesheetBreak.startTime, transactionDate, timeZoneTransaction);
				timesheetBreak.endTime = convertTimeToUTC(timesheetBreak.endTime, transactionDate, timeZoneTransaction);

				if (moment(timesheetBreak.startTime).isBefore(entry.startTime)) {
					timesheetBreak.startTime = moment(timesheetBreak.startTime).add(1, 'day').utc().format();
					timesheetBreak.endTime = moment(timesheetBreak.endTime).add(1, 'day').utc().format();
				}

				if (moment(timesheetBreak.endTime).isBefore(timesheetBreak.startTime)) {
					timesheetBreak.endTime = moment(timesheetBreak.endTime).add(1, 'day').utc().format();
				}

				return timesheetBreak;
			});
	};

	const formatMileageEntries = (entries) => {
		if (entries) {
			return entries
				.filter((entry) => entry.transactionDate)
				.map((entry) => {
					entry.id = entry.id;
					entry.transactionDate = moment.tz(entry.transactionDate, 'Greenwich').format();
					entry.locationId = entry.locationId === '' ? null : entry.locationId;
					entry.miles = entry.miles === '' ? null : entry.miles;
					entry.comment = entry.comment === '' ? null : entry.comment;
					return entry;
				});
		} else {
			return [];
		}
	};

	const formatTimesheet = (data) => {
		let newTimesheet = cloneDeep(timesheet);
		if (!settingDidNotWork.current) {
			newTimesheet.status =
				timesheet.status === TimesheetStatusEnumApi.NotStarted && !isDirty
					? TimesheetStatusEnumApi.NotStarted
					: TimesheetStatusEnumApi.PendingSubmission;
		}
		newTimesheet.timesheetEntries = formatTimesheetEntries(data.timesheetEntries);
		newTimesheet.mileageEntries = formatMileageEntries(data.mileageEntries);
		return newTimesheet;
	};

	const generateDateLookup = (weekEnding, bookingStart, bookingEnd) => {
		let dates = [];
		const format = 'YYYY-MM-DDTHH:mm:ss';
		const startDate = moment.tz(bookingStart, 'Greenwich');
		const endDate = moment.tz(bookingEnd, 'Greenwich');
		for (let index = 0; index < 7; index++) {
			let date = moment.tz(weekEnding, 'Greenwich').subtract(index, 'day');
			const inBooking = date.isSameOrAfter(startDate) && date.isSameOrBefore(endDate);
			if (inBooking) {
				dates = [
					...dates,
					{
						label: date.format('ddd M/D'),

						value: date.format(format),
					},
				];
			}
		}
		const sortedDates = dates.sort((a, b) => moment(a.value) - moment(b.value));
		setDateLookup(sortedDates);
	};

	const formatPeriod = (weekEnding) => {
		let endDate = moment(weekEnding).format('MMM D, YYYY');
		let startDate = moment(weekEnding).subtract(6, 'days').format('MMM D');
		return `Week of ${startDate} - ${endDate}`;
	};

	const checkIfReadOnly = (review) =>
		review.status !== 'NotStarted' &&
		review.status !== 'PendingSubmission' &&
		review.status !== 'Rejected' &&
		review.status !== 'DidNotWork';

	const handleDirtyCancel = () => {
		if (isDirty || cleared) {
			setCancelModalOpen(true);
		} else {
			history.push('/timesheets');
		}
	};

	const handleRecall = async () => {
		setRecallWarningDialogOpen(false);
		setIsLoaded(false);
		const data = methods.getValues();
		const path = `/timesheet/${timesheet.id}/edit?weekEnding=${timesheet.weekEnding}`;
		let newTimesheet = formatTimesheet(data);
		try {
			const currentTimesheet = await getTimesheetById(externalId, timesheet.id);
			setRecallErrorType('updated');
			const currentTimsheetHasEntryInRecallableStatus =
				currentTimesheet?.timesheetReviews?.some(
					(review) => review.status === TimesheetStatusEnumApi.Submitted
				);
			if (!currentTimsheetHasEntryInRecallableStatus) {
				setRecallErrorDialogOpen(true);
				setIsLoaded(true);
			} else {
				newTimesheet.status = TimesheetStatusEnumApi.PendingSubmission;
				await saveTimesheet(newTimesheet, timesheet.id, true);
				await fetchTimesheetById();
				history.replace(path);
			}
		} catch (error) {
			setIsLoaded(true);
			var message = formatErrorMessage(error);
			if (message === 'An error has occurred.') message = 'Timesheet has failed to be saved. Please try again.';
			setError(message);
			console.error(message);
		}
	};

	const resetEntries = (entries, key) => {
		if (!entries) {
			return [];
		}

		const isTimesheetEntry = key === 'timesheetEntry';
		const newTimesheetEntry = {
			id: 0,
			transactionDate: '',
			locationId: '',
			rateTypeId: '',
			relatedEntryId: '',
			startTime: '',
			endTime: '',
			units: '',
			comment: '',
		};

		let newEntries = entries
			.map((entry, index) => {
				const canReset = !readOnlyLocations.includes(entry.locationId);
				if (canReset) {
					return isTimesheetEntry && entry.transactionDate
						? { ...newTimesheetEntry, id: -(index + 1), transactionDate: entry.transactionDate }
						: null;
				} else {
					return entry;
				}
			})
			.filter(Boolean);

		if (isTimesheetEntry) {
			// Only include entries where transactionDate is in dateLookup or is read only
			newEntries = newEntries.filter((entry) => dateLookup.some((d) => readOnlyLocations.includes(entry.locationId) || d.value === entry.transactionDate));

			// Add back missing days
			dateLookup.forEach((date) => {
				const exists = newEntries.some((entry) => entry.transactionDate === date.value);
				if (!exists) {
					newEntries = [
						...newEntries,
						{ ...newTimesheetEntry, id: -(newEntries.length + 1), transactionDate: date.value },
					];
				}
			});

			// Only 1 blank entry per day (don't filter our any read-only entries)
			newEntries = newEntries.filter((entry, index, self) => {
				return readOnlyLocations.includes(entry.locationId) || (self.findIndex((e) => e.transactionDate === entry.transactionDate) === index);
			});
		}

		return newEntries.sort((a, b) => new Date(a.transactionDate) - new Date(b.transactionDate));
	};

	const handleAlertClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setError(null);
	};

	const handleClear = () => {
		const data = methods.getValues();
		const timesheetEntries = resetEntries(data.timesheetEntries, 'timesheetEntry');
		const mileageEntries = resetEntries(data.mileageEntries, 'mileageEntry');
		setTotalTimesheetHours(0);
		methods.reset({ timesheetEntries, mileageEntries });
		setCleared(true);
		setClearDialogOpen(false);
	};

	const isBlankEntry = (isMileageEntry, entry) => {
		if (isMileageEntry) {
			return (
				entry.transactionDate === '' &&
				entry.locationId === '' &&
				entry.miles === '' &&
				entry.comment === ''
			);
		} else {
			return (
				entry.locationId === '' &&
				entry.rateTypeId === '' &&
				entry.startTime === '' &&
				entry.endTime === '' &&
				entry.units === '' &&
				entry.comment === ''
			);
		}
	};
	const handleRemove = (entry, index) => {
		const isMileageEntry = entry.hasOwnProperty('miles');
		const blankEntry = isBlankEntry(isMileageEntry, entry);
		if (blankEntry && isMileageEntry) {
			mileageRemove(index);
		} else if (blankEntry && !isMileageEntry) {
			timesheetRemove(index);
		} else {
			setRemoveDialogOpen({ isMileageEntry, index });
		}
	};

	const renderEditButtons = () => {
		const recallOptionAvailable = timesheet?.timesheetReviews?.some(
			(review) => review.status === TimesheetStatusEnumApi.Submitted
		);
		const editOptionsAvailable =
			timesheet?.timesheetReviews?.some(
				(review) =>
					review.status === TimesheetStatusEnumApi.NotStarted ||
					review.status === TimesheetStatusEnumApi.PendingSubmission ||
					review.status === TimesheetStatusEnumApi.Rejected ||
					review.status === TimesheetStatusEnumApi.DidNotWork
			) ||
			timesheet?.status === TimesheetStatusEnumApi.NotStarted ||
			timesheet?.status === TimesheetStatusEnumApi.PendingSubmission ||
			timesheet?.status === TimesheetStatusEnumApi.DidNotWork ||
			!timesheet?.status;
		return (
			<>
				<Hidden only={editOptionsAvailable ? [] : ['xs', 'sm', 'md', 'lg', 'xl']}>
					<Grid item xs={isMobile && isMobileUiV2Enabled ? 12 : undefined}>
						<Button
							variant='contained'
							classes={isMobileUiV2Enabled ? undefined : { root: classes.cancelButton }}
							className={classes.mobileButton}
							onClick={handleDirtyCancel}
						>
							Cancel
						</Button>
					</Grid>
				</Hidden>
				<Hidden only={editOptionsAvailable ? [] : ['xs', 'sm', 'md', 'lg', 'xl']}>
					<Grid item xs={isMobile && isMobileUiV2Enabled ? 12 : undefined}>
						<Button
							variant='contained'
							color='primary'
							classes={{ root: classes.saveButton }}
							className={classes.mobileButton}
							onClick={validateSave}
						>
							Save
						</Button>
					</Grid>
				</Hidden>
				<Hidden only={editOptionsAvailable ? [] : ['xs', 'sm', 'md', 'lg', 'xl']}>
					<Grid item xs={isMobile && isMobileUiV2Enabled ? 12 : undefined}>
						<Button
							variant='contained'
							classes={{ root: classes.containedSuccess }}
							className={classes.mobileButton}
							onClick={() => validateSubmit()}
						>
							Review/Submit
						</Button>
					</Grid>
				</Hidden>
				<Hidden only={recallOptionAvailable ? [] : ['xs', 'sm', 'md', 'lg', 'xl']}>
					<Grid item xs={isMobile && isMobileUiV2Enabled ? 12 : undefined}>
						<Button
							variant='contained'
							classes={{ root: classes.cancelButton }}
							className={classes.mobileButton}
							onClick={() => setRecallWarningDialogOpen(true)}
						>
							Recall
						</Button>
					</Grid>
				</Hidden>
			</>
		);
	};

	const renderClinicianHeader = () => {
		return (
			<Grid container direction='column' spacing={2}>
				<Grid item>
					<Button
						variant='text'
						color='primary'
						startIcon={<ChevronLeft />}
						onClick={() => {
							if (isDirty || cleared) {
								setCancelModalOpen(true);
							} else {
								history.push('/timesheets');
							}
						}}
					>
						<Typography variant='overline' className={classes.overline1}>
							{t('submitTimesheet:LINKS.GO_BACK_TO_MY_TIMESHEETS')}
						</Typography>
					</Button>
				</Grid>
				<Grid item>
					<Typography variant='h4' component='h2' className={classes.weekEnding}>
						{formatPeriod(weekEnding)}
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant='body2' className={classes.bookingName}>
						{timesheet.booking.name}
					</Typography>
				</Grid>
			</Grid>
		);
	};

	const renderAdminHeader = () => {
		return (
			<Grid container direction='column' spacing={2}>
				<Grid item>
					<Button
						variant='text'
						color='primary'
						startIcon={<ChevronLeft />}
						onClick={() => {
							if (isDirty || cleared) {
								setCancelModalOpen(true);
							} else {
								history.push('/timesheets');
							}
						}}
					>
						<Typography variant='overline' className={classes.overline1}>
							All Timesheets
						</Typography>
					</Button>
				</Grid>
				<Grid item>
					<Grid container direction='column'>
						<Grid item className={classes.clinicianNameHeader}>
							<Typography variant='h4' component='h2' className={classes.header}>
								Timesheet for {timesheet?.clinicianName || ''}
							</Typography>
						</Grid>
						<Grid item>
							<Typography variant='h5' className={`${classes.weekEnding} admin`}>
								{formatPeriod(weekEnding)}
							</Typography>
						</Grid>
						<Grid item>
							<Typography variant='caption' className={classes.bookingName}>
								{timesheet?.booking?.name || ''}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		);
	};

	const renderTimesheetRejectionReasons = () => {
		const rejectedTimesheetReviews = timesheet?.timesheetReviews?.filter(
			(timesheetReview) => timesheetReview.status === TimesheetStatusEnumApi.Rejected
		);

		return (
			<>
				{rejectedTimesheetReviews.map((rejectedTimesheet, i) => {
					const rejectedComment = rejectedTimesheet?.rejectionReason;
					let reviewer = timesheet?.booking?.locations?.find(
						(location) => rejectedTimesheet?.locationId === location.id
					)?.displayName;
					return (
						<Alert
							severity='error'
							className={
								i === rejectedTimesheetReviews.length - 1
									? classes.lastRejectionReasonAlert
									: classes.rejectReasonAlert
							}
						>
							<Grid container alignItems='center'>
								{reviewer?.length && reviewer?.length > 0
									? `${t('submitTimesheet:HEADERS.TIMESHEET_REJECTED_BY')} ${
											reviewer || ''
									  }. ${t('submitTimesheet:HEADERS.TIMESHEET_REJECTED')}`
									: `${t('submitTimesheet:HEADERS.TIMESHEET_WAS_REJECTED')} ${
											rejectedComment?.length && rejectedComment?.length > 0
												? t(
														'submitTimesheet:HEADERS.CHECK_FACILITY_COMMENT'
												  )
												: ''
									  }`}
							</Grid>
							{rejectedComment?.length > 0 && (
								<>
									<ul className={classes.errorListItem}>
										<li>
											<Grid container alignItems='center'>
												<Typography
													variant='body2'
													classes={{ root: classes.errorText }}
												>
													Comment from the facility: "
													{rejectedTimesheet?.rejectionReason || ''}"
												</Typography>
											</Grid>
										</li>
									</ul>
								</>
							)}
						</Alert>
					);
				})}
			</>
		);
	};

	const renderHolidayDialog = () => {
		return (
			<Dialog
				open={holidayModalOpen}
				onClose={() => setHolidayModalOpen(false)}
				aria-labelledby='holiday-modal-title'
				aria-describedby='holiday-modal-description'
				classes={{ paper: classes.dialogPaperRoot }}
				fullWidth
				maxWidth='xs'
			>
				<DialogTitle
					classes={{ root: classes.dialogTitleRoot }}
					disableTypography
					id='holiday-modal-title'
				>
					Holiday Detected
				</DialogTitle>
				<DialogContent
					id='holiday-modal-description'
					classes={{ root: classes.dialogContentRoot }}
				>
					You have a recognized holiday on your timesheet for ({holidayModalProperties?.name} - {holidayModalProperties?.date}), do you want to indicate it?
				</DialogContent>
				<DialogActions classes={{ root: classes.dialogActionsRoot }}>
					<Button
						onClick={handleHolidayModalNo}
						color='primary'
						className={classes.underlinedButton}
					>
						No
					</Button>
					<Button
						variant='contained'
						color='primary'
						onClick={handleHolidayModalYes}
					>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	const handleCommentWarningAlertModalNo = () => {
		if (commentWarningAlertModalOpen) {
			validateSubmitAfterEmptyCheck(false, false);
		}

		setCommentWarningAlertModalOpen(false);
	};

	const handleHolidayModalYes = () => {
		if (holidayModalOpen) {
			timesheetAppend({
				id: generateNextId(),
				transactionDate: holidayModalProperties.transactionDate,
				locationId: holidayModalProperties.locationId,
				rateTypeId: holidayModalProperties.rateTypeId,
				relatedEntryId: '',
				units: '',
				startTime: '',
				endTime: '',
				comment: '',
			});
		}

		setHolidayModalOpen(false);
	};

	const handleHolidayModalNo = () => {
		setHolidayModalOpen(false);
		validateSubmitAfterEmptyCheck(true, false);
	};

	const renderMissingShiftOnLoggedHolidayDialog = () => {
		return (
			<Dialog
				open={missingShiftOnLoggedHolidayModelOpen}
				onClose={() => setMissingShiftOnLoggedHolidayModelOpen(false)}
				aria-labelledby='missingShiftOnLoggedHoliday-modal-title'
				aria-describedby='missingShiftOnLoggedHoliday-modal-description'
				classes={{ paper: classes.dialogPaperRoot }}
				fullWidth
				maxWidth='xs'
			>
				<DialogTitle
					classes={{ root: classes.dialogTitleRoot }}
					disableTypography
					id='missingShiftOnLoggedHoliday-modal-title'
				>
					Holiday Shift Detected
				</DialogTitle>
				<DialogContent
					id='missingShiftOnLoggedHoliday-modal-description'
					classes={{ root: classes.dialogContentRoot }}
				>
					{missingShiftOnLoggedHolidayModelProperties?.date} is marked as a Holiday ({missingShiftOnLoggedHolidayModelProperties?.name}). Do you want to enter a shift?
				</DialogContent>
				<DialogActions classes={{ root: classes.dialogActionsRoot }}>
					<Button
						onClick={handleMissingShiftOnLoggedHolidayModelNo}
						color='primary'
						className={classes.underlinedButton}
					>
						No
					</Button>
					<Button
						variant='contained'
						color='primary'
						onClick={handleMissingShiftOnLoggedHolidayModelYes}
					>
						Yes
					</Button>
				</DialogActions>
			</Dialog>

		);
	};

	const handleMissingShiftOnLoggedHolidayModelYes = () => {
		setMissingShiftOnLoggedHolidayModelOpen(false);
		timesheetAppend({
			id: generateNextId(),
			transactionDate: missingShiftOnLoggedHolidayModelProperties.transactionDate,
			locationId: missingShiftOnLoggedHolidayModelProperties.locationId,
			rateTypeId: '',
			relatedEntryId: '',
			units: '',
			startTime: '',
			endTime: '',
			comment: '',
		});
	};

	const handleMissingShiftOnLoggedHolidayModelNo = () => {
		setMissingShiftOnLoggedHolidayModelOpen(false);
		validateSubmitAfterEmptyCheck(false, true);
	};

	const renderEmptyTimesheetDialog = () => {
		return (
			<Dialog
				open={emptyTimesheetModalOpen}
				onClose={() => setEmptyTimesheetModalOpen(false)}
				aria-labelledby='holiday-modal-title'
				aria-describedby='holiday-modal-description'
				classes={{ paper: classes.dialogPaperRoot }}
				fullWidth
				maxWidth='xs'
			>
				<DialogTitle
					classes={{ root: classes.dialogTitleRoot }}
					disableTypography
					id='holiday-modal-title'
				>
					Empty Timesheet
				</DialogTitle>
				<DialogContent
					id='holiday-modal-description'
					classes={{ root: classes.dialogContentRoot }}
				>
					There is no data in the timesheet you are submitting.  If this was done in error then select <b>Cancel</b> to return to the timesheet and enter your shift data for the week.  If this is accurate and you did not work any shifts this week, then select <b>Did Not Work</b> and the status will be updated for this record.
				</DialogContent>
				<DialogActions classes={{ root: classes.dialogActionsRoot }}>
					<Button
						onClick={handleEmptyTimesheetModalNo}
						color='primary'
						className={classes.underlinedButton}
					>
						Cancel
					</Button>
					<Button
						variant='contained'
						color='primary'
						onClick={handleEmptyTimesheetModalYes}
					>
						Did Not Work
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	const handleEmptyTimesheetModalYes = () => {
		timesheet.status = TimesheetStatusEnumApi.DidNotWork;
		settingDidNotWork.current = true;
		setEmptyTimesheetModalOpen(false);
		validateSubmitAfterEmptyCheck(true, true);
	};

	const handleEmptyTimesheetModalNo = () => {
		setEmptyTimesheetModalOpen(false);
		settingDidNotWork.current = false; // just in case it was set somehow
	};

	const renderCancelDialog = () => {
		return (
			<Dialog
				open={cancelModalOpen}
				onClose={() => setCancelModalOpen(false)}
				aria-labelledby='cancel-modal-title'
				aria-describedby='cancel-modal-description'
				classes={{ paper: classes.dialogPaperRoot }}
				fullWidth
				maxWidth='xs'
			>
				<DialogTitle
					classes={{ root: classes.dialogTitleRoot }}
					disableTypography
					id='cancel-modal-title'
				>
					Unsaved changes
				</DialogTitle>
				<DialogContent
					id='cancel-modal-description'
					classes={{ root: classes.dialogContentRoot }}
				>
					Your changes have not been saved. Are you sure you want to leave this page with
					unsaved changes?
				</DialogContent>
				<DialogActions classes={{ root: classes.dialogActionsRoot }}>
					<Button
						component={RouterLink}
						to={{ pathname: '/timesheets' }}
						color='primary'
						className={classes.underlinedButton}
					>
						Discard changes
					</Button>
					<Button
						variant='contained'
						color='primary'
						onClick={() => setCancelModalOpen(false)}
					>
						Stay
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	const renderTimesheetFormHeader = () => {
		return (
			<Grid className={classes.headerContainer} container alignItems='center'>
				<Grid className={classes.headerCell} item xs={1} md={2} lg={1}>
					<span className={classes.paragraph3}>Date</span>
				</Grid>
				<Grid className={classes.headerCell} item xs={3} md={2} lg={3}>
					<Grid container direction='row' alignItems='center'>
						<div className={classes.paragraph3}>Work Location</div>
						<ToolTipComponent
							disableFocusListener
							enterTouchDelay={150}
							interactive
							placement='right'
							title={
								<>
									<span>Location worked at for a given date</span>
								</>
							}
						>
							<InfoOutlined classes={{ root: classes.infoIcon }} />
						</ToolTipComponent>
					</Grid>
				</Grid>
				<Grid className={classes.headerCell} item xs={1} md={2} lg={1}>
					<Grid container direction='row' alignItems='center'>
						<div className={classes.paragraph3}>Shift Type</div>
						<ToolTipComponent
							disableFocusListener
							enterTouchDelay={150}
							interactive
							placement='right'
							title={
								<>
									<span>Type of worked performed (e.g. Regular)</span>
								</>
							}
						>
							<InfoOutlined classes={{ root: classes.infoIcon }} />
						</ToolTipComponent>
					</Grid>
				</Grid>
				<Grid className={classes.headerCell} item xs={1}>
					<Grid container alignItems='center'>
						<span className={classes.paragraph3}>Units</span>
						<ToolTipComponent
							disableFocusListener
							enterTouchDelay={150}
							interactive
							placement='right'
							title={
								<>
									<span>
										Indicator for specific shift types that do not require start
										and end times.
									</span>
								</>
							}
						>
							<InfoOutlined classes={{ root: classes.infoIcon }} />
						</ToolTipComponent>
					</Grid>
				</Grid>
				<Grid className={classes.headerCell} item xs={1}>
					<span className={classes.paragraph3}>Start</span>
				</Grid>
				<Grid className={classes.headerCell} item xs={1}>
					<span className={classes.paragraph3}>End</span>
				</Grid>
				<Grid className={classes.headerCell} item xs={1}>
					<Grid container alignItems='center'>
						<span className={classes.paragraph3}>Hours</span>
						<ToolTipComponent
							disableFocusListener
							enterTouchDelay={150}
							interactive
							placement='right'
							title={
								<>
									<span>
										The Total hours calculated by the Start and End time of the shift and breaks.
									</span>
								</>
							}
						>
							<InfoOutlined classes={{ root: classes.infoIcon }} />
						</ToolTipComponent>
					</Grid>
				</Grid>
				<Grid className={classes.headerCell} item xs={4} md={2}>
					<Grid container direction='row' alignItems='center'>
						<div className={classes.paragraph3}>Comments</div>
						<ToolTipComponent
							disableFocusListener
							enterTouchDelay={150}
							interactive
							placement='right'
							title={
								<>
									<span>
										Comments will be visible by LT and facility staff.
										Recommendations:
										<ul>
											<li>Do not list billable hours or call</li>
											<li>Do not include rates</li>
											<li>Do not include mileage</li>
											<li>Include explanation as needed</li>
											<li>Include logs for time work as needed</li>
										</ul>
									</span>
								</>
							}
						>
							<InfoOutlined classes={{ root: classes.infoIcon }} />
						</ToolTipComponent>
					</Grid>
				</Grid>
			</Grid>
		);
	};

	const renderTimesheetsHeader = () => {
		return (
			<Grid container>
				<Grid container spacing={2} alignItems='center' justifyContent='space-between'>
					<Grid item>
						<Typography
							variant='h5'
							component='h5'
							gutterBottom
							className={classes.header}
						>
							<span>Shifts</span>
						</Typography>
					</Grid>
					<Grid item>
						<Box display='flex' justifyContent='flex-end'>
							<Typography
								variant='h5'
								component='h5'
								gutterBottom
								className={classes.headerGrey}
							>
								<span>Total Hours: {totalTimesheetHours}</span>
							</Typography>
						</Box>
					</Grid>
				</Grid>
				<Grid container display='flex' justifyContent="flex-end">
					<Grid item>
						<Box>
							<Alert severity='info'>
								All comments are visible to both LocumTenens staff and facility
								supervisors
							</Alert>
						</Box>
					</Grid>
				</Grid>
			</Grid>
		);
	};

	const renderTimesheetFormButtons = () => {
		return (
			<>
				<Grid container justifyContent='space-between'>
					<Grid item xs={isMobile && isMobileUiV2Enabled ? 12 : undefined}>
						<Grid container direction='row' spacing={2}>
							<Grid item xs={isMobile && isMobileUiV2Enabled ? 12 : undefined}>
								<Button
									id='timesheet-entry--button--add-row'
									variant='contained'
									classes={{ root: classes.containedPrimaryLight }}
									className={classes.mobileButton}
									onClick={() => {
										timesheetAppend({
											id: generateNextId(),
											transactionDate: '',
											locationId: '',
											rateTypeId: '',
											relatedEntryId: '',
											units: '',
											startTime: '',
											endTime: '',
											comment: '',
										})
									}}
								>
									Add Shift
								</Button>
							</Grid>
							{mileageEntries.length === 0 && (
								<Grid item xs={isMobile && isMobileUiV2Enabled ? 12 : undefined}>
									<Button
										variant='contained'
										classes={{ root: classes.containedPrimaryLight }}
										className={classes.mobileButton}
										onClick={() => {
											mileageAppend({
												id: 0,
												transactionDate: '',
												locationId: '',
												miles: '',
												comment: '',
											});
										}}
									>
										Add Mileage
									</Button>
								</Grid>
							)}
							<Grid item xs={isMobile && isMobileUiV2Enabled ? 12 : undefined}>
								<Button
									variant='contained'
									color='default'
									className={classes.mobileButton}
									disabled={timesheetEntries.length === 0}
									onClick={() => setClearDialogOpen(true)}
								>
									Clear
								</Button>
							</Grid>
						</Grid>
					</Grid>
					{isAdminApp && historyEnabled && mileageEntries.length === 0 && (
						<Grid item>
							<TimesheetHistory />
						</Grid>
					)}
				</Grid>
			</>
		);
	};

	const renderTimesheetEntries = () => {
		return (
			<>
				{renderTimesheetsHeader()}

				<Hidden only={['xs', 'sm']}>{renderTimesheetFormHeader()}</Hidden>
				<Grid
					container
					direction='column'
					spacing={isMobile ? 3 : 1}
					className={classes.entriesContainer}
				>
					{timesheetEntries.map((timesheetEntry, index) => (		
							<TimesheetEntry
							key={timesheetEntry.timesheetEntryId + '-' + index}
							index={index}
							dateLookup={dateLookup}
							timesheet={timesheet}
							timesheetEntry={timesheetEntry}
							onRemove={handleRemove}
							isPaidApproved={isPaidApproved}
							timesheetInsert={timesheetInsert}
							triggerCallRowLockCheck={triggerCallRowLockCheck}
							setTotalTimesheetHours={setTotalTimesheetHours}
							isLastCallBackEntry={isLastCallBackEntry(index)}
							isMobile={isMobile}
							isMobileUiV2Enabled={isMobileUiV2Enabled}
							isShowWarning={isShowWarning}
							generateNextId={generateNextId}
						/>
					))}
				</Grid>
				{!isReadOnly && !adminAuth?.isLTAssociateUser && renderTimesheetFormButtons()}
			</>
		);
	};

	const isLastCallBackEntry = (index) => {
		if (!timesheetEntries || !timesheetEntries[index].relatedEntryId)
			return false;

		const lastTimesheetEntryIndex = timesheetEntries.findLastIndex(x => x.relatedEntryId === timesheetEntries[index].relatedEntryId);
		return lastTimesheetEntryIndex === index;
	};

	const renderMileageFormHeader = () => {
		return (
			<>
				<Grid className={classes.headerContainer} container>
					<Grid className={classes.headerCell} item xs={1} md={2} lg={2}>
						<span className={classes.paragraph3}>Date</span>
					</Grid>
					<Grid className={classes.headerCell} item xs={6} md={3}>
						<Grid container direction='row' alignItems='center'>
							<div className={classes.paragraph3}>Work Location</div>
							<ToolTipComponent
								disableFocusListener
								enterTouchDelay={150}
								interactive
								placement='right'
								title={
									<>
										<span>
											Work location for associated mileage on a given date
										</span>
									</>
								}
							>
								<InfoOutlined classes={{ root: classes.infoIcon }} />
							</ToolTipComponent>
						</Grid>
					</Grid>
					<Grid className={classes.headerCell} item xs={1}>
						<Grid container direction='row' alignItems='center'>
							<div className={classes.paragraph3}>Mileage</div>
							<ToolTipComponent
								disableFocusListener
								enterTouchDelay={150}
								interactive
								placement='right'
								title={
									<>
										<span>
											Total number of miles associated to a work location
											within a week ending period
										</span>
									</>
								}
							>
								<InfoOutlined classes={{ root: classes.infoIcon }} />
							</ToolTipComponent>
						</Grid>
					</Grid>
					<Grid className={classes.headerCell} item xs={6} md={5} lg={6}>
						<Grid container direction='row' alignItems='center'>
							<div className={classes.paragraph3}>Comments</div>
							<ToolTipComponent
								disableFocusListener
								enterTouchDelay={150}
								interactive
								placement='right'
								title={
									<>
										<span>
											Comments will be visible by LT and facility staff.
											Recommendations:
											<ul>
												<li>Do not list billable hours or call</li>
												<li>Do not include rates</li>
												<li>Do not include mileage</li>
												<li>Include explanation as needed</li>
												<li>Include logs for time work as needed</li>
											</ul>
										</span>
									</>
								}
							>
								<InfoOutlined classes={{ root: classes.infoIcon }} />
							</ToolTipComponent>
						</Grid>
					</Grid>
				</Grid>
			</>
		);
	};

	const renderMileageHeader = () => {
		return (
			<Grid
				container
				justifyContent='space-between'
				alignItems='center'
				className={classes.headerBar}
			>
				<Grid item>
					<Typography variant='h6' component='h3' className={classes.header}>
						<span>Mileage</span>
					</Typography>
				</Grid>
			</Grid>
		);
	};

	const renderMileageFormButtons = () => {
		return (
			<Grid container direction='row' justifyContent='space-between'>
				<Grid item xs={isMobileUiV2Enabled ? 12 : 6} sm={3} md={2}>
					<Button
						id='mileage-entry--button--add-row'
						variant='contained'
						className={classes.mileageAddButton}
						classes={{ root: classes.containedPrimaryLight }}
						onClick={() =>
							mileageAppend({
								id: 0,
								transactionDate: '',
								locationId: '',
								miles: '',
								comment: '',
							})
						}
					>
						{isMobile && isMobileUiV2Enabled ? 'Add Mileage' : 'Add Row'}
					</Button>
				</Grid>
				{isAdminApp && historyEnabled && (
					<Grid item>
						<TimesheetHistory />
					</Grid>
				)}
			</Grid>
		);
	};

	const renderMileageEntries = () => {
		return (
			<>
				{renderMileageHeader()}
				{!isMileageExpected && (
					<Alert severity='warning'>
						Your assignment is not expecting mileage. Are you sure you want to proceed?
						Please connect with your recruiter with questions.
					</Alert>
				)}
				<Hidden only={['xs', 'sm']}>{renderMileageFormHeader()}</Hidden>
				<Grid
					container
					direction='column'
					spacing={isMobile ? 3 : 1}
					className={classes.entriesContainer}
				>
					{mileageEntries.map((mileageEntry, index) => (
						<Grid item key={mileageEntry.mileageEntryId} xs={12}>
							<MileageEntry
								index={index}
								dateLookup={dateLookup}
								timesheet={timesheet}
								mileageEntry={mileageEntry}
								onRemove={handleRemove}
								isPaidApproved={isPaidApproved}
								isMobile={isMobile}
								isMobileUiV2Enabled={isMobileUiV2Enabled}
								isShowWarning={isShowWarning}
							/>
						</Grid>
					))}
				</Grid>
				{!isReadOnly && !adminAuth?.isLTAssociateUser && renderMileageFormButtons()}
			</>
		);
	};

	const renderErrorBanner = () => {
		return (<Grid item>
			{isSave ? (
				<Alert severity='error'>
					Please fill out all missing or incorrect cells to save your
					timesheet
				</Alert>
			) : (
				<Alert severity='error'>
					Please fill out all missing or incorrect cells to submit
					your timesheet
				</Alert>
			)}
		</Grid>)
	};

	const renderPageGrid = () => {
		return (
			<Container maxWidth='xl' className={classes.ContainerRoot}>
				<FormProvider {...methods}>
					<Grid container direction='column' spacing={4}>
						<Grid item>
							{isAdminApp ? renderAdminHeader() : renderClinicianHeader()}
						</Grid>
						{formHasRejectedTimesheet && (
							<Grid item>{renderTimesheetRejectionReasons()}</Grid>
						)}
						{!isMobileUiV2Enabled && Object.keys(methods.formState.errors).length > 0 ? renderErrorBanner() : undefined}
						<Grid item xs={12}>
							<form ref={formRef} tabIndex='0' className={classes.form}>
								<Paper elevation={3} className={classes.PaperRoot}>
									<div id='timesheet-entries' className={classes.entrySection}>
										{renderTimesheetEntries()}
									</div>

									{mileageEntries.length > 0 && (
										<div id='mileage-entries' className={classes.entrySection}>
											{renderMileageEntries()}
										</div>
									)}
								</Paper>
							</form>
						</Grid>
						{isMobileUiV2Enabled && Object.keys(methods.formState.errors).length > 0 ? renderErrorBanner() : undefined}
						<Grid item>
							<Grid container justifyContent='flex-end' spacing={2}>
								{(isReadOnly && isPaidApproved) || adminAuth?.isLTAssociateUser
									? null
									: renderEditButtons()}
							</Grid>
						</Grid>
					</Grid>
				</FormProvider>
			</Container>
		);
	};

	const renderRecallWarningDialog = () => {
		return (
			<Dialog
				open={recallWarningDialogOpen}
				onClose={() => {
					setRecallWarningDialogOpen(false);
				}}
				aria-labelledby='recall-warning-dialog-title'
				aria-describedby='recall-warning-dialog-description'
				fullWidth
				maxWidth='xs'
				classes={{ paper: classes.dialogPaperRoot }}
			>
				<DialogContent classes={{ root: classes.dialogContentRoot }}>
					<Grid
						container
						direction='column'
						justifyContent='center'
						alignItems='center'
						className={classes.dialogGrid}
					>
						<Grid item>
							<Grid container alignContent='center' justifyContent='center'>
								<PriorityHigh
									fontSize='large'
									className={classes.dialogWarningIcon}
								></PriorityHigh>
							</Grid>
						</Grid>
						<Grid item>
							<Typography variant='h5' align='center'>
								Recalling a timesheet stops the approval process and will require
								the recalled timesheets to be re-submitted.
							</Typography>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions
					classes={{ root: classes.dialogActionsRoot }}
					style={{ justifyContent: 'center' }}
				>
					<Button variant='contained' onClick={() => setRecallWarningDialogOpen(false)}>
						Cancel
					</Button>
					<Button variant='contained' color='primary' onClick={handleRecall}>
						Recall
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	const renderRemoveDialog = () => (
		<Dialog
			open={removeDialogOpen ? true : false}
			onClose={() => setRemoveDialogOpen(false)}
			aria-labelledby='remove-dialog-title'
			aria-describedby='remove-dialog-description'
			fullWidth
			maxWidth='xs'
			classes={{ paper: classes.dialogPaperRoot }}
		>
			<DialogTitle
				id='remove-dialog-title'
				classes={{ root: classes.dialogTitleRoot }}
				disableTypography
			>
				Are you sure?
			</DialogTitle>
			<DialogContent classes={{ root: classes.dialogContentRoot }}>
				Removing this item cannot be undone. Are you sure you would like to remove this?
			</DialogContent>
			<DialogActions classes={{ root: classes.dialogActionsRoot }}>
				<Button
					color='primary'
					className={classes.underlinedButton}
					onClick={() => setRemoveDialogOpen(false)}
				>
					No, go back
				</Button>
				<Button
					color='primary'
					className={classes.cancelButton}
					variant='contained'
					onClick={() => {
						// Redo validations when removing rows
						const entryType = removeDialogOpen.isMileageEntry ? 'mileageEntries' : 'timesheetEntries';
						const index = removeDialogOpen.index;

						if (entryType === 'mileageEntries') {
							methods.clearErrors(`mileageEntries[${index}]`);
						}

						else {
							for (let i = 0; i < timesheetEntries.length; i++) {
								methods.clearErrors(`timesheetEntries[${i}]`);
							}
						}

						if (removeDialogOpen.isMileageEntry) {
							mileageRemove(removeDialogOpen.index);
						}

						else {
							const entryToRemove = methods.getValues().timesheetEntries[removeDialogOpen.index];
							let data = methods.getValues();

							const remainingEntries = data.timesheetEntries.filter(entry =>
								entry.relatedEntryId !== entryToRemove.id && entry.id !== entryToRemove.id
							);

							data.timesheetEntries = remainingEntries;

							methods.setValue('timesheetEntries', remainingEntries);

							revalidateErrors(data);
						}

						setRemoveDialogOpen(false);
					}}
					startIcon={<DeleteOutline fontSize='small' />}
				>
					Remove
				</Button>
			</DialogActions>
		</Dialog>
	);

	const renderClearDialog = () => (
		<Dialog
			open={clearDialogOpen}
			onClose={() => setClearDialogOpen(false)}
			aria-labelledby='clear-dialog-title'
			aria-describedby='clear-dialog-description'
			fullWidth
			maxWidth='xs'
			classes={{ paper: classes.dialogPaperRoot }}
		>
			<DialogTitle
				id='clear-dialog-title'
				classes={{ root: classes.dialogTitleRoot }}
				disableTypography
			>
				Are you sure?
			</DialogTitle>
			<DialogContent classes={{ root: classes.dialogContentRoot }}>
				Clearing all your data cannot be undone. Are you sure you would like to clear all
				data?
			</DialogContent>
			<DialogActions classes={{ root: classes.dialogActionsRoot }}>
				<Button
					color='primary'
					className={classes.underlinedButton}
					onClick={() => setClearDialogOpen(false)}
				>
					No, go back
				</Button>
				<Button
					color='primary'
					className={classes.cancelButton}
					variant='contained'
					onClick={handleClear}
				>
					Clear
				</Button>
			</DialogActions>
		</Dialog>
	);

	const renderErrorAlert = () => (
		<Snackbar
			open={Boolean(error)}
			autoHideDuration={6000}
			onClose={handleAlertClose}
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
		>
			<Alert onClose={handleAlertClose} severity='error'>
				{error}
			</Alert>
		</Snackbar>
	);

	const renderCommentWarningAlert = (aCommentWordList) => {
		return (
			<Dialog
				open={commentWarningAlertModalOpen}
				onClose={() => setCommentWarningAlertModalOpen(false)}
				aria-labelledby='commentwarning-modal-title'
				aria-describedby='commentwarning-modal-description'
				classes={{ paper: classes.dialogPaperRoot }}
				fullWidth
				maxWidth='xs'
			>
				<DialogTitle
					classes={{ root: classes.dialogTitleRoot }}
					disableTypography
					id='commentwarning-modal-title'
				>
					Comment Warning Detected
				</DialogTitle>
				<DialogContent
					id='commentwarning-modal-description'
					classes={{ root: classes.dialogContentRoot }}
				>
					We detected you may have entered {aCommentWordList} information in a comment.<br/><br/> To help reduce potential delays in timesheet processing, please enter the information directly in the timesheet.<br/><br/> Review the Help information for additional information.<br/><br/>
				</DialogContent>
				<DialogActions classes={{ root: classes.dialogActionsRoot }} 
							style={{ justifyContent: 'center' }}>
					<Button 
						color='primary'
						className={classes.underlinedButton}
						onClick={() => setCommentWarningAlertModalOpen(false)}
					>
						Go back
					</Button>
					<Button
						variant='contained'
						color='primary'
						onClick={handleCommentWarningAlertModalNo}
					>
						Continue
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	const renderRecallErrorDialog = () => {
		const props = {
			name: 'recall-error',
			message: `This timesheet has been recently ${recallErrorType} and is not available
								to be recalled. Please reach out to your recruiter with any
								questions.`,
			onClose: () => {
				setRecallErrorDialogOpen(false);
				setIsLoaded(false);
			},
			onExited: () => {
				history.go(0);
			},
			open: recallErrorDialogOpen,
		};
		return <StatusErrorDialog {...props} />;
	};

	return (
		<div className={classes.root}>
			{!!errorLoadingTimesheet ? (
				<h1>{errorLoadingTimesheet}</h1>
			) : isLoaded ? (
				<>{renderPageGrid()}</>
			) : (
				<CircularProgress classes={{ root: classes.progressRoot }} color='primary' />
			)}
			{renderRecallWarningDialog()}
			{renderClearDialog()}
			{renderRemoveDialog()}
			{renderHolidayDialog()}
			{renderMissingShiftOnLoggedHolidayDialog()}
			{renderEmptyTimesheetDialog()}
			{renderCancelDialog()}
			{renderRecallErrorDialog()}
			{renderErrorAlert()}
			{renderCommentWarningAlert(new Intl.ListFormat().format(commentWordList.current))}
		</div>
	);
}

Timesheet.propTypes = {
	timesheet: PropTypes.shape({
		id: PropTypes.string,
		bookingId: PropTypes.number,
		clinicianId: PropTypes.number,
		weekEnding: PropTypes.string,
		status: PropTypes.string,
		comment: PropTypes.string,
		mileageEntries: PropTypes.array,
		timesheetEntries: PropTypes.array,
		timesheetReview: PropTypes.array,
	}),
};

export default WithContexts(withRouter(withTranslation()(Timesheet)));
