/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import {
	Paper,
	Typography,
	Button,
	IconButton,
	Chip,
	withStyles,
	Grid,
	Hidden,
	useTheme,
	useMediaQuery,
	Menu,
	MenuItem,
	FormControlLabel,
	RadioGroup,
	Radio,
	Dialog,
	DialogContent,
	CircularProgress,
	DialogActions,
} from '@material-ui/core';
import { COLORS } from '../../../utils/Application_Constants';
import { FlipToFront, HighlightOffOutlined, MoreVert, PriorityHigh } from '@material-ui/icons';
import moment from 'moment';
import WithContexts from '../../../contexts/withContexts';
import { Link, withRouter, useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { getTimesheetById, saveTimesheet } from '../../../services/TimeAndPayService';
import { getSamlResponse } from '../../../services/peoplenetSsoService';
import { cloneDeep } from 'lodash';
import { TimesheetStatusEnumApi, TimesheetStatusEnum } from './Status.enum';
import StatusErrorDialog from '../../../Shared/Components/StatusErrorDialog';

const styles = (theme) => ({
	chipRoot: {
		textAlign: 'center',
		width: '100%',

		[theme.breakpoints.up('lg')]: {
			minWidth: '175px',
		},
	},
	chipSubmitted: {
		backgroundColor: COLORS.GLITTER,
		color: COLORS.RICH_ELECTRIC_BLUE,
		borderColor: COLORS.RICH_ELECTRIC_BLUE,
	},
	chipApproved: {
		backgroundColor: COLORS.AQUA_SPRING,
		color: COLORS.KELLY_GREEN,
		borderColor: COLORS.KELLY_GREEN,
	},
	chipRejected: {
		backgroundColor: COLORS.LT_PEPPER10,
		color: COLORS.LT_PEPPER,
		borderColor: COLORS.LT_PEPPER,
	},
	chipInProgress: {
		backgroundColor: '#fff2cc',
		color: '#f1c232',
	},
	chipDueIn: {
		backgroundColor: COLORS.LT_SUNFLOWER25,
		color: '#AD7829',
	},
	chipPastDue: {
		backgroundColor: COLORS.LT_PEPPER10,
		color: COLORS.LT_PEPPER,
		borderColor: COLORS.LT_PEPPER,
	},
	chipPaid: {
		color: 'white',
		background: COLORS.LT_EMERALD,
	},
	tableContainer: {
		border: `1px solid ${COLORS.LT_MIDNIGHT5}`,
		borderRadius: '8px',
		alignSelf: 'flex-start',
	},
	tableRow: {
		width: '100%',
		borderBottom: `1px solid ${COLORS.LT_MIDNIGHT5}`,
		'&:last-child': {
			borderBottom: 'none',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '16px',
			gap: '16px',
		},
	},
	tableCell: {
		padding: '16px',
		color: COLORS.LT_MIDNIGHT,
		[theme.breakpoints.down('sm')]: {
			padding: '8px 16px',
			'&:not(:last-child)': {
				borderRight: `1px solid ${COLORS.LT_MIDNIGHT5}`,
			},
		},
	},
	tableCellLabel: {
		fontSize: '8px',
		fontWeight: '600',
		color: COLORS.LT_MIDNIGHT33,
		textTransform: 'uppercase',
		lineHeight: '12px',
	},
	statusTableCell: {
		padding: '16px',
		flexGrow: '1',
	},
	menuButtonTableCell: {
		paddingRight: '8px',
	},
	header: {
		fontWeight: '600',
		color: COLORS.LT_MIDNIGHT,
	},
	PaperRoot: {
		display: 'flex',
		padding: '24px 16px 24px 32px',
		flexDirection: 'column',
		[theme.breakpoints.down('sm')]: {
			padding: '24px',
		},
	},
	menuButton: {
		background: COLORS.LT_MIDNIGHTBG1,
		padding: '6px',
		borderRadius: '8px',
		color: COLORS.LT_MIDNIGHT33,
	},
	menuButtonDidNotWork: {
		background: COLORS.LT_MIDNIGHTBG1,
		padding: '6px',
		borderRadius: '8px',
		color: COLORS.LT_MIDNIGHT33,
		marginLeft: '15px',
	},
	infoContainerDidNotWork: {
		height: '100%',
	},
	infoContainer: {
		height: '100%',
		gap: '24px',
	},
	cardRoot: {
		[theme.breakpoints.down('sm')]: {
			gap: '16px',
		},
	},
	primaryAction: {
		[theme.breakpoints.down('sm')]: {
			marginLeft: 'auto',
		},
		[theme.breakpoints.down('xs')]: {
			flexGrow: '1',
		},
	},
	menuIcon: {
		color: COLORS.LT_MIDNIGHT25,
		display: 'flex',
		alignItems: 'center',
	},
	menuList: {
		minWidth: '250px',
		padding: '24px',
		display: 'flex',
		flexDirection: 'column',
		gap: '16px',
	},
	menuFormLabel: {
		fontWeight: '400',
		fontSize: '14px',
		lineHeight: '20px',
	},
	menuForm: {
		display: 'flex',
		flexDirection: 'column',
		gap: '4px',
	},
	locationTableCell: {
		maxWidth: '100%',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	dialogPaperRoot: {
		padding: '40px',
		gap: '24px',
	},
	dialogTitleRoot: {
		color: COLORS.LT_FOUNDATION,
		fontSize: '24px',
		fontWeight: '700',
		padding: '0',
	},
	dialogContentRoot: {
		padding: '0',
		'&:first-child': {
			paddingTop: '0',
		},
	},
	dialogGrid: {
		gap: '24px',
	},
	dialogActionsRoot: {
		padding: '0',
		justifyContent: 'center',
	},
	dialogErrorIcon: {
		color: COLORS.LT_PEPPER,
		background: COLORS.LT_PEPPER10,
		borderRadius: '50%',
		padding: '16px',
	},
	dialogWarningIcon: {
		color: COLORS.LT_SUNFLOWER,
		background: COLORS.LT_SUNFLOWER25,
		borderRadius: '50%',
		padding: '16px',
	},
	youHaveEnteredTimeError: {
		color: COLORS.LT_JOURNEY,
	},
	dateContainer: {
		width: 'auto',
	},
	didNotWorkLabel: {
		marginLeft: '20px',
		[theme.breakpoints.down('sm')]: {
			marginLeft: '0px',
		},
	},
	stayButton: {
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	bookingName: {
		fontStyle: 'italic',
		color: COLORS.LT_MIDNIGHT50,
	},
});

const TimesheetCard = (props) => {
	const {
		classes,
		t,
		timesheet,
		weekEndings,
		onDidNotWorkTimesheetStatusUpdate,
		status,
		UserContext,
		refreshTimesheets,
		id,
	} = props;
	const { externalId } = UserContext;
	const isSubmitted = timesheet.status === TimesheetStatusEnumApi.Submitted;
	const isPaid = timesheet.status === TimesheetStatusEnumApi.Paid;
	const isApproved = timesheet.status === TimesheetStatusEnumApi.Approved;
	const isNotStarted = timesheet.status === TimesheetStatusEnumApi.NotStarted;
	const isPeopleNet = timesheet.status === TimesheetStatusEnumApi.PeopleNetTimesheet;
	const isDidNotWork = timesheet.status === TimesheetStatusEnumApi.DidNotWork;
	const isPendingSubmission = timesheet.status === TimesheetStatusEnumApi.PendingSubmission;
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
		defaultMatches: true,
	});
	const [anchorEl, setAnchorEl] = useState(null);
	const [mainMenuOpen, setMainMenuOpen] = useState(false);
	const [duplicateToMenuOpen, setDuplicateToMenuOpen] = useState(false);
	const [didNotWorkDialogOpen, setDidNotWorkDialogOpen] = useState(false);
	const [peopleNetError, setPeopleNetError] = useState(null);
	const [movingToPeopleNet, setMovingToPeopleNet] = useState(false);
	const methods = useForm({ mode: 'onChange', defaultValues: {} });
	const { fields, append } = useFieldArray({
		control: methods.control,
		name: 'weekEndings',
	});
	const [submittedData, setSubmittedData] = useState({});
	const history = useHistory();
	const [recallErrorDialogOpen, setRecallErrorDialogOpen] = useState(false);
	const [recallWarningDialogOpen, setRecallWarningDialogOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [showNotInSFCalendarDialog, setShowNotInSFCalendarDialog] = useState(false);
	const timesheetStatus = timesheet?.status;
	const timesheetReviewsCount = timesheet?.timesheetReviews?.length;
	const inNotStartedOrPendingSubmissionStatus = () => {
		let status = true;
		for (let i = 0; i < timesheet?.timesheetReviews?.length; i++) {
			if (
				timesheet?.timesheetReviews[i]?.status !== TimesheetStatusEnumApi.NotStarted &&
				timesheet?.timesheetReviews[i]?.status !== TimesheetStatusEnumApi.PendingSubmission
			) {
				status = false;
			}
		}
		return status;
	};
	const allTimesheetReviewsInNotStartedOrPendingSubmissionStatus =
		inNotStartedOrPendingSubmissionStatus();
	let pathname;
	switch (timesheet?.status) {
		case 'NotStarted':
		case 'PendingSubmission':
		case 'Rejected':
		case 'DidNotWork':
			pathname = `timesheet/${timesheet.id}/edit`;
			break;
		case 'Approved':
		case 'Paid':
		case 'Submitted':
			pathname = `timesheet/${timesheet.id}/view`;
			break;
		default:
			break;
	}

	useEffect(() => {
		weekEndings
			.filter(
				(w) => w.weekEnding !== timesheet.weekEnding && w.bookingId === timesheet.bookingId
			)
			.forEach((weekEnding) => {
				append(weekEnding);
			});
	}, []);

	useEffect(() => {
		if (methods.formState.isSubmitSuccessful) {
			methods.reset({ weekEndings, weekEnding: '' });
		}
	}, [methods.formState.isSubmitSuccessful, submittedData, methods.reset]);

	const totalCount = (entries, property) => {
		const sum = entries.reduce((partialSum, a) => partialSum + a[property], 0);
		const roundedSum = parseFloat(sum.toFixed(2));
		return roundedSum > 0 ? roundedSum : '-';
	};

	const getLocationData = (location) => {
		const timesheetEntries = timesheet.timesheetEntries.filter(
			(entry) => entry.locationId === location.id
		);
		const mileageEntries = timesheet.mileageEntries.filter(
			(entry) => entry.locationId === location.id
		);
		const timesheetReviewIndex = timesheet.timesheetReviews.findIndex(
			(review) => review.locationId === location.id
		);
		const timesheetReview = timesheet.timesheetReviews[timesheetReviewIndex];
		return { timesheetEntries, mileageEntries, timesheetReview };
	};

	const locationTimesheets = useMemo(() =>
		timesheet?.booking?.locations?.map((location) => {
			const { timesheetEntries, mileageEntries, timesheetReview } = getLocationData(location);
			const totalHours = totalCount(timesheetEntries, 'totalTimeInHours');
			const totalUnits = totalCount(timesheetEntries, 'units');
			const totalMiles = totalCount(mileageEntries, 'miles');
			return {
				totalHours,
				totalUnits,
				totalMiles,
				timesheetReview,
				location,
			};
		})
	);

	const applyDuplicate = (data) => {
		console.log(data);
		setSubmittedData(data);
	};

	function isDateWithinRange(startDate1, endDate1, startDate2, endDate2) {
		const start1 = moment(startDate1).startOf('day');
		const end1 = moment(endDate1).endOf('day');
		const start2 = moment(startDate2);
		const end2 = moment(endDate2);

		if (!(start1.isValid() && end1.isValid() && start2.isValid() && end2.isValid())) {
			return false;
		}

		if (start1.isAfter(end1) || start2.isAfter(end2)) {
			return false;
		}

		return start1.isSameOrBefore(end2) && end1.isSameOrAfter(start2);
	}

	const bookedOnSalesforce = () => {
		const startDate = moment(timesheet?.weekEnding).subtract(6, 'days').format('YYYY-MM-DD');
		const endDate = moment(timesheet?.weekEnding).format('YYYY-MM-DD');

		for (let i = 0; i < timesheet?.booking?.shifts?.length; i++) {
			if (isDateWithinRange(startDate, endDate, timesheet?.booking?.shifts[i].start, timesheet?.booking?.shifts[i].end)) {
				return true;
			}
		}

		return false;
	};

	const handleEnterTimeClick = (url) => {
		if (bookedOnSalesforce()) {
			history.push(url);
		} else {
			setShowNotInSFCalendarDialog(true);
		}
	};

	const getStatusColor = (status) => {
		switch (status) {
			case TimesheetStatusEnum.Submitted:
				return classes.chipSubmitted;
			case TimesheetStatusEnum.Approved:
				return classes.chipApproved;
			case TimesheetStatusEnum.Rejected:
				return classes.chipRejected;
			case TimesheetStatusEnum.Paid:
				return classes.chipPaid;
			default:
				return 'default';
		}
	};

	const formatWeekEnding = (date) => {
		let formattedWeekEnding = new Date(date);
		formattedWeekEnding.setDate(formattedWeekEnding.getDate() + 1);
		formattedWeekEnding = new Date(formattedWeekEnding).toLocaleDateString('en-us', {
			year: 'numeric',
			month: 'short',
			day: 'numeric',
		});
		return formattedWeekEnding;
	};

	const handleMenuClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const handleRecall = async () => {
		setRecallWarningDialogOpen(false);
		setIsLoading(true);
		const path = `timesheet/${timesheet.id}/edit?weekEnding=${timesheet.weekEnding}`;
		let newTimesheet = cloneDeep(timesheet);
		newTimesheet.timesheetEntries = newTimesheet.timesheetEntries.filter(
			(entry) => entry.locationId && entry.rateTypeId
		);
		newTimesheet.mileageEntries = newTimesheet.mileageEntries.filter(
			(entry) => entry.locationId
		);
		try {
			const currentTimesheet = await getTimesheetById(externalId, timesheet.id);
			const currentTimsheetHasEntryInRecallableStatus =
				currentTimesheet?.timesheetReviews?.some(
					(review) => review.status === TimesheetStatusEnumApi.Submitted
				);
			if (!currentTimsheetHasEntryInRecallableStatus) {
				setRecallErrorDialogOpen(true);
				setIsLoading(false);
			} else {
				newTimesheet.status = TimesheetStatusEnumApi.PendingSubmission;
				await saveTimesheet(newTimesheet, timesheet.id, true);
				setIsLoading(false);
				history.push(path);
			}
		} catch (error) {
			setIsLoading(false);
			console.error(error);
		}
	};

	const isHiddenTimesheetReview = (timesheetReview) => {
		return (
			!timesheetReview ||
			((isSubmitted || isApproved || isPaid) &&
				(timesheetReview.status === TimesheetStatusEnumApi.NotStarted ||
					timesheetReview.status === TimesheetStatusEnumApi.PendingSubmission)) ||
			timesheetReview.status === TimesheetStatusEnumApi.TimeNotEntered
		);
	};

	const renderDueIn = () => {
		if (isSubmitted || isPaid || isApproved || isDidNotWork || isPeopleNet) {
			return null;
		}
		const today = moment();
		const dueIn = moment(timesheet.dueDate).diff(today, 'hours');
		const dueInDays = moment(timesheet.dueDate).diff(today, 'days');
		const bookedOnSf = bookedOnSalesforce();
		let label;
		if (!bookedOnSf && timesheet?.status === TimesheetStatusEnumApi.NotStarted) {
			return <Chip label='Time not expected' />;
		} else {
			label =
				dueIn < 0
					? 'PAST DUE'
					: dueIn < 24
					? 'DUE TODAY'
					: dueInDays === 1
					? `DUE IN ${dueInDays} DAY`
					: dueInDays > 0
					? `DUE IN ${dueInDays} DAYS`
					: null;
		}
		if (dueInDays <= 7 && dueIn >= 0) {
			return <Chip label={label} classes={{ root: classes.chipDueIn }} />;
		} else if (dueIn < 0) {
			return <Chip label={label} classes={{ root: classes.chipPastDue }} />;
		} else {
			return null;
		}
	};

	const renderPeopleNetTimesheet = () => {
		const { timesheet } = props;
		return (
			<Grid
				key={timesheet.id}
				container
				className={classes.tableRow}
				alignItems='center'
				id={`${timesheet.id}--peoplenet`}
			>
				<Grid item xs={12} sm={9} className={classes.tableCell}>
					<Typography
						variant='body2'
						className={classes.locationTableCell}
						title={'location'}
						id={`${timesheet.id}--peoplenet--location`}
					>
						{timesheet.primaryWorkLocationName}
					</Typography>
				</Grid>
				<Grid item className={classes.statusTableCell} xs={12} sm={3}>
					<Chip
						title={TimesheetStatusEnum.PeopleNetTimesheet}
						id={`${timesheet.id}--peoplenet--status`}
						label={TimesheetStatusEnum.PeopleNetTimesheet}
						className={getStatusColor(TimesheetStatusEnumApi.PeopleNetTimesheet)}
						classes={{ root: classes.chipRoot }}
					/>
				</Grid>
			</Grid>
		);
	};

	const renderDesktopTable = (
		{ totalHours, totalUnits, totalMiles, timesheetReview, location },
		timesheetStatus,
		reviewCount,
		index
	) => {
		if (isHiddenTimesheetReview(timesheetReview)) return;
		return (
			<Grid
				key={location.id}
				container
				className={classes.tableRow}
				alignItems='center'
				id={`${id}--location--${index}`}
			>
				<Grid item xs={3} className={classes.tableCell}>
					<Typography
						variant='body2'
						className={classes.locationTableCell}
						title={location.displayName}
						id={`${id}--location--${index}--work-location`}
					>
						{location.displayName}
					</Typography>
				</Grid>
				<Grid item xs={2} className={classes.tableCell}>
					<Typography variant='body2' id={`${id}--location--${index}--hours`}>
						{totalHours}
					</Typography>
				</Grid>
				<Grid item xs={2} className={classes.tableCell}>
					<Typography variant='body2' id={`${id}--location--${index}--units`}>
						{totalUnits}
					</Typography>
				</Grid>
				<Grid item xs={2} className={classes.tableCell}>
					<Typography variant='body2' id={`${id}--location--${index}--mileage`}>
						{totalMiles}
					</Typography>
				</Grid>
				<Grid item className={classes.statusTableCell} xs={3}>
					<Chip
						title={timesheetReview?.status}
						id={`${id}--location--${index}--status`}
						label={
							timesheetReview
								? TimesheetStatusEnum[timesheetReview.status]
								: timesheetStatus === TimesheetStatusEnumApi.PendingSubmission &&
								  reviewCount === 0
								? TimesheetStatusEnum.PendingSubmission
								: TimesheetStatusEnum.NotStarted
						}
						className={getStatusColor(
							timesheetReview
								? timesheetReview.status
								: TimesheetStatusEnum.NotStarted
						)}
						classes={{ root: classes.chipRoot }}
					/>
				</Grid>
			</Grid>
		);
	};

	const renderMobileTable = (
		{ totalHours, totalUnits, totalMiles, timesheetReview, location },
		timesheetStatus,
		reviewCount,
		index
	) => {
		if (isHiddenTimesheetReview(timesheetReview)) return;
		return (
			<Grid
				key={location.id}
				container
				className={classes.tableRow}
				id={`${id}--location--${index}`}
			>
				<Grid item xs={12}>
					<Grid
						container
						justifyContent='space-between'
						alignItems='center'
						wrap='nowrap'
					>
						<Grid item xs={6} sm={7}>
							<Typography
								className={classes.locationTableCell}
								title={location.displayName}
								variant='body2'
								id={`${id}--location--${index}--work-location`}
							>
								{location.displayName}
							</Typography>
						</Grid>
						<Grid item xs={6} sm={5}>
							<Chip
								title={timesheetReview?.status}
								id={`${id}--location--${index}--status`}
								label={
									timesheetReview
										? TimesheetStatusEnum[timesheetReview.status]
										: timesheetStatus ===
												TimesheetStatusEnumApi.PendingSubmission &&
										  reviewCount === 0
										? TimesheetStatusEnum.PendingSubmission
										: TimesheetStatusEnum.NotStarted
								}
								className={getStatusColor(
									timesheetReview
										? timesheetReview.status
										: TimesheetStatusEnum.NotStarted
								)}
								classes={{ root: classes.chipRoot }}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} className={classes.tableContainer}>
					<Grid container>
						<Grid item xs={4} className={classes.tableCell}>
							<span className={classes.tableCellLabel}>Hours</span>
							<Typography variant='body2' id={`${id}--location--${index}--hours`}>
								{totalHours}
							</Typography>
						</Grid>
						<Grid item xs={4} className={classes.tableCell}>
							<span className={classes.tableCellLabel}>Units</span>
							<Typography variant='body2' id={`${id}--location--${index}--units`}>
								{totalUnits}
							</Typography>
						</Grid>
						<Grid item xs={4} className={classes.tableCell}>
							<span className={classes.tableCellLabel}>Miles</span>
							<Typography variant='body2' id={`${id}--location--${index}--mileage`}>
								{totalMiles}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		);
	};

	const onSubmit = () => {
		window.Appcues.track('Begin Timesheet Completion Flow (Begin)', {
			key: 'Click the Begin button',
			description: 'A user is starting the process to complete their first time sheet',
		});
		handleEnterTimeClick(pathname + `?weekEnding=${timesheet.weekEnding}`);
	};

	const showPeopleNetTimesheet = async () => {
		setPeopleNetError(null);
		setMovingToPeopleNet(true);

		await getSamlResponse().catch((e) => {
			setPeopleNetError('Error connecting to PeopleNet');
		});
		setMovingToPeopleNet(false);
	};

	const renderButtons = () => {
		if (isDidNotWork) {
			return <></>;
		}
		const recallOptionAvailable = timesheet?.timesheetReviews?.some(
			(review) => review.status === TimesheetStatusEnumApi.Submitted
		);
		return (
			<Grid container spacing={1} justifyContent='flex-start' alignItems='center'>
				{isPeopleNet ? (
					<Grid item className={classes.primaryAction}>
						<Button
							id={`${id}--button--begin`}
							variant='contained'
							color='primary'
							onClick={async () => showPeopleNetTimesheet()}
							fullWidth
						>
							{t('submitTimesheet:BUTTONS.VIEW')}
						</Button>
						{!!peopleNetError ? (
							<>
								<br />
								{peopleNetError}
							</>
						) : null}
						{!!movingToPeopleNet ? (
							<CircularProgress
								classes={{ root: classes.progressRoot }}
								color='primary'
							/>
						) : null}
					</Grid>
				) : isNotStarted ? (
					<Grid item className={classes.primaryAction}>
						<Button
							id={`${id}--button--begin`}
							variant='contained'
							color='primary'
							onClick={onSubmit}
							fullWidth
						>
							{t('submitTimesheet:BUTTONS.BEGIN')}
						</Button>
					</Grid>
				) : (
					<Grid item className={classes.primaryAction}>
						<Link
							to={{
								pathname,
								search: `?weekEnding=${timesheet.weekEnding}`,
							}}
						>
							<Button
								variant='contained'
								color={isPendingSubmission ? 'primary' : 'default'}
								fullWidth
								id={`${id}--button--view`}
							>
								{t('submitTimesheet:BUTTONS.VIEW')}
							</Button>
						</Link>
					</Grid>
				)}
				{!isApproved && !isPaid && !isPeopleNet && (
					<Grid item>
						<IconButton
							className={classes.menuButton}
							color='default'
							aria-label='More options'
							id={`${id}--button--menu`}
							onClick={(e) => {
								handleMenuClick(e);
								setMainMenuOpen(true);
							}}
						>
							<MoreVert />
						</IconButton>
						<Menu
							anchorEl={anchorEl}
							keepMounted
							open={mainMenuOpen}
							onClose={() => {
								handleMenuClose();
								setMainMenuOpen(false);
							}}
						>
							{/* {!isNotStarted && (
							<MenuItem
								onClick={(e) => {
									handleMenuClick(e);
									setMainMenuOpen(false);
									setDuplicateToMenuOpen(true);
								}}
								id={`${id}--button--menu--list-item--duplicate`}
							>
								<Grid container alignItems='center' spacing={2}>
									<Grid item className={classes.menuIcon}>
										<FileCopyOutlined fontSize='small' />
									</Grid>
									<Grid item>
										<Typography variant='body2'>Duplicate to...</Typography>
									</Grid>
								</Grid>
							</MenuItem>
						)} */}
							{recallOptionAvailable && (
								<MenuItem
									onClick={() => {
										setRecallWarningDialogOpen(true);
										setMainMenuOpen(false);
									}}
									id={`${id}--button--menu--list-item--recall`}
								>
									<Grid container alignItems='center' spacing={2}>
										<Grid item className={classes.menuIcon}>
											<FlipToFront fontSize='small' />
										</Grid>
										<Grid item>
											<Typography variant='body2'>Recall</Typography>
										</Grid>
									</Grid>
								</MenuItem>
							)}
							{status === 'Outstanding' &&
								allTimesheetReviewsInNotStartedOrPendingSubmissionStatus && (
									<MenuItem
										onClick={() => {
											setDidNotWorkDialogOpen(true);
											setMainMenuOpen(false);
										}}
										id={`${id}--button--menu--list-item--dnw`}
									>
										<Grid container alignItems='center' spacing={2}>
											<Grid item className={classes.menuIcon}>
												<HighlightOffOutlined fontSize='small' />
											</Grid>
											<Grid item>
												<Typography variant='body2'>
													Did not work
												</Typography>
											</Grid>
										</Grid>
									</MenuItem>
								)}
							{(status !== 'Outstanding' ||
								!allTimesheetReviewsInNotStartedOrPendingSubmissionStatus) &&
								!recallOptionAvailable && (
									<MenuItem disabled id={`${id}--button--menu--list-item--none`}>
										<Typography variant='body2'>
											No options available
										</Typography>
									</MenuItem>
								)}
						</Menu>
						{renderDidNotWorkDialog()}
						<Menu
							anchorEl={anchorEl}
							keepMounted
							open={duplicateToMenuOpen}
							onClose={() => {
								handleMenuClose();
								setDuplicateToMenuOpen(false);
							}}
							classes={{ list: classes.menuList }}
						>
							<form className={classes.menuForm}>
								<Controller
									control={methods.control}
									name='weekEnding'
									defaultValue=''
									rules={{ required: true }}
									render={({ onChange, value }) => (
										<RadioGroup onChange={onChange} value={value}>
											{fields.map((field, index) => (
												<FormControlLabel
													key={field.id}
													value={field.weekEnding}
													control={
														<Radio
															color='primary'
															name={`weekEndings[${index}].weekEnding`}
														/>
													}
													classes={{ label: classes.menuFormLabel }}
													label={`Week ending ${formatWeekEnding(
														field.weekEnding
													)}`}
												/>
											))}
										</RadioGroup>
									)}
								/>
							</form>
							<Grid container>
								<Grid item xs={12}>
									<Button
										variant='contained'
										color='primary'
										disabled={!methods.formState.isValid}
										onClick={() => {
											methods.handleSubmit(applyDuplicate)();
											setDuplicateToMenuOpen(false);
											handleMenuClose();
										}}
										fullWidth
									>
										Apply
									</Button>
								</Grid>
							</Grid>
						</Menu>
					</Grid>
				)}
			</Grid>
		);
	};

	const renderNotInSFDialog = (pathname) => {
		return (
			<Dialog
				open={showNotInSFCalendarDialog}
				onClose={() => setShowNotInSFCalendarDialog(false)}
				aria-labelledby='not-in-sf-calendar-dialog-title'
				aria-describedby='not-in-sf-calendar-dialog-description'
				fullWidth
				maxWidth='xs'
				classes={{ paper: classes.dialogPaperRoot }}
			>
				<DialogContent classes={{ root: classes.dialogContentRoot }}>
					<Grid container direction='column' className={classes.dialogGrid}>
						<Grid item>
							<Grid container alignContent='center' justifyContent='center'>
								<PriorityHigh
									fontSize='large'
									className={classes.dialogErrorIcon}
								></PriorityHigh>
							</Grid>
						</Grid>
						<Grid item>
							<Typography
								variant='h5'
								id='not-in-sf-calendar-dialog-description'
								align='center'
							>
								This week is outside the expected timeframe and can be subject to
								processing delays.
							</Typography>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions classes={{ root: classes.dialogActionsRoot }}>
					<Button variant='contained' onClick={() => setShowNotInSFCalendarDialog(false)}>
						Cancel
					</Button>
					<Button
						component={Link}
						to={{
							pathname,
							search: `?weekEnding=${timesheet.weekEnding}`,
						}}
						variant='contained'
						color='primary'
						classes={{ root: classes.stayButton }}
					>
						Proceed
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	const renderDidNotWorkDialog = () => {
		return (
			<Dialog
				open={didNotWorkDialogOpen}
				onClose={() => setDidNotWorkDialogOpen(false)}
				aria-labelledby='did-not-work-dialog-title'
				aria-describedby='did-not-work-dialog-description'
				classes={{ paper: classes.dialogPaperRoot }}
				fullWidth
				maxWidth='xs'
			>
				<DialogContent classes={{ root: classes.dialogContentRoot }}>
					<Grid
						container
						direction='column'
						justifyContent='center'
						alignItems='center'
						className={classes.dialogGrid}
					>
						<Grid item>
							<Grid container alignContent='center' justifyContent='center'>
								<PriorityHigh
									fontSize='large'
									className={classes.dialogWarningIcon}
								></PriorityHigh>
							</Grid>
						</Grid>
						<Grid item>
							{timesheet?.timesheetEntries?.length > 0 && (
								<Typography
									variant='h5'
									align='center'
									classes={{ root: classes.youHaveEnteredTimeError }}
								>
									You have entered time!
								</Typography>
							)}
							<Typography
								variant='h5'
								align='center'
								classes={{ root: classes.textCenter }}
							>
								{`Are you sure you would like to mark ${moment(
									timesheet?.weekEnding
								).format('MMMM Do')} as Not Worked?`}
							</Typography>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions classes={{ root: classes.dialogActionsRoot }}>
					<Button variant='contained' onClick={() => setDidNotWorkDialogOpen(false)}>
						Cancel
					</Button>
					<Button
						variant='contained'
						color='primary'
						onClick={() => {
							timesheet.status = TimesheetStatusEnumApi.DidNotWork;
							onDidNotWorkTimesheetStatusUpdate(timesheet, timesheet?.id);
							setDidNotWorkDialogOpen(false);
						}}
					>
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	const renderTimesheetCard = () => {
		return (
			<Grid
				container
				classes={{ root: classes.cardRoot }}
				alignItems={isDidNotWork ? 'center' : 'stretch'}
			>
				<Grid item xs={12} md={3}>
					<Grid
						container
						justifyContent='space-between'
						direction='column'
						className={
							isDidNotWork ? classes.infoContainerDidNotWork : classes.infoContainer
						}
					>
						<Grid item>
							<Grid
								container
								justifyContent={isMobile ? 'space-between' : null}
								direction={isMobile ? 'row' : 'column'}
								alignItems={isMobile ? 'center' : 'flex-start'}
								spacing={1}
							>
								<Grid item>
									<Grid
										container
										direction='row'
										alignItems={isMobile ? 'flex-start' : 'center'}
									>
										<Grid
											container
											direction={isMobile ? 'row' : 'column'}
											spacing={isMobile ? 1 : 0}
											classes={{ root: classes.dateContainer }}
										>
											<Grid item>
												<Typography className={classes.header}>
													{t('submitTimesheet:HEADERS.WEEK_ENDING')}
												</Typography>
											</Grid>
											<Grid item>
												<Typography className={classes.header}>
													{formatWeekEnding(timesheet.weekEnding)}
												</Typography>
											</Grid>
											<Grid item>
												<Typography variant='body2' className={classes.bookingName}>
													{timesheet.booking.name}
												</Typography>
											</Grid>
										</Grid>
										{timesheet?.status ===
											TimesheetStatusEnumApi.DidNotWork && (
											<Chip
												label='Did not work'
												classes={{ root: classes.didNotWorkLabel }}
											/>
										)}
									</Grid>
								</Grid>
								<Grid item>{renderDueIn()}</Grid>
							</Grid>
						</Grid>
						<Hidden only={['xs', 'sm']}>
							<Grid item>{renderButtons()}</Grid>
						</Hidden>
					</Grid>
				</Grid>
				{timesheetStatus !== TimesheetStatusEnumApi.DidNotWork ? (
					<Grid item xs={12} md={9} className={classes.tableContainer}>
						{isPeopleNet
							? renderPeopleNetTimesheet()
							: locationTimesheets.map((locationTimesheet, index) =>
									isMobile
										? renderMobileTable(
												locationTimesheet,
												timesheetStatus,
												timesheetReviewsCount,
												index
										  )
										: renderDesktopTable(
												locationTimesheet,
												timesheetStatus,
												timesheetReviewsCount,
												index
										  )
							  )}
					</Grid>
				) : (
					<Grid item className={classes.infoContainerDidNotWork} xs={12} md={9}>
						<Grid
							container
							justifyContent={isMobile ? 'flex-start' : 'flex-end'}
							display='flex'
							alignItems='center'
						>
							<Button
								variant='contained'
								color='primary'
								onClick={() =>
									handleEnterTimeClick(
										pathname + `?weekEnding=${timesheet.weekEnding}`
									)
								}
								classes={{ root: classes.stayButton }}
							>
								Enter Time
							</Button>
						</Grid>
					</Grid>
				)}
				<Hidden only={['md', 'lg', 'xl']}>
					<Grid item xs={12}>
						{renderButtons()}
					</Grid>
				</Hidden>
			</Grid>
		);
	};

	const renderRecallWarningDialog = () => {
		return (
			<Dialog
				open={recallWarningDialogOpen}
				onClose={() => {
					setRecallWarningDialogOpen(false);
				}}
				aria-labelledby='recall-warning-dialog-title'
				aria-describedby='recall-warning-dialog-description'
				fullWidth
				maxWidth='xs'
				classes={{ paper: classes.dialogPaperRoot }}
			>
				<DialogContent classes={{ root: classes.dialogContentRoot }}>
					<Grid
						container
						direction='column'
						justifyContent='center'
						alignItems='center'
						className={classes.dialogGrid}
					>
						<Grid item>
							<Grid container alignContent='center' justifyContent='center'>
								<PriorityHigh
									fontSize='large'
									className={classes.dialogWarningIcon}
								></PriorityHigh>
							</Grid>
						</Grid>
						<Grid item>
							<Typography variant='h5' align='center'>
								Recalling a timesheet stops the approval process and will require
								the recalled timesheets to be re-submitted.
							</Typography>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions classes={{ root: classes.dialogActionsRoot }}>
					<Button variant='contained' onClick={() => setRecallWarningDialogOpen(false)}>
						Cancel
					</Button>
					<Button variant='contained' color='primary' onClick={handleRecall}>
						Recall
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	const renderRecallErrorDialog = () => {
		const props = {
			name: 'recall-error',
			message: `Your timesheet has been recently updated and is not available
								to be recalled. Please reach out to your recruiter with any
								questions.`,
			onClose: () => {
				setRecallErrorDialogOpen(false);
				refreshTimesheets();
			},
			open: recallErrorDialogOpen,
		};
		return <StatusErrorDialog {...props} />;
	};

	return (
		<>
			<Paper className={classes.PaperRoot} id={id}>
				{isLoading ? (
					<CircularProgress classes={{ root: classes.progressRoot }} color='primary' />
				) : (
					renderTimesheetCard()
				)}
			</Paper>
			{renderRecallErrorDialog()}
			{renderRecallWarningDialog()}
			{renderNotInSFDialog(pathname)}
		</>
	);
};

export default WithContexts(withRouter(withTranslation()(withStyles(styles)(TimesheetCard))));
