import { useTheme } from '@material-ui/styles';
import { COLORS } from '../../../utils/Application_Constants';

function TimesheetBreakStylesV1() {
	const theme = useTheme();

	return {
		breakRow: {
			[theme.breakpoints.up('md')]: {
				flexWrap: 'nowrap',
			},
			zIndex: 0,
			position: 'relative',
		},
		firstBreakCell: {
			'&:nth-child(4)': {
				'& .MuiInputBase-input': {
					borderTopLeftRadius: '8px',
					[theme.breakpoints.up('md')]: {
						borderBottomLeftRadius: '8px',
					},
					[theme.breakpoints.down('md')]: {
						borderRight: 'none',
					},
				},
			}
		},
		menuButtonCell: {
			border: `1px solid ${COLORS.LT_MIDNIGHT5}`,
			padding: '2px',
			borderTopRightRadius: '8px',
			borderBottomRightRadius: '8px',
			overflow: 'hidden',
			background: COLORS.WHITE,
		},
		disabledBackgroundColor: {
			background: COLORS.LT_MIDNIGHTBG2,
		},
		formControl: {
			marginBottom: '0',
			'& .MuiOutlinedInput-notchedOutline': {
				borderColor: 'transparent',
				borderRadius: '8px',
			},
		},
		menuButton: {
			color: COLORS.LT_MIDNIGHT25,
			backgroundColor: COLORS.WHITE,
		},
		menuIcon: {
			color: COLORS.LT_MIDNIGHT25,
			display: 'flex',
			alignItems: 'center',
		},
		menuIconRemove: {
			color: COLORS.LT_PEPPER,
		},
		inputRoot: {
			'&$disabled $notchedOutline': {
				borderColor: 'transparent',
			},

			'&$disabled $input': {
				background: COLORS.LT_MIDNIGHTBG2,
				color: COLORS.LT_MIDNIGHT33,
			},
		},
		disabled: {},
		notchedOutline: {},
		input: {
			padding: '16px !important',
			height: 'auto',
			fontSize: '14px',
			lineHeight: '20px',
			border: `1px solid ${COLORS.LT_MIDNIGHT5}`,
			[theme.breakpoints.up('md')]: {
				borderRight: 'none',
				'&::placeholder': {
					opacity: 0,
				},
			},
			[theme.breakpoints.down('sm')]: {
				borderBottom: 'none',
			},
			'&$disabled': {
				background: COLORS.LT_MIDNIGHTBG2,
			},
			'&[type="time"]': {
				height: '20px',
			},
		},
		mobileMenuButton: {
			marginBottom: '4px',
			marginLeft: 'auto',
			display: 'flex',
			justifyContent: 'flex-end',

			'& .MuiIconButton-root': {
				padding: '6px',
			},
		},
		midBreakConnector: {
			position: 'absolute',
			height: '35px',
			width: '600px',
			borderRight: '1px solid #EEF0F1',
			right: '28px',
			borderBottom: '1px solid #EEF0F1',
			bottom: '52%',
			zIndex: -1,
		},
		endBreakConnector: {
			position: 'absolute',
			height: '6em',
			borderRight: '1px solid #EEF0F1',
			right: '28px',
			borderBottom: '1px solid #EEF0F1',
			bottom: '0%',
			zIndex: -1
		},
		midBreakConnectorContainer: {
			height: '3.5em',
			position: 'relative',
		},
		connectorGrid: {
			flexGrow: '1',
			maxWidth: '100%',
			flexBasis: '25%',
			zIndex: -1,
			backgroundColor: COLORS.WHITE,
		},
		endBreakConnectorContainer: {
			height: '4em',
			position: 'absolute',
			right: '0%',
		},
		menuGrid: {
			backgroundColor: COLORS.WHITE,
			zIndex: 1,
		},
		breakMobileHeader: {
			display: 'none',
		},
		breakDeleteButton: {
			display: 'none',
		},
		inputLabel: {
			display: 'none',
		},
	};
}

export default TimesheetBreakStylesV1;
