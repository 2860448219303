import React, { useEffect, useMemo, useState } from 'react';
import { Grid, MenuItem, Typography, withStyles, Switch } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { COLORS } from '../../../utils/Application_Constants';
import ExpenseTextField from './ExpenseTextField';
import ExpenseTransactionDate from './ExpenseTransactionDate';
import ExpenseDateRange from './ExpenseDateRange';
import ExpenseCategoryType from './ExpenseCategoryType';
import ExpenseBillType from './ExpenseBillType';
import ExpenseAmount from './ExpenseAmount';
import ExpenseComments from './ExpenseComments';
import ExpensePayDate from './ExpensePayDate';
import { TypeEnum } from './Division.enum';
import { ExpenseClassEnum } from './ExpenseClass.enum';
import { ExpenseStatusValuesEnum } from './ExpenseStatus.enum';

const styles = (theme) => ({
    formHeader: {
        borderBottom: `1px solid ${COLORS.LT_MIDNIGHT25}`,
        paddingBottom: 8,
        marginBottom: 16,
    },
});

const PreCodedExpenseDetailsForm = (props) => {
    const { classes, index, expense, mode, setIsSGA, divisions, isSGA,allCreditCards,expenseCategoriesSGA,expenseCategoriesNonSGA } = props;
    const [required, setRequired] = useState({ required: (mode === 'cc' ? false : true) });
    const { control, watch, errors, setValue, getValues, clearErrors } = useFormContext();
    const [expenseCategories, setExpenseCategories] = useState(null);
    const expenseCategory = watch(`expenseEntries[${index}].category`);
    const expenseType = watch(`expenseEntries[${index}].type`);
    const billType = watch(`expenseEntries[${index}].billType`);
    const hasPayDate = billType && billType === 'ClinicianDeduction' && mode === 'cc';
    const isExpenseTravel = expenseCategory && expenseCategory === 'Travel';
    const hasTransactionDate = expenseCategory && expenseCategory !== '';
    const [isDivisionDisabled, setIsDivisionDisabled] = useState(false);
    const hasExpenseDescription =
        expenseCategory &&
        expenseCategory === 'Travel' &&
        watch(`expenseEntries[${index}].type`) &&
        watch(`expenseEntries[${index}].type`) === 'Misc';
    const hasComplianceFeeDescription =
        expenseCategory &&
        expenseCategory === 'Other' &&
        watch(`expenseEntries[${index}].type`) &&
        watch(`expenseEntries[${index}].type`) === 'Compliance Fees';
    const isClinicianReimbursement = false;
    const importedData = watch(`expenseEntries[0].importedData`);
    const parentExpenseErrors = watch(`expenseEntries[0].errors`);
    const isPosted = expense.status === 'Posted';
    // const expenseAmount = watch(`expenseEntries[${index}].amount`);
    // const serviceFee = watch(`expenseEntries[${index}].serviceFee`);

    // const totalExpense = useMemo(() => {
    // 	let total = 0;
    // 	if (expenseAmount && serviceFee) {
    // 		total = +expenseAmount + +serviceFee;
    // 	} else if (expenseAmount && !serviceFee) {
    // 		total = +expenseAmount;
    // 	} else if (!expenseAmount && serviceFee) {
    // 		total = +serviceFee;
    // 	}
    // 	return parseFloat(total).toFixed(2);
    // }, [expenseAmount, serviceFee]);

    useEffect(() => {
        setRequired({ required: (mode !== 'cc' || expense?.status === ExpenseStatusValuesEnum.Approved) });
    }, [expense?.status]);


    const expenseTypes = useMemo(() => {
        if (!expenseCategory) return [];
        if (isSGA[index]){
            if (!expenseCategoriesSGA) return [];
            let { types } = expenseCategoriesSGA.find((option) => option.category === expenseCategory);
            types = types.filter((type) => type.name !== 'Mileage');
            return types ? types : [];
        }else{
            if (!expenseCategoriesNonSGA) return [];
            let { types } = expenseCategoriesNonSGA.find((option) => option.category === expenseCategory);
            types = types.filter((type) => type.name !== 'Mileage');
            return types ? types : [];
        }
    }, [expenseCategory, expenseCategoriesSGA, expenseCategoriesNonSGA]);

    const toggleIsSGA = () => {
        //Backup Form Settings
        setValue(`expenseEntries[${index}].sgaType`, getValues(`expenseEntries[${index}].type`));
        setValue(`expenseEntries[${index}].sgaDivision`, getValues(`expenseEntries[${index}].division`));
        setValue(`expenseEntries[${index}].sgaCategory`, getValues(`expenseEntries[${index}].category`));
        setValue(`expenseEntries[${index}].sgaBillType`, getValues(`expenseEntries[${index}].billType`));
        setValue(`expenseEntries[${index}].sgaExpenseClass`, getValues(`expenseEntries[${index}].expenseClass`));
        //Blank Form Values
        setValue(`expenseEntries[${index}].type`, '');
        setValue(`expenseEntries[${index}].division`, '');
        setValue(`expenseEntries[${index}].category`, '');
        setValue(`expenseEntries[${index}].billType`, '');
        setValue(`expenseEntries[${index}].expenseClass`, '');
        isSGA[index] = !isSGA[index];
        setIsSGA(isSGA);
        clearErrors(`expenseEntries[${index}]`);
      };

    const checkIsDisabledDivision = (aExpense) => {
        const disabledValues = [TypeEnum.CustomerCareGiftsFlowers,
            TypeEnum.MalpracticeCompFundStateCompfunds,
            TypeEnum.PSYCMalpracticeCompFund,
            TypeEnum.UnidentifiedLicensing,
            TypeEnum.LodgingSecurityDeposit,
            TypeEnum.AssociateTravelPrepaid,
            TypeEnum.AssociateTravelAccrued,
            TypeEnum.Disputed,
            TypeEnum.IncidentalsRounding,
            TypeEnum.Rebate];
        if (disabledValues.includes(aExpense.type)){
            setIsDivisionDisabled(true);
        }else{
            setIsDivisionDisabled(false);
        }
      };

      useEffect(async () => {
        if (isSGA[index]){
            setExpenseCategories(expenseCategoriesSGA);
        }else{
            setExpenseCategories(expenseCategoriesNonSGA);
        }
        checkIsDisabledDivision(expense);
    }, [expenseCategory, expenseCategoriesSGA, expenseCategoriesNonSGA]);

    useEffect(() => {
        if (mode === "cc"){
            console.log(isSGA[index], `- PreCodedExpenseDetailsForm isSGA ${index} Has changed`)
            if (isSGA[index]){
                setExpenseCategories(expenseCategoriesSGA);
                setValue(`expenseEntries[${index}].type`, getValues(`expenseEntries[${index}].sgaType`));
                setValue(`expenseEntries[${index}].division`, getValues(`expenseEntries[${index}].sgaDivision`));
                setValue(`expenseEntries[${index}].category`, getValues(`expenseEntries[${index}].sgaCategory`));
                setValue(`expenseEntries[${index}].billType`, getValues(`expenseEntries[${index}].sgaBillType`));
                setValue(`expenseEntries[${index}].expenseClass`,ExpenseClassEnum.SalesGeneralAndAdmin);
            }else{
                setExpenseCategories(expenseCategoriesNonSGA);
                setValue(`expenseEntries[${index}].type`, getValues(`expenseEntries[${index}].bookingType`));
                setValue(`expenseEntries[${index}].division`, getValues(`expenseEntries[${index}].bookingDivision`));
                setValue(`expenseEntries[${index}].category`, getValues(`expenseEntries[${index}].bookingCategory`));
                setValue(`expenseEntries[${index}].billType`, getValues(`expenseEntries[${index}].bookingBillType`));
                setValue(`expenseEntries[${index}].expenseClass`, ExpenseClassEnum.IngestedCCTransaction);
            }
        }
    },[isSGA[index]])



    return (
        <>
            <Typography variant='h5' component='h5' className={classes.formHeader}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        Expense Details
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {mode === 'cc' && isSGA[index] &&
                            <>
                                Booking
                                <Switch
                                    checked={isSGA[index]}
                                    onChange={toggleIsSGA}
                                    color="primary"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    disabled={isPosted}
                                    />
                                SG&A
                            </>
                        }
                    
                    </Grid>
                </Grid>
            </Typography>
            <Grid container spacing={3}>
                {mode === 'cc' && isSGA[index] &&
                    <>
                        <Grid item xs={12} md={6}>
                            <Controller
                                control={control}
                                name={`expenseEntries[${index}].division`}
                                defaultValue={!expense.division ? '' : expense.division}
                                rules={{ required: 'Division is required' }}
                                render={({ onChange, value }) => (
                                    <ExpenseTextField
                                        id={`division`}
                                        select
                                        onChange={(e) => {
                                            onChange(e);
                                            setValue(`expenseEntries[${index}].sgaDivision`, e.target.value);
                                        }}
                                        value={!value ? '' : value}
                                        label={'Division'}
                                        required
                                        disabled={isDivisionDisabled || isPosted}
                                        error={Boolean(errors.expenseEntries?.[index]?.division)}
                                        helperText={errors.expenseEntries?.[index]?.division?.message}
                                    >
                                        <MenuItem value='' disabled>
                                            Select a division
                                        </MenuItem>
                                        {divisions.map((option, index) => (
                                            <MenuItem
                                                key={option.name}
                                                value={option.name}
                                                id={`division-menu-option-${index}`}
                                            >
                                                {option.description}
                                            </MenuItem>
                                        ))}
                                    </ExpenseTextField>
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}></Grid>
                    </>
                }
                <Grid item xs={12} md={6}>
                    <Controller
                        control={control}
                        name={`expenseEntries[${index}].creditCardId`}
                        defaultValue={expense.creditCardId}
                        rules={{
                            required: required.required ? 'Credit card acct is required' : false,
                        }}
                        render={({ onChange, value }) => {
                            let hasFailedToMatchCC = true;
                            if (parentExpenseErrors && Array.isArray(parentExpenseErrors)) {
                                hasFailedToMatchCC = parentExpenseErrors.some(error =>
                                    error.error === "Failed to match Credit Card using Last Four Digits"
                                );
                            }

                            return (
                                <ExpenseTextField
                                    id={'credit-card-acct'}
                                    select
                                    onChange={onChange}
                                    value={value}
                                    label={'Credit card acct'}
                                    {...required}
                                    disabled={!hasFailedToMatchCC || isPosted}
                                    error={Boolean(errors.expenseEntries?.[index]?.creditCardId)}
                                    helperText={errors.expenseEntries?.[index]?.creditCardId?.message}
                                >
                                    <MenuItem value='' disabled>
                                        Select a credit card account
                                    </MenuItem>
                                    {allCreditCards.map((creditcard) => (
                                        <MenuItem key={creditcard.id} id={`credit-card-acct-menu-option-${creditcard.id}`} value={creditcard.id}>
                                            {creditcard.lastFourDigits} - {creditcard.name}
                                        </MenuItem>
                                    ))}
                                </ExpenseTextField>
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Controller
                        control={control}
                        name={`expenseEntries[${index}].merchantName`}
                        defaultValue={expense.merchantName}
                        rules={{
                            required: required.required ? 'Merchant name is required' : false,
                        }}
                        render={({ onChange, value }) => {
                            const merchantNameKeys = ['Supplier', 'Supplier Name', 'Merchant Name'];
                            const isMerchantNameInImportedData = importedData && merchantNameKeys.some(key => key in importedData);

                            return (
                                <ExpenseTextField
                                    id={'merchant-name'}
                                    onChange={onChange}
                                    inputProps={{ maxLength: 50 }}
                                    value={value}
                                    placeholder='Merchant name'
                                    label={'Merchant name'}
                                    {...required}
                                    disabled={isMerchantNameInImportedData || isPosted}
                                    error={Boolean(errors.expenseEntries?.[index]?.merchantName)}
                                    helperText={errors.expenseEntries?.[index]?.merchantName?.message}
                                ></ExpenseTextField>
                            );
                        }}
                    />
                </Grid>
                <ExpenseBillType index={index} expense={expense} isClinicianReimbursement={isClinicianReimbursement} mode={mode} isSGA={isSGA} isPosted={isPosted} />
                {hasPayDate && <ExpensePayDate index={index} expense={expense} mode={mode} isPosted={isPosted} />}
                <ExpenseCategoryType expenseTypes={expenseTypes} index={index} expense={expense} expenseCategories={expenseCategories} mode={mode} isSGA={isSGA} setIsDivisionDisabled={setIsDivisionDisabled} isPosted={isPosted} />
                {hasTransactionDate && <ExpenseTransactionDate index={index} expense={expense} mode={mode} isPosted={isPosted} />}
                <ExpenseAmount disabled index={index} expense={expense} mode={mode} isPosted={isPosted} />
                {(isExpenseTravel || (isSGA[index] && expenseType === 'LodgingSecurityDeposit')) && <ExpenseDateRange index={index} expense={expense} mode={mode} isPosted={isPosted} />}
                <ExpenseComments index={index} expense={expense} isPosted={isPosted} />
            </Grid>
        </>
    );
};

export default withStyles(styles)(PreCodedExpenseDetailsForm);
