/* eslint-disable react-hooks/exhaustive-deps */
import { useOktaAuth } from '@okta/okta-react';
import React, { createContext, useEffect, useState } from 'react';
import {
	ExpenseUserGroupEnum,
	ExpenseUserGroups,
	TimesheetUserGroupEnum,
	TimesheetUserGroups,
	ExpenseUserGroupProdEnum,
	ExpenseUserProdGroups,
	TimesheetUserGroupProdEnum,
	TimesheetUserProdGroups,
} from '../Configurations/OktaUserGroup.enum';

export const AdminAuthContext = createContext();

export function AdminAuthProvider(props) {
	const { children } = props;
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [adminUser, setAdminUser] = useState('');
	const { oktaAuth, authState } = useOktaAuth();
	const [isTimesheetUser, setIsTimesheetUser] = useState(false);
	const [isExpenseUser, setIsExpenseUser] = useState(false);
	const [isExpenseSystemAdministratorUser, setIsExpenseSystemAdministratorUser] = useState(false);
	const [isExpenseAdminUser, setIsExpenseAdminUser] = useState(false);
	const [isLTExpenseAssociateUser, setIsLTExpenseAssociateUser] = useState(false);
	const [isTimesheetSystemAdminUser, setIsTimesheetSystemAdminUser] = useState(false);
	const [isTimesheetAdminUser, setIsTimesheetAdminUser] = useState(false);
	const [isLTAssociateUser, setIsLTAssociateUser] = useState(false);
	const [accessDenied, setAccessDenied] = useState(true);

	const value = {
		adminUser,
		isAuthenticated,
		isTimesheetUser,
		isExpenseUser,
		isTimesheetSystemAdminUser,
		isTimesheetAdminUser,
		isLTAssociateUser,
		isExpenseSystemAdministratorUser,
		isExpenseAdminUser,
		isLTExpenseAssociateUser,
		accessDenied
	};

	useEffect(() => {
		getUserInfo();
	}, [oktaAuth, authState]);

	const getUserInfo = async () => {
		if (authState?.isAuthenticated) {
			try {
				const info = await oktaAuth?.getUser();
				const { groups } = info;
				const isProd = (process.env.REACT_APP_EXECUTION_ENVIRONMENT === "PROD") ? true : false;

				if (groups) {
					setAccessDenied(false);
					const hasExpenseAccess = groups.some((g) => isProd ? 
						ExpenseUserProdGroups.includes(g)
						: ExpenseUserGroups.includes(g)
					);
					const hasTimesheetAccess = groups.some((g) => isProd ? 
						TimesheetUserProdGroups.includes(g)
						: TimesheetUserGroups.includes(g)
					);
					const isExpenseSystemAdministrator = groups.some((group) => isProd ?
						group === ExpenseUserGroupProdEnum.ExpenseSystemAdministratorPROD
						: group === ExpenseUserGroupEnum.ExpenseSystemAdministrator
					);
					const isExpenseAdmin = groups.some((group) => isProd ?
						group === ExpenseUserGroupProdEnum.ExpenseAdministratorPROD
						: group === ExpenseUserGroupEnum.ExpenseAdministrator
					);
					const isLTExpenseAssociate = groups.some((group) => isProd ? 
						group === ExpenseUserGroupProdEnum.LTExpenseAssociatePROD
						: group === ExpenseUserGroupEnum.LTExpenseAssociate
					);
					const isTimesheetSystemAdmin = groups.some((group) => isProd ?
						group === TimesheetUserGroupProdEnum.SystemAdministratorPROD
						: group === TimesheetUserGroupEnum.SystemAdministrator
					);
					const isTimesheetAdmin = groups.some((group) => isProd ?
						group === TimesheetUserGroupProdEnum.TimesheetAdminPROD
						: group === TimesheetUserGroupEnum.TimesheetAdmin
					);
					const isLTAssociate = groups.some((group) => isProd ?
						group === TimesheetUserGroupProdEnum.LTAssociatePROD
						: group === TimesheetUserGroupEnum.LTAssociate
					);
					setIsExpenseSystemAdministratorUser(isExpenseSystemAdministrator);
					setIsExpenseAdminUser(isExpenseAdmin);
					setIsLTExpenseAssociateUser(isLTExpenseAssociate);
					setIsTimesheetSystemAdminUser(isTimesheetSystemAdmin);
					setIsTimesheetAdminUser(isTimesheetAdmin);
					setIsLTAssociateUser(isLTAssociate);
					setIsExpenseUser(hasExpenseAccess);
					setIsTimesheetUser(hasTimesheetAccess);
				}
				setAdminUser(info);
				setIsAuthenticated(true);
			} catch (error) {
				console.error(error);
			}
		}
	};

	return <AdminAuthContext.Provider value={value}>{children}</AdminAuthContext.Provider>;
}
