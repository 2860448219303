import moment from 'moment';

const getSplitEntryStartTime = (entry, breaks, breakIndex) => {
    if (breakIndex === 0) {
        return entry.startTime;
    }
    return breaks[breakIndex - 1].endTime;
};

const getSplitEntryEndTime = (entry, breaks, breakIndex) => {
    if (breakIndex === breaks.length) {
        return entry.endTime;
    }
    return breaks[breakIndex].startTime;
};

const calculateEntryHours = (startTime, endTime) => {
    const start = moment(startTime, 'HH:mm');
    const end = moment(endTime, 'HH:mm');
    let totalTimeInHours = moment.duration(end.diff(start)).asHours();

    if (totalTimeInHours <= 0) {
        totalTimeInHours += 24;
    }

    return Number(totalTimeInHours.toFixed(2));
};

const splitEntryByBreaks = (entry) => {
    if (!entry.timesheetBreaks || entry.timesheetBreaks.length === 0) {
        return [entry];
    }

    const newEntries = [];
    const filteredBreaks = entry.timesheetBreaks
        .filter((x) => x.startTime && x.endTime);

    for (let i = 0; i <= filteredBreaks.length; i += 1) {
        const newEntryStartTime = getSplitEntryStartTime(entry, filteredBreaks, i);
        const newEntryEndTime = getSplitEntryEndTime(entry, filteredBreaks, i);

        if (newEntryStartTime === newEntryEndTime)
            continue;

        const isMultiday = moment(newEntryStartTime, 'HH:mm').isBefore(moment(entry.startTime, 'HH:mm'));
        const totalTimeInHours = calculateEntryHours(newEntryStartTime, newEntryEndTime);

        const transactionDate = isMultiday
            ? moment(entry.transactionDate).add(1, 'days').format('YYYY-MM-DDTHH:mm:ss')
            : entry.transactionDate;

        const relatedEntryId = entry.id;

        newEntries.push({
            ...entry,
            transactionDate,
            startTime: newEntryStartTime,
            endTime: newEntryEndTime,
            totalTimeInHours,
            relatedEntryId,
            isFirstEntry: newEntries.length === 0, // Marking the first entry
        });
    }

    return newEntries;
};

const splitEntriesByBreaks = (entries) => {
    if (!entries || entries.length === 0) {
        return [];
    }

    return entries.reduce((totalEntries, x) => totalEntries.concat(splitEntryByBreaks(x)), []);
};

export default splitEntriesByBreaks;
