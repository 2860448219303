import { Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { COLORS } from '../../../utils/Application_Constants';
import { useFormContext } from 'react-hook-form';
import ExpenseReceiptUpload from './ExpenseReceiptUpload';
const useStyles = makeStyles((theme) => ({
    formHeader: {
        borderBottom: `1px solid ${COLORS.LT_MIDNIGHT25}`,
        paddingBottom: 8,
        marginBottom: 16,
    },
	asterisk: {
		color: COLORS.LT_PEPPER,
	},
}));

const ExpenseReceiptUploadForm = (props) => {
    const { index, expense, originalExpenseEntries, mode } = props;
    const classes = useStyles();
    const { watch } = useFormContext();
    const isRequired = mode === 'clinician-reimbursement' ? true : false;
    const isPosted = expense.status === 'Posted';
	const isTimesheetDocumentUpload =
		watch('type') &&
		watch('category') &&
		watch('category') === 'Travel' &&
		watch('type') === 'Mileage';
    const sectionLabel = isTimesheetDocumentUpload
		? 'Timesheet & Document Upload'
		: 'Receipt Upload';
    return (
        <div>
            <Typography variant='h5' component='h5' className={classes.formHeader}>
                {sectionLabel}{isRequired && <span className={classes.asterisk}> *</span>}
            </Typography>
            <ExpenseReceiptUpload index={index}
                mode={mode}
                isPosted={isPosted} />
        </div>
    );
};

export default ExpenseReceiptUploadForm;
