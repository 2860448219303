import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import { COLORS } from '../../../utils/Application_Constants';
import { Alert as MUIAlert } from '@material-ui/lab';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const styles = (theme) => ({
    container: {
        display: 'inline-block',
        paddingBottom: '20px',
    },
    alert: {
        borderRadius: '15px',
        color: COLORS.LT_PEPPER,
    },
    list: {
        marginLeft: '-20px',
        marginBottom: '-10px',
    },
    listItem: {
        marginBottom: '10px',
    },
    button: {
        marginBottom: '10px',
        fontWeight: 'bold',
        display: 'block',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        cursor: 'pointer'
    },
    uploadTitle: {
        color: COLORS.LT_JOURNEY,
        fontWeight: 'bold',
        textDecoration: 'underline',
    },
});

const ExpenseNeedsReviewAlert = (props) => {
    const { classes, expense } = props;

    const [showAllNeedsReview, setShowAllNeedsReview] = useState(false);

    return (
        <div className={classes.container}>
            <MUIAlert severity='error' className={classes.alert}>
                This transaction needs to be reviewed for the reasons listed below:
                <ul className={classes.list}>
                    {expense.errors.slice(0, showAllNeedsReview ? expense.errors.length : 1)
                        .map((errorObj, index) => {
                            // Expense <-> Expense "needs review" reasons
                            if (errorObj.relatedExpenseId != null) {
                                return (
                                    <li className={classes.listItem} key={index}>
                                        {errorObj.error} <a className={classes.uploadTitle} href={`/#/expense-redirect/${errorObj.relatedExpenseId}/`} target="_blank">{"(Expense " + errorObj.relatedExpenseId.toString() + ")"}</a>
                                    </li>
                                );
                            }

                            // Expense <-> Timesheet "needs review" reasons
                            if (errorObj.relatedTimesheetId != null) {
                                return (
                                    <li className={classes.listItem} key={index}>
                                        {errorObj.error} <a className={classes.uploadTitle} href={`/#/clinician/${expense.clinicianId}/timesheet/${errorObj.relatedTimesheetId}/review`} target="_blank">{"(Timesheet " + errorObj.relatedTimesheetId.toString() + ")"}</a>
                                    </li>
                                );
                            }

                            // All other "needs review" reasons
                            return <li className={classes.listItem} key={index}>{errorObj.error}</li>;
                        })
                    }
                    {expense.errors.length > 1 && (
                            <li style={{ listStyleType: 'none' }}>
                                <a className={classes.button}
                                    onClick={(e) => { e.preventDefault(); setShowAllNeedsReview(!showAllNeedsReview) }}>
                                    {showAllNeedsReview ? <>Show less<ExpandLessIcon fontSize='small' /></> : 'Show all'}
                                </a>
                            </li>
                    )}
                </ul>
            </MUIAlert>
        </div>
    );
};

export default withStyles(styles)(ExpenseNeedsReviewAlert);