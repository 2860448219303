/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { withTranslation } from 'react-i18next';
import {
	withStyles,
	Grid,
	MenuItem,
	Typography,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import WithContexts from '../../../contexts/withContexts';
import { COLORS } from '../../../utils/Application_Constants';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

const styles = (theme) => ({
	menuIcon: {
		color: COLORS.LT_MIDNIGHT25,
		display: 'flex',
		alignItems: 'center',
	},
	menuIconCopyADay: {
		color: COLORS.LT_STEEL,
	},
});

function CopyADayMenuItem(props) {
	const { classes, index, entryType, setAnchorEl, setShowCopyADayMenu, isDisabled, isMobile, isMobileUiV2Enabled } =
		props;
		
	return (
			<MenuItem
				onClick={(event) => {
					if (!isMobile || !isMobileUiV2Enabled) {
						setAnchorEl(event.currentTarget);
					}

					setShowCopyADayMenu(true);
				}}
				id={entryType + `--${index}--menu--list-item--copy-a-day`}
				disabled={isDisabled}
			>
				<Grid container alignItems='center' spacing={2}>
					<Grid item className={classes.menuIcon}>
						<FileCopyOutlinedIcon
							fontSize='small'
							className={classes.menuIconCopyADay}
						/>
					</Grid>
					<Grid item>
						<Typography variant='body2'>Copy a Day</Typography>
					</Grid>
				</Grid>
			</MenuItem>
	);
}

export default WithContexts(withRouter(withTranslation()(withStyles(styles)(CopyADayMenuItem))));
