import React, { useEffect, useState, useRef } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getExpenseById } from '../../Services/ExpenseService';

function getExpenseObjectFromPromise(expenseId, history) {
    const test = getExpenseById(expenseId, history)
        .then((result) => {
            if (result === undefined || result === null) {
                throw new DOMException(`Error getting expense ${expenseId} object from promise`);
            }

            // Prevents infinite loop if user tries to go back to previous page after redirect.
            history.replace('/expenses/review');

            if (result.expenseClass === 'Reimbursement') {
                history.push(`/expenses/clinician-reimbursement/${expenseId}`);
            } else {
                history.push(`/expenses/cc/${expenseId}`);
            }
        })
        .catch((error) => {
            console.error(`Error getting expense ${expenseId} object from promise: `, error);
            alert(`Could not find expense ${expenseId}`);
            history.goBack();
            return null;
        });

    return test;
}

function ExpenseRedirector(props) {
    const { expenseId } = useParams();
    const history = useHistory();
    const initialRenderRef = useRef(true);

    if (initialRenderRef.current) {
        initialRenderRef.current = false;
        const expense = getExpenseObjectFromPromise(expenseId, history);
    }

    return (null);
}

export default ExpenseRedirector;
