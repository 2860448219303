import React, { useMemo } from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import { COLORS } from '../../../utils/Application_Constants';
import ExpenseTextField from './ExpenseTextField';
import ExpensePayDate from './ExpensePayDate';
import ExpenseTransactionDate from './ExpenseTransactionDate';
import ExpenseDateRange from './ExpenseDateRange';
import ExpenseCategoryType from './ExpenseCategoryType';
import ExpenseBillType from './ExpenseBillType';
import ExpenseAmount from './ExpenseAmount';
import ExpenseComments from './ExpenseComments';
import { valueAsMileage } from '../../Utils/ValueAsUtils';

const styles = (theme) => ({
    formHeader: {
        borderBottom: `1px solid ${COLORS.LT_MIDNIGHT25}`,
        paddingBottom: 8,
        marginBottom: 16,
    },
});

const ClinicianExpenseDetailsForm = (props) => {
    const { classes, index, expense, expenseCategories } = props;
    const { control, watch, errors } = useFormContext();
    const expenseCategory = watch(`expenseEntries[${index}].category`);
    const expenseType = watch(`expenseEntries[${index}].type`);
    const isExpenseTravel = expenseCategory && expenseCategory === 'Travel';
    const isClinicianReimbursement = true;
    const hasTransactionDate = expenseCategory && expenseCategory !== '';
    const hasMileage =
        expenseType && expenseCategory && expenseCategory === 'Travel' && expenseType === 'Mileage';
    const isPosted = expense.status === 'Posted';
    const expenseTypes = useMemo(() => {
        if (!expenseCategory) return [];
        if (!expenseCategories) return [];
        let { types } = expenseCategories.find((option) => option.category === expenseCategory);
        return types ? types : [];
    }, [expenseCategory, expenseCategories]);

    return (
        <>
            <Typography variant='h5' component='h5' className={classes.formHeader}>
                Expense Details
            </Typography>
            <Grid container spacing={3}>
                <ExpenseBillType index={index} expense={expense} isClinicianReimbursement={isClinicianReimbursement} isSGA={[]} isPosted={isPosted} />
                <ExpensePayDate index={index} expense={expense} mode={'reimbursement'} isPosted={isPosted} />
                <ExpenseCategoryType expenseTypes={expenseTypes} index={index} expense={expense} expenseCategories={expenseCategories} isSGA={[]} isPosted={isPosted} />
                {hasTransactionDate && (
                    <ExpenseTransactionDate index={index} expense={expense} isReimbursement isPosted={isPosted} />
                )}
                <ExpenseAmount index={index} expense={expense} isReimbursement isPosted={isPosted}/>
                {hasMileage && (
                    <Grid item xs={12} sm={6}>
                        <Controller
                            control={control}
                            name={`expenseEntries[${index}].mileage`}
                            defaultValue={expense.mileage}
                            rules={{
                                required: 'Miles driven is required',
                            }}
                            render={({ onChange, value }) => (
                                <ExpenseTextField
                                    id={'miles-driven'}
                                    onChange={(e) => onChange(valueAsMileage(e.target.value))}
                                    value={value}
                                    placeholder={'Enter number of miles'}
                                    label={'Miles driven'}
                                    required
                                    disabled={isPosted}
                                    error={Boolean(errors.expenseEntries?.[index]?.mileage)}
                                    helperText={errors.expenseEntries?.[index]?.mileage?.message}
                                />
                            )}
                        />
                    </Grid>
                )}
                {isExpenseTravel && <ExpenseDateRange index={index} expense={expense} isPosted={isPosted} />}
                <ExpenseComments index={index} expense={expense} isPosted={isPosted} />
            </Grid>
        </>
    );
};

export default withStyles(styles)(ClinicianExpenseDetailsForm);
