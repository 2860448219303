import React from 'react';
import { withTranslation } from 'react-i18next';
import {
    withStyles,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import WithContexts from '../../../contexts/withContexts';
import { COLORS } from '../../../utils/Application_Constants';
import moment from 'moment';

const styles = (theme) => ({
    tableContainer: {
        border: `1px solid ${COLORS.LT_MIDNIGHT5}`,
        borderRadius: '8px',
    },
    tableHead: {
        fontSize: '12px',
        lineHeight: '16px',
        color: COLORS.LT_MIDNIGHT33,
        padding: '8px 16px',
    },
    emptyText: {
        color: COLORS.LT_MIDNIGHT25,
    },
    childCell: {
        padding: '0px 0px 0px 30px',
    }
});

function TimesheetReviewTable(props) {
    const { classes, t, timesheetEntries, availableRates } = props;

    const formatRateTypeId = (id) => {
        const rate = availableRates?.find((rate) => rate.id === id);
        if (!rate) return null;
        const { displayName } = rate;
        return displayName;
    };

    const renderTableBody = () => {
        if (!timesheetEntries || timesheetEntries.length <= 0) {
            return (
                <TableRow id='timesheet-entry--no-data'>
                    <TableCell colSpan={5}>
                        {t('submitTimesheet:TIMESHEET_TABLE.NO_TIMESHEET_DATA')}
                    </TableCell>
                </TableRow>
            );
        }

        return timesheetEntries.map((row, index) => (
            <TableRow key={row.id} id={`timesheet-entry--${index}`}>
                <TableCell
                    className={row.isFirstEntry ? null : (row.relatedEntryId ? classes.childCell : null)}>{moment(row.transactionDate).format('ddd M/D')}</TableCell>
                <TableCell>{formatRateTypeId(row.rateTypeId)}</TableCell>
                <TableCell className={!row.units ? classes.emptyText : null}>
                    {row.units || '-'}
                </TableCell>
                <TableCell className={!row.startTime ? classes.emptyText : null}>
                    {row.startTime ? moment(row.startTime, 'HH:mm').format('h:mm a') : '-'}
                </TableCell>
                <TableCell className={!row.endTime ? classes.emptyText : null}>
                    {row.endTime ? moment(row.endTime, 'HH:mm').format('h:mm a') : '-'}
                </TableCell>
                <TableCell className={!row.totalTimeInHours ? classes.emptyText : null}>
                    {row.totalTimeInHours || '-'}
                </TableCell>
                <TableCell className={!row.comment ? classes.emptyText : null}>
                    {row.comment || 'No comments'}
                </TableCell>
            </TableRow>
        ));
    };

    return (
        <TableContainer className={classes.tableContainer}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableHead}>
                            {t('submitTimesheet:TIMESHEET_TABLE.DATE')}
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                            {t('submitTimesheet:TIMESHEET_TABLE.SHIFT_TYPE')}
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                            {t('submitTimesheet:TIMESHEET_TABLE.UNITS')}
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                            {t('submitTimesheet:TIMESHEET_TABLE.START')}
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                            {t('submitTimesheet:TIMESHEET_TABLE.END')}
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                            {t('submitTimesheet:TIMESHEET_TABLE.HOURS')}
                        </TableCell>
                        <TableCell className={classes.tableHead}>
                            {t('submitTimesheet:TIMESHEET_TABLE.COMMENTS')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{renderTableBody()}</TableBody>
            </Table>
        </TableContainer>
    );
}

export default WithContexts(
    withRouter(withTranslation()(withStyles(styles)(TimesheetReviewTable)))
);
