import React, { useEffect, useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import WithContexts from '../../contexts/withContexts';
import { withTranslation } from 'react-i18next';
import { Hidden } from '@material-ui/core';
import { COLORS, professionList } from '../../utils/Application_Constants';
import {
	withStyles,
	Button,
	FormControl,
	InputLabel,
	NativeSelect,
	ThemeProvider,
	TextField,
} from '@material-ui/core';
import StartCredentialingImage from '../../assets/images/start-credentialing-bg-2.jpg';
import StartCredentialingMobileIcon from '../../assets/images/ltoe-axuall-credentialing-screenshot.png';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import TextFieldComponent from '../../components/GlobalComponents/TextFieldComponent/TextFieldComponent';
import createTheme from '@material-ui/core/styles/createTheme';
import ErrorDialog from '../../components/GlobalComponents/Dialog/ErrorDialog';
import { validateNpi } from '../../utils/CredentialingAppUtility';
import { postAzureB2cUserClaims } from '../../services/Credentialing';
import {setProviderRecipe} from "../../services/CredentialVerificationService";

const styles = (theme) => ({
	root: {
		// margin: '80px 0',
		//padding:'80px 0
		// height: '100vh',
		minHeight: '700px',
		// backgroundImage: `url(${StartCredentialingImage})`,
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		// filter: 'blur(10px)'

		background: `linear-gradient(to right,#4370a000, #3f6a9900), url(${StartCredentialingImage}) no-repeat top center`,
	},
	mobileIcon: {
		height: '500px',
	},
	npiLink: {
		color: COLORS.LT_JOURNEY,
		textDecoration: 'underline',
		display: 'block',
		marginBottom: '10px',
	},
	leftPane: {
		padding: '80px 65px',
		[theme.breakpoints.down('sm')]: {
			padding: '50px 15px',
		},
		color: '#3c3c3c',
	},
	rightPane: {
		paddingTop: '80px',
	},
	title: {
		color: '#FFFFFF',
		float: 'left',
		fontSize: '30px',
		fontWeight: '500',
		marginBottom: '10px',
		[theme.breakpoints.down('sm')]: {
			textAlign: 'left',
			fontSize: '25px',
			paddingRight: '2px',
		},
	},
	subtitle: {
		color: '#596579',
		float: 'left',
		fontSize: '25px',
		fontWeight: '500',
		marginBottom: '30px',
		[theme.breakpoints.down('sm')]: {
			fontSize: '20px',
		},
	},
	// section: {
	//     color: '#FFFFFF',
	//     float: 'left',
	//     fontSize: '13px',
	//     fontWeight: '300',
	//     textAlign: 'left',
	//     marginBottom: '20px'
	// },
	infoBlock: {
		color: '#0077c8',
		marginBottom: '20px',
	},
	info: {
		color: '##333333',
		float: 'left',
		textAlign: 'left',
		marginBottom: '10px',
	},
	informationText: {
		color: COLORS.LT_SLATE,
		textAlign: 'left',
		fontWeight: 'normal',
		marginBottom: '10px',
	},
	getStartedButton: {
		//backgoundColor: '#2a3953',
		[theme.breakpoints.up('md')]: {
			width: '365px',
			fontSize: '16px',
			height: '50px',
		},
		[theme.breakpoints.down('sm')]: {
			width: '310px',
			fontSize: '16px',
			height: '50px',
		},
		disabledButton: {
			backgroundColor: 'red',
		},
	},
	inputLabel: {
		color: '#3c3c3c',
		fontFamily: 'museo-sans',
		fontSize: '16px',
		fontWeight: 'bold',
		marginLeft: '0px',
		position: 'inherit',
	},
	setWidthTo100OnMobile: {
		width: '100%',
		marginTop: '10px',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	gridRoot: {
		marginTop: '10px',
		marginBottom: '10px',
	},
	validationErrorMsg: {
		color: '#8b0000',
	},
});

// const errorTheme = createTheme({
// 	overrides: {
// 		MuiFormLabel: {
// 			root: {
// 				'&$error': {
// 					color: '#8b0000',
// 				},
// 			},
// 		},
// 	},
// });

const normalTheme = createTheme({
	overrides: {
		MuiNativeSelect: {
			// Name of the rule
			select: {
				'&:hover': {
					background: '#FFFFFF',
				},
				'&:focus': {
					background: '#FFFFFF',
					backgroundColor: '#FFFFFF',
				},
				background: '#FFFFFF !important',
				backgroundColor: '#FFFFFF !important',
			},
		},
		MuiFormLabel: {
			root: {
				color: '#FFFFFF',
				'&:focus': {
					color: '#FFFFFF',
				},
			},
		},
		MuiButton: {
			containedPrimary: {
				backgroundColor: COLORS.LT_SUNFLOWER,
				fontWeight: '700',
				borderRadius: '0px',
				boxShadow: 'none',
				color: COLORS.LT_MIDNIGHT,
				fontFamily: 'inherit',
				textTransform: 'none',
				'&:hover': {
					backgroundColor: '#009909',
					boxShadow: 'none',
				},
			},
		},
		MuiInputBase: {
			input: {
				background: '#FFFFFF !important',
				backgroundColor: '#FFFFFF !important',
			},
		}
	},
});
function StartCredentialsV2({ classes, t, userContext, personalInformation, initialProfession, getStarted, credAppStatus, priorCredAppStatus }) {
	const initialNpi = personalInformation.npiNumber;
	const [profession, setProfession] = useState(initialProfession);
	const [npi, setNpi] = useState(initialNpi);
	const [submitted, setSubmitted] = useState(false);
	const [buttonClickError, setButtonClickError] = useState(null);
	const [isValidNpi, setIsValidNpi] = useState(validateNpi(initialNpi));

	let items = ['1', '2', '3', '4', '5'];

	const inforamtionTextBlock = items.map((item, index) => {
		return (
			<div
				key={'infoDiv-' + index}
				className={`${classes.informationText}`}
				style={{
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<CheckCircleIcon
					style={{ height: '13px', width: '13px', color: COLORS.LT_EMERALD }}
				/>
				<span style={{ marginLeft: '10px' }}>
					{t(`credentials:START_CREDENTIALING.CRED_INFO_${item}`)}
				</span>
			</div>
		);
	});

	const handleProfessionChange = (event) => {
		setProfession(event.target.value);
	};
	const handleNpiChange = (event) => {
		setNpi(event.target.value);
		setIsValidNpi(validateNpi(event.target.value));
	};

	const submitAction = async () => {
		setSubmitted(true);

		const postAzureB2cUserClaimsRequest = {
			b2cId: userContext.account.accountIdentifier,
			npiNumber: npi,
			providerTitles: personalInformation.titles
		};

		await postAzureB2cUserClaims(postAzureB2cUserClaimsRequest);

		await getStarted(profession, npi);

		try {
			await setProviderRecipe(npi, userContext.degree, credAppStatus, priorCredAppStatus);
			window.open(process.env.REACT_APP_AXUALL_REDIRECTURL, '_blank');
		} catch (error) {
			setButtonClickError(error);
		}
	};

	const clearButtonClickError = () => {
		setButtonClickError(null);
	};

	return (
		<div className={classes.root}>
			<ErrorDialog
				open={!!buttonClickError}
				onClose={clearButtonClickError}
				title={buttonClickError?.title}
				description={buttonClickError?.message}
				errorId={buttonClickError?.errorId}
			/>
			<Container maxWidth='xl'>
				<CssBaseline />
				<Grid item xs={12}>
					<Grid container justifyContent='space-evenly'>
						<Grid item xs={12} md={7} className={classes.leftPane}>
							<h1 className='cr-foundation'>
								{t(`credentials:START_CREDENTIALING.TITLE`)}
							</h1>
							<h3 className='cr-foundation'>
								{t(`credentials:START_CREDENTIALING.SUB_TITLE`)}
							</h3>
							<p className='cr-mt-4 cr-slate'>
								{t(`credentials:START_CREDENTIALING.SECTION`)}
							</p>

							<span className={`${classes.infoBlock}`}> {inforamtionTextBlock}</span>
							{/* <Grid item xs={12} md={7}>                            <span
                                className={`${classes.info}`}>{t(`credentials:START_CREDENTIALING.GET_STARTED_INFO`)}
                            </span>
                            </Grid> */}

							<Grid item classes={{ root: classes.gridRoot }} xs={12} md={7}>
								<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
									<ThemeProvider theme={normalTheme}>
										<InputLabel
											shrink
											htmlFor='profession'
											classes={{ root: classes.inputLabel }}
										>
											{t('credentials:START_CREDENTIALING.PROFESSION')}
										</InputLabel>
									</ThemeProvider>
									<ThemeProvider theme={normalTheme}>
										<NativeSelect
											id='profession'
											name='profession'
											value={profession ?? ''} 
											onChange={handleProfessionChange}
											input={
												<TextFieldComponent
													style={{ border: `0px solid ${COLORS.RAVEN}` }}
													classes={{
														input: classes.setWidthTo100OnMobile,
													}}
												/>
											}
										>
											{' '}
											<option key={''} value={null}></option>
											{professionList.map((item) => (
												<option key={item.value} value={item.value}>
													{item.label}
												</option>
											))}
										</NativeSelect>
									</ThemeProvider>
								</FormControl>
							</Grid>

							<Grid item classes={{ root: classes.gridRoot }} xs={12} md={7}>
								<FormControl classes={{ root: classes.setWidthTo100OnMobile }}>
									<ThemeProvider theme={normalTheme}>
										<InputLabel
											shrink
											htmlFor='npi'
											classes={{ root: classes.inputLabel }}
										>
											{t('credentials:START_CREDENTIALING.NPI')}
										</InputLabel>
									</ThemeProvider>
									<ThemeProvider theme={normalTheme}>
										<TextField
											id="npi"
											name='npi'
											onChange={handleNpiChange}
											error={!isValidNpi}
											placeholder={t(`credentials:START_CREDENTIALING.ENTER_NPI`)}
											variant='outlined'
											classes={{ root: classes.inputLabel }}
											InputProps={{
												readOnly: !!initialNpi && !!priorCredAppStatus,
											}}
											value={npi ?? ''}
											helperText={isValidNpi ? null :  t(`credentials:START_CREDENTIALING.NPI_INVALID`) }
											input={
												<TextFieldComponent
													style={{ border: `0px solid ${COLORS.RAVEN}` }}
													classes={{
														input: classes.setWidthTo100OnMobile,
													}}												
												/> 											
											}									
										></TextField>
										<div>
											<a
												href='https://npiregistry.cms.hhs.gov/search'
												target='_blank'
												rel='noopener noreferrer'
												className={classes.npiLink}
											>
												To look up your NPI Number click here
											</a>
										</div>
										<div>
											{t(`credentials:START_CREDENTIALING.MISSING_NPI`)}
										</div>
									</ThemeProvider>
								</FormControl>
							</Grid>

							<Grid item xs={12} md={7}>
								<div>
									<ThemeProvider theme={normalTheme}>
										<Button
											className={classes.getStartedButton}
											variant='contained'
											color='primary'
											disabled={!profession || !npi || submitted || !isValidNpi}
											onClick={submitAction}											
										>
											{t(`credentials:START_CREDENTIALING.GET_STARTED`)}
										</Button>		
									</ThemeProvider>				
								</div>
							</Grid>
						</Grid>
						<Hidden only={['xs', 'sm']}>
							<Grid item xs={12} md={5} className={classes.rightPane}>
								<img
									src={StartCredentialingMobileIcon}
									className={classes.mobileIcon}
									alt='StartCredentialing Mobile Icon'
								/>
							</Grid>
						</Hidden>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
}

export default WithContexts(withTranslation()(withStyles(styles)(StartCredentialsV2)));
