/**
 * @file Internationalization configuration.
 */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import common from './i18n/en-us/common.json';
import tabs from './i18n/en-us/tabs.json';
import profile from './i18n/en-us/profile.json';
import documents from './i18n/en-us/documents.json';
import timeAndPay from './i18n/en-us/timeAndPay.json';
import dashboard from './i18n/en-us/dashboard.json';
import contacts from './i18n/en-us/contacts.json';
import presents from './i18n/en-us/presents.json';
import assignments from './i18n/en-us/assignments.json';
import error from './i18n/en-us/error.json';
import widgets from './i18n/en-us/widgets.json';
import credentials from './i18n/en-us/credentials.json';
import submitTimesheet from './i18n/en-us/submitTimesheet.json';
import additionalExpenseDetails from './i18n/en-us/additional-expense-details.json';
import currency from './i18n/en-us/currency.json';

i18n.use(initReactI18next).init({
	resources: {
		en: {
			common,
			tabs,
			profile,
			documents,
			timeAndPay,
			contacts,
			presents,
			assignments,
			error,
			widgets,
			credentials,
			dashboard,
			submitTimesheet,
			additionalExpenseDetails,
			currency,
		},
	},
	ns: [
		'common',
		'tabs',
		'profile',
		'documents',
		'timeAndPay',
		'contacts',
		'presents',
		'assignments',
		'error',
		'widgets',
		'credentials',
		'internship',
		'dashboard',
		'submitTimesheet',
		'additionalExpenseDetails',
		'currency',
	],
	lng: 'en-us',
	lowerCaseLng: true,
});

export default i18n;
