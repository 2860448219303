import { Grid, Paper, Typography, withStyles } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import PublishIcon from '@material-ui/icons/Publish';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import { COLORS } from '../../../utils/Application_Constants';
import FilePreview from './FilePreview';
import { Alert } from '@material-ui/lab';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import FileRejections from './FileRejections';
import heic2any from 'heic2any';

const styles = (theme) => ({
	PaperRoot: {
		padding: '24px',
		background: 'white',
		marginTop: 41,
	},
	formControl: {
		marginBottom: theme.spacing(1),
	},
	'& input[type="file"]': {
		display: 'none',
	},
	outside: {
		borderStyle: 'dashed',
		borderWidth: '2px',
		position: 'relative',
		borderColor: COLORS.LT_MIDNIGHT25,
		textAlign: 'center',
		borderRadius: '4px',
		cursor: 'pointer',
		opacity: '1',

		'&:hover': {
			background: COLORS.LT_MIDNIGHTBG2,
		},

		'&.disabled': {
			'&:hover': {
				background: 'transparent',
			},
			cursor: 'initial',
			opacity: 0.5,
		},
	},
	header: {
		paddingBottom: '10px',
	},
	dot: {
		height: '50px',
		width: '50px',
		backgroundColor: COLORS.LT_JOURNEY10,
		borderRadius: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: COLORS.LT_JOURNEY,
	},
	uploadTitle: {
		'& u': {
			color: COLORS.LT_JOURNEY,
			fontWeight: 'bold',
		},
	},
	uploadDescription: {
		fontSize: '12px',
		marginTop: '8px',
		color: COLORS.LT_MIDNIGHT50,
	},
	centerDrop: {
		padding: 24,
		minHeight: 150,
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'center',
		gap: 8,
	},
	tip: {
		marginTop: 24,
	},
	standardInfo: {
		background: COLORS.LT_MIDNIGHTSTEEL,
		color: COLORS.LT_MIDNIGHT75,
		fontSize: 12,
		lineHeight: 1.25,
		opacity: 0.8,
	},
	standardInfoIcon: {
		color: COLORS.LT_MIDNIGHT75,
	},
	error: {
		marginTop: '24px',
		color: COLORS.LT_PEPPER,
		fontSize: '.75rem',
	},
	filePreviews: {
		marginTop: '24px',
		display: 'flex',
		flexDirection: 'column',
		gap: '8px',
	},
});

const ExpenseReceiptUpload = (props) => {
	const { classes, index, mode, isPosted } = props;
	const { register, setValue, errors, getValues, clearErrors, trigger } = useFormContext();
	const [existingFiles, setExistingFiles] = useState([]);
	const [newFiles, setNewFiles] = useState([]);
	const [error, setError] = useState(null);
	const [fileRejections, setFileRejections] = useState([]);
	const isRequired = mode === 'clinician-reimbursement' ? true : false;
	const maxSize = 5 * 1048576;
	const maxFiles = 15;
	const [loadingState, setLoadingState] = useState({});
	const isDisabled = JSON.stringify(loadingState) !== '{}' || isPosted;
	const bytesToMegaBytes = (bytes) => (bytes / (1024 * 1024)).toFixed(1);

	useEffect(() => {
		const fileUploads = getValues(`fileUploads[${index}]`);
		if (fileUploads){
			//The resetting of the setValue with  shouldValidate: false and the subsequent trigger
			//was added to get rid of a validation issue that was happening when deleting all images
			//on a middle tab (4) and then deleting a prior tab(3) and having the validation
			//correct on the new tabs post delete.  Might be a better way but this seems to resolve the issue
			setNewFiles(fileUploads.newFiles || []);
			setValue(`fileUploads[${index}].newFiles`, fileUploads.newFiles, { shouldValidate: false});
			setExistingFiles(fileUploads.existingFiles || []);
			setValue(`fileUploads[${index}].existingFiles`, fileUploads.existingFiles, { shouldValidate: false});
			trigger(`fileUploads[${index}].newFiles`);
			trigger(`fileUploads[${index}].existingFiles`);
		}else{
			setNewFiles([]);
			setExistingFiles([]);
		}

		// Make sure to revoke the data uris to avoid memory leaks, will run on unmount
		return () => {
			// Cleanup logic here
			if (newFiles && newFiles.length && newFiles.length > 0){
				newFiles.forEach((file) => URL.revokeObjectURL(file.preview));
			}
		  };
	}, []);

	const convertFile = async (file) => {
		if (file.type === 'image/heic') {
			const fileName = file.name.substring(0, file.name.indexOf('.'));
			const convertedBlob = await heic2any({
				blob: file,
				toType: 'image/jpeg',
				quality: 0.5,
			});
			const newFile = new File([convertedBlob], fileName + '.jpg', { type: 'image/jpeg' });
			if (bytesToMegaBytes(newFile.size) > 5) {
				setError('File is too large after HEIC conversion');
				return;
			}
			return newFile;
		} else {
			return file; // Return non-HEIC files directly
		}
	};

	const delay = (ms) => {
		return new Promise((resolve) => setTimeout(resolve, ms));
	};

	const onDrop = useCallback(
		async (acceptedFiles) => {
			setError(null);
			clearErrors(`fileUploads[${index}].existingFiles`);
			clearErrors(`fileUploads[${index}].newFiles`);
			const checkExistingFiles = getValues(`fileUploads[${index}].existingFiles`);
			const checkNewFiles = getValues(`fileUploads[${index}].newFiles`);
			setFileRejections([]);
			if (acceptedFiles.length + checkNewFiles.length + checkExistingFiles.length > maxFiles || checkNewFiles.length + checkExistingFiles.length === maxFiles) {
				setError('Max file limit reached');
				return;
			} else {
				setError(null);
			}

			let newIsLoading = { ...loadingState };
			setValue(`fileUploads[${index}].newFiles`, [...newFiles, ...acceptedFiles]);
			setNewFiles([...newFiles, ...acceptedFiles]);

			const conversionPromises = acceptedFiles.map(async (file) => {
				const sizeInMB = bytesToMegaBytes(file.size);
				let duration = sizeInMB >= 4.3 ? 9 : sizeInMB >= 3.5 ? 5 : 2.5;
				newIsLoading = { ...newIsLoading, [file.name]: { isLoading: true, duration } };
				setLoadingState(newIsLoading); // Set loading for this file

				try {
					const convertedFile = await convertFile(file);
					Object.assign(convertedFile, {
						preview: URL.createObjectURL(convertedFile),
					});
					return convertedFile;
				} catch (error) {
					console.error('Error converting HEIC image:', error);
				} finally {
					newIsLoading = {
						...newIsLoading,
						[file.name]: { ...newIsLoading[file.name], duration: 0.25 },
					};
					setLoadingState(newIsLoading); // Clear duration for this file

					await delay(250);

					newIsLoading = {
						...newIsLoading,
						[file.name]: { ...newIsLoading[file.name], isLoading: false },
					};
					setLoadingState(newIsLoading); // Clear loading for this file
				}
			});

			// Wait for all conversions to finish using await
			const convertedFiles = await Promise.all(conversionPromises);

			const allFiles = [...newFiles, ...convertedFiles];
			setLoadingState({});
			setValue(`fileUploads[${index}].newFiles`, allFiles, { shouldValidate: true, shouldDirty: true });
			setNewFiles(allFiles);
		},
		[setValue, newFiles]
	);

	const onError = useCallback(
		(err) => {
			setError(err);
		},
		[setValue, newFiles]
	);

	useEffect(() => {
		register(`fileUploads[${index}].newFiles`, {
			validate: {
				required: (value) =>
				{
					const checkExistingFiles = getValues(`fileUploads[${index}].existingFiles`);
					if (!((value && (value.length + checkExistingFiles.length) > 0 && isRequired) || !isRequired)){
						return 'Upload is required';
					}				
				},
			},
		});
		register(`fileUploads[${index}].existingFiles`, {
			validate: {
				required: (value) =>{
					const checkNewFiles = getValues(`fileUploads[${index}].newFiles`);
					if (!((value && (value.length + checkNewFiles.length) > 0 && isRequired) || !isRequired)){
						return 'Upload is required';
					}
				},
			},
		});
		return () => {
			setError(null);
			setFileRejections([]);
		};
	}, [register, isRequired]);

	const customValidation = (file) => {
		const isValidSize = file.size <= maxSize;
		if (!isValidSize) return { code: 'invalid-file-size', message: 'File is larger than 5 MB' };
		return null;
	};

	const onDropRejected = useCallback((rejections) => {
		setFileRejections(rejections);
	});

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		onError,
		onDropRejected,
		accept: {
			'image/jpeg': [],
			'image/png': [],
			'image/heic': [],
			'application/pdf': [],
		},
		disabled: isDisabled,
		validator: customValidation,
	});

	const removeFile = (fileIndex, isExisting) => {
		setError(null);
		clearErrors(`fileUploads[${index}].existingFiles`);
		clearErrors(`fileUploads[${index}].newFiles`);
		if (isExisting){
			if (existingFiles && existingFiles.length && existingFiles.length > 0){
				const existingFile = existingFiles[fileIndex];
				setLoadingState((prevState) => ({ ...prevState, [existingFile.name]: { isLoading: true } }));
				const aExistingFiles = existingFiles.filter((existingFile, index) => index !== fileIndex);
				setExistingFiles(aExistingFiles);
				setValue(`fileUploads[${index}].existingFiles`, aExistingFiles, { shouldValidate: true, shouldDirty: true });
			}
		}else{
			if (newFiles && newFiles.length && newFiles.length > 0){
				const newFile = newFiles[fileIndex];
				setLoadingState((prevState) => ({ ...prevState, [newFile.name]: { isLoading: true } }));
				const aNewFiles = newFiles.filter((file, index) => index !== fileIndex);
				setNewFiles(aNewFiles);
				setValue(`fileUploads[${index}].newFiles`, aNewFiles, { shouldValidate: true, shouldDirty: true });
			}
		}
		setLoadingState({});
	};

	return (
		<Paper classes={{ root: classes.PaperRoot }}>
			<Grid
				{...getRootProps({
					className: `${classes.outside} ${isDisabled && 'disabled'}`,
				})}
			>
				<Grid className={classes.centerDrop}>
					<Grid className={classes.dot}>
						<PublishIcon className={classes.icon} />
					</Grid>
					<Typography variant='body2' className={classes.uploadTitle}>
						<u>Click to upload</u> or drag and drop
					</Typography>
					<Typography variant='body2' className={classes.uploadDescription}>
						PDF, HEIC, PNG or JPEG <br /> (Maximum size: 5MB)
					</Typography>
					<input
						{...getInputProps()}
						name={`fileUploads[${index}].newFiles`}
						type='file'
						disabled={isDisabled}
					/>
				</Grid>
			</Grid>
			{error && (
				<Typography variant='body2' className={classes.error}>
					<b>{error?.message ? error.message : error}</b>
				</Typography>
			)}

			{(!!newFiles?.length > 0 || !!existingFiles?.length > 0)&& (
				<div className={classes.filePreviews}>
					<Typography variant='body2'>
						{((newFiles && newFiles.length) ? newFiles.length : 0 ) + ((existingFiles && existingFiles.length) ? existingFiles.length : 0 )} of {maxFiles} files
					</Typography>
					{existingFiles.map((file, index) => (
						<FilePreview
							key={file.filename}
							fileIndex={index}
							file={file}
							isExisting={true}
							loadingState={loadingState}
							isDisabled={isDisabled}
							removeFile={removeFile}
							mode={mode}
						/>
					))}
					{newFiles.map((file, index) => (
						<FilePreview
							key={file.name}
							fileIndex={index}
							file={file}
							isExisting={false}
							loadingState={loadingState}
							isDisabled={isDisabled}
							removeFile={removeFile}
							mode={mode}
						/>
					))}
				</div>
			)}

			{fileRejections?.length > 0 && <FileRejections fileRejections={fileRejections} />}

			<Alert
				severity='info'
				icon={<InfoOutlinedIcon fontSize='small' className={classes.standardInfoIcon} />}
				className={classes.tip}
				classes={{ standardInfo: classes.standardInfo }}
			>
				Please ensure images are clear, well-lit, free of blur or distortion, and in upright
				orientation.
			</Alert>

			{(( (errors?.fileUploads && errors?.fileUploads[index] && errors?.fileUploads[index]?.newFiles) || (errors?.fileUploads && errors?.fileUploads[index] && errors?.fileUploads[index]?.existingFiles) )) && (
				<Typography variant='body2' className={classes.error}>
					{errors.fileUploads?.[index]?.newFiles?.message ? errors.fileUploads?.[index]?.newFiles?.message 
						: (errors.fileUploads?.[index]?.existingFiles?.message ? errors.fileUploads?.[index]?.existingFiles?.message : '')}
				</Typography>
			)}
		</Paper>
	);
};

export default withStyles(styles)(ExpenseReceiptUpload);
