/**
 *@file Services file that fetches from the API for the Time and Pay Page
 */
import {
	axiosCredentialInstance,
	axiosWCApiInstance,
} from '../configuration/axios/axiosConfiguration';
import { ApiError } from '../utils/ApiError';

async function getNextPayDateData(externalId) {
	let response = {
		data: {},
	};
	try {
		response = await axiosCredentialInstance.get(
			`clinician/${externalId}/timeandpay/nextpaydate`
		);
	} catch (error) {
		console.log('getNextPayDate error=' + error);
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'getNextPayDate'
			);
		}
	}
	return response.data;
}

async function getNextPayDateWCAPI(externalId) {
	let response = {
		data: {},
	};
	try {
		response = await axiosWCApiInstance.get(`clinicians/${externalId}/NextPayDate`);
	} catch (error) {
		console.log('getNextPayDate error=' + error);
		if (!!error.response && error.response.status === 404) {
			console.log('getNextPayDate status 404 =: ' + error.response.status);
			//Return empty on 404
			response = {
				data: [],
			};
		} else if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'getNextPayDate'
			);
		}
	}
	if (response.status === 204) {
		response.data = [];
	}
	return response.data;
}

async function postFeedbackResponse(externalId, feedback) {
	let payload = {
		Id: externalId,
		Level: feedback,
	};
	try {
		await axiosWCApiInstance.post(`clinicians/${externalId}/Satisfaction`, payload);
	} catch (error) {
		console.log('postFeedbackResponse error=' + error);
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'postFeedbackResponse'
			);
		}
	}
}

async function getYTDPayData(externalId) {
	let response;
	try {
		response = await axiosWCApiInstance.get(`clinicians/${externalId}/PayStatementsYTD`);
	} catch (error) {
		console.log('getYTDPayData error=' + error);
		if (!!error.response && error.response.status === 404) {
			console.log('getYTDPayData status 404 =: ' + error.response.status);
			//Return Empty List on 404
			response = {
				data: [],
			};
		} else if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'getYTDPayData'
			);
		}
	}
	if (!response) return null;
	return response.data;
}

async function getClinicianPaysheetZip(externalId, paysheets) {
	try {
		await axiosWCApiInstance
			.post(`clinicians/${externalId}/PayStatementZip`, paysheets, {
				headers: {
					'Content-type': 'application/json',
				},
				responseType: 'blob',
			})
			.then((response) => {
				const blob = new Blob([response.data], { type: 'application/zip' });
				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = `PayStatements.zip`;
				document.body.appendChild(link);
				link.click();
				link.parentNode.removeChild(link);
			});
	} catch (error) {
		console.log('getClinicianPaysheetZip error=' + error);
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'getClinicianPaysheetZip'
			);
		}
	}
}

async function getClinicianPaysheetPdf(externalId, providerId, checkNumber, checkDate) {
	let payload = {
		providerId: providerId,
		checkNumber: checkNumber,
		checkDate: checkDate,
	};
	try {
		await axiosWCApiInstance
			.post(`clinicians/${externalId}/PayStatementPdf`, payload, {
				headers: {
					'Content-type': 'application/json',
				},
				responseType: 'blob',
			})
			.then((response) => {
				const blob = new Blob([response.data], { type: 'application/pdf' });
				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = `${checkNumber}.pdf`;
				document.body.appendChild(link);
				link.click();
				link.parentNode.removeChild(link);
			});
	} catch (error) {
		console.log('getClinicianPaysheetPdf error=' + error);
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'getClinicianPaysheetPdf'
			);
		}
	}
}

async function getClinicianTaxStatementsZip(externalId, statements) {
	try {
		await axiosWCApiInstance
			.post(`clinicians/${externalId}/TaxStatementsZip`, statements, {
				headers: {
					'Content-type': 'application/json',
				},
				responseType: 'blob',
			})
			.then((response) => {
				const blob = new Blob([response.data], { type: 'application/zip' });
				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = `TaxStatements.zip`;
				document.body.appendChild(link);
				link.click();
				link.parentNode.removeChild(link);
			});
	} catch (error) {
		console.log('getClinicianTaxStatementsZip error=' + error);
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'getClinicianTaxStatementsZip'
			);
		}
	}
}

async function getClinicianTaxStatementPdf(externalId, providerId, year, statementType, corrected) {
	let payload = {
		externalId: providerId,
		year: year,
		statementType: statementType,
		corrected: corrected,
	};
	try {
		await axiosWCApiInstance
			.post(`clinicians/${externalId}/TaxStatementsPdf`, payload, {
				headers: {
					'Content-type': 'application/json',
				},
				responseType: 'blob',
			})
			.then((response) => {
				const blob = new Blob([response.data], { type: 'application/pdf' });
				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = `TaxStatement-${year}-${statementType}.pdf`;
				document.body.appendChild(link);
				link.click();
				link.parentNode.removeChild(link);
			});
	} catch (error) {
		console.log('getClinicianTaxStatementPdf error=' + error);
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'getClinicianTaxStatementPdf'
			);
		}
	}
}

async function getPayStatementDetailsData(externalId, startDate, endDate) {
	let response = {
		data: {},
	};

	try {
		if (startDate === null && endDate === null) {
			response = await axiosWCApiInstance.get(`clinicians/${externalId}/PayStatements`);
		}

		if (startDate !== null && endDate !== null) {
			response = await axiosWCApiInstance.get(`clinicians/${externalId}/PayStatements`, {
				params: {
					startTime: `${startDate}`,
					endTime: `${endDate}`,
				},
			});
		}
	} catch (error) {
		console.log('getPayStatementDetails error=' + error);
		if (!!error.response && error.response.status === 404) {
			console.log('getPayStatementDetails status 404 =: ' + error.response.status);
			//Return Empty List on 404
			response = {
				data: [],
			};
		} else if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'getPayStatementDetails'
			);
		}
	}

	return response.data;
}

async function getTaxStatementDetails(externalId) {
	let response = {
		data: {},
	};

	try {
		response = await axiosWCApiInstance.get(`clinicians/${externalId}/TaxStatementsInfo`);
	} catch (error) {
		console.log('getTaxStatementDetails error=' + error);
		if (!!error.response && !!error.response.data) {
			console.log('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.log('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'getTaxStatementDetails'
			);
		}
	}
	return response.data.sort((a, b) => (a.year < b.year ? 1 : -1));
}

async function getOutstandingTimesheets(externalId, statusList = null, numberWeeks) {
	let response = {
		data: {},
	};
	let queryString = '';
	if (statusList?.length > 0) {
		queryString += '?';
		statusList.forEach((status, index) => {
			queryString += `statusList=${status}`;
			if (index !== statusList?.length - 1) queryString += '&';
		});
	}
	if (numberWeeks) {
		queryString = `${queryString}&numberWeeks=${numberWeeks}`;
	}
	try {
		response = await axiosWCApiInstance.get(`clinicians/${externalId}/timepage${queryString}`);
	} catch (error) {
		console.error('getOutstandingTimesheets error=' + error);
		if (!!error.response && !!error.response.data) {
			console.error('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.error('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'getOutstandingTimesheets'
			);
		}
	}
	return response.data;
}

async function saveTimesheet(timesheet, timesheetId, isRecall = false) {
	let response = {
		data: {},
	};

	try {
		if (timesheetId) {
			response = await axiosWCApiInstance.put(
				`/timesheets/${timesheetId}${isRecall ? '?mode=Recall' : ''}`,
				timesheet
			);
		} else {
			response = await axiosWCApiInstance.post(`/timesheets`, timesheet);
		}
	} catch (error) {
		console.error('postFeedbackResponse error=' + error);
		if (!!error.response && !!error.response.data) {
			console.error('throwing ApiError:' + JSON.stringify(error.response.data));
			throw error.response.data;
		} else if (!!error.message) {
			console.error('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'postFeedbackResponse'
			);
		}
	}
	return response.data;
}

async function validateTimesheet(timesheet, timesheetId) {
	let response = {
		data: {},
	};
	try {
		await axiosWCApiInstance.put(`/timesheets/${timesheetId}?mode=validateOnly`, timesheet);
	} catch (error) {
		console.error('postFeedbackResponse error=' + error);
		if (!!error.response && !!error.response.data) {
			console.error('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.error('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'postFeedbackResponse'
			);
		}
	}
	return response.data;
}

async function getTimesheetById(externalId, timesheetId, locationId) {
	let response = {
		data: {},
	};

	if (!externalId || !timesheetId) {
		throw ApiError.UnwrappedError(
			'Server Error',
			'Invalid parameters.',
			'getTimesheetById'
		);
	}

	const isAdminApp = process.env.REACT_APP_TYPE === 'Admin';
	const isClinicianApp = process.env.REACT_APP_TYPE === 'Clinician';
	const isClientApp = process.env.REACT_APP_TYPE === 'Client';
	let queryString = '';
	if (locationId) {
		queryString += `?locationId=${locationId}`;
	}
	try {
		if (isClinicianApp || isAdminApp) {
			response = await axiosWCApiInstance.get(
				`clinicians/${externalId}/timesheets/${timesheetId}`
			);
		} else if (isClientApp) {
			response = await axiosWCApiInstance.get(
				`clinicians/${externalId}/timesheets/${timesheetId}${queryString}`
			);
		} else {
			throw new Error('Application Configuration Not Found: ');
		}
	} catch (error) {
		console.error('getTimesheetById error=' + error);
		if (!!error.response && !!error.response.data) {
			console.error('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.error('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'getTimesheetById'
			);
		}
	}
	return response.data;
}

async function getTimesheetBookingDetails(bookingId) {
	let response = {
		data: {},
	};
	try {
		response = await axiosWCApiInstance.get(`Assignments/${bookingId}/TimesheetBookingDetails`);
	} catch (error) {
		console.error('getTimesheetBookingDetails error=' + error);
		if (!!error.response && !!error.response.data) {
			console.error('throwing ApiError:' + JSON.stringify(error.response.data));
			throw new ApiError(error.response.data);
		} else if (!!error.message) {
			console.error('throwing ApiError:' + JSON.stringify(error.message));
			throw new ApiError(error.message);
		} else {
			throw ApiError.UnwrappedError(
				'Server Error',
				'There was a problem processing that request.',
				'getTimesheetBookingDetails'
			);
		}
	}
	return response.data;
}

export {
	getNextPayDateData,
	getNextPayDateWCAPI,
	postFeedbackResponse,
	getPayStatementDetailsData,
	getYTDPayData,
	getClinicianPaysheetPdf,
	getClinicianPaysheetZip,
	getTaxStatementDetails,
	getClinicianTaxStatementPdf,
	getClinicianTaxStatementsZip,
	getOutstandingTimesheets,
	getTimesheetBookingDetails,
	getTimesheetById,
	saveTimesheet,
	validateTimesheet,
};
