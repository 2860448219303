import {
	axiosNotificationInstance,
	axiosWCApiInstance,
} from '../configuration/axios/axiosConfiguration';
import msalService from '../services/msSalService';
import { getConfigurationSetting } from './AppConfigService';

async function getNotificationsByReceiptId(externalId) {
	if (externalId === null || externalId === undefined) return null;
	let response;
	try {
		var useRewiredNotification = await getConfigurationSetting('ShowRewiredNotification',true);

		if (useRewiredNotification) {
			response = await axiosWCApiInstance.get(`clinicians/${externalId}/Notifications`);
			return response.data;
		} else {
			response = await axiosNotificationInstance.get('GetNotificationsByReceiptId', {
				params: {
					receiptId: externalId,
				},
			});
			return response.data;
		}
	} catch (error) {
		Error(error);
	}
}

async function dismissNotification(externalSystemId) {
	let response;

	try {
		var useRewiredNotification = await getConfigurationSetting('ShowRewiredNotification',true);

		if (useRewiredNotification) {
			const externalId = getExternalId();
			response = await axiosWCApiInstance.patch(
				`clinicians/${externalId}/Notifications/${externalSystemId}/Dismiss`
			);
			return 1;
		} else {
			response = await axiosNotificationInstance.post('DismissNotification', {
				externalSystemId: externalSystemId,
			});
			return response.data;
		}
	} catch (error) {
		Error(error);
	}
}

function getExternalId() {
	const account = msalService.getAccount || null;
	const id = account?.idTokenClaims?.extension_SfExternalId;
	if (!id) return null;
	return id.trim();
}

export { getNotificationsByReceiptId, dismissNotification };
