import { AppConfigurationClient } from '../components/app-configuration';
import { getLocalStorageWithExpiry, setLocalStorageWithExpiry } from '../utils/helpers';
import { appInsights } from '../AppInsights';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

/**
 * Gets App Configuration from Azure
 */
async function getConfigurationSetting(name, isFeatureFlag = false) {
	var featureName = name;

	if (isFeatureFlag) {
		featureName = '.appconfig.featureflag/' + featureName;
	}

	let client = new AppConfigurationClient(process.env.REACT_APP_APP_CONFIG);

	let response;

	try {
		var responseValue = getLocalStorageWithExpiry(featureName);
		if (responseValue === null) {
			response = await client.getConfigurationSetting({ key: featureName });
			responseValue = JSON.parse(response.value);
			setLocalStorageWithExpiry(featureName, responseValue, 300000);
		}

		if (isFeatureFlag) {
			var rtn = false;

			if (responseValue.enabled === true) {
				rtn = true;
			}
	
			return rtn;
		}else{
			return responseValue;
		}

	} catch (error) {
		appInsights.trackException({
			exception: error,
			properties: { method: 'getConfigurationSetting', severityLevel: SeverityLevel.Error },
		});
		return false;
	}
}

export { getConfigurationSetting };
